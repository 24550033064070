import React, { useEffect, useState } from "react";
import { IoMdSearch } from "react-icons/io";
import {
    Grid,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FaCamera } from "react-icons/fa";
import CircleImg from "../../../../assets/images/MyCommunity/nominated1.svg";
import UpArrow from "../../../../assets/images/MyCommunity/upArrow.svg";
import RoleCloseIcon from "../../../../assets/images/successionPlan/roleClose.svg";
import GreetingCard from './components/GreetingCard';

const ViewFullNomination = ({ openViewFullNomination, setOpenViewFullNomination }) => {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery("(max-width: 480px)");
    
    const [search, setSearch] = useState("");
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        console.log("Searching for:", search);
    };

    // State to store uploaded files
    const [files, setFiles] = useState([]);
    const [status, setStatus] = useState("INACTIVE"); // Example status
    const [openGreetingCard, setOpenGreetingCard] = useState(false);
    // Handle file selection
    const handleImageChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const newFiles = [...files, selectedFile]; // Add new file to state
            setFiles(newFiles); // Update state with new files
        }
    };

    // Open document handler (optional)
    const openDoc = (index) => {
        // Function to open the document (e.g., in a new tab or modal)
        const file = files[index];
        // Logic to open the file...
    };

    // Delete document handler
    const handleDeleteDoc = (index) => {
        const updatedFiles = files.filter((_, fileIndex) => fileIndex !== index);
        setFiles(updatedFiles); // Remove the file from state
    };

    const handleOpenGreetingCard = () => {
        if(!openGreetingCard){  
            setOpenGreetingCard(true);
        } else {
            setOpenGreetingCard(false);
        }

    }

    return (
        <div>
            <Dialog
                open={openViewFullNomination}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                PaperProps={{
                    style: {
                        backgroundColor: "#F9F9F9",
                        borderRadius: "20px",
                        // width: "52%",
                        width: isMobile ? "100%" : "52%",
                    },
                }}
            >
                <DialogContent>
                    <Grid container spacing={4} className="kudos-for-modal">
                        <Grid item xs={12} md={12} sm={12}>
                            {/*----- Heading -----*/}
                            <Typography className="model-title">{"Annette Black"} <span className="light-text">nominated</span> {"Savannah Nguyen"} <span className="light-text">for</span> </Typography>

                            {/*----- Top Image Card Section -----*/}
                            <div className="image-section">
                                <div className="main-b">
                                    <div className="org-update-row">
                                        <img src={CircleImg} className="loginBg"></img>
                                        <div className="update-info">
                                            <Typography className="head">{"Positive Pat-on-the back"}</Typography>
                                            <Typography className="description">{"Awards Definition"}{" "}<img src={UpArrow} className="up-arrow"></img></Typography>
                                            <Typography className="description">
                                                In publishing and graphic design, Lorem ipsum is a
                                                placeholder text commonly used to demonstrate the visual
                                                form of a document. In publishing and graphic design,
                                                Lorem ipsum is a placeholder text.
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>                         

                            {/*----- Reason for nomination -----*/}
                            <div className="textAreaSection">
                                <label htmlFor="outlined-multiline-static">
                                    Reason for nomination
                                </label>
                                <div className="whiteBoxbluetext">
                                    I would like him to Thing Big and Strategically as a VP
                                    (showcase this in areas outside of the standard input focus)
                                </div>
                            </div>

                            {/*----- Reason for Rejection -----*/}
                            {/* <div className="textAreaSection">
                                <label htmlFor="outlined-multiline-static">
                                    Reason for Rejection
                                </label>
                                <div className="whiteBoxbluetext">
                                    I would like him to Thing Big and Strategically as a VP
                                    (showcase this in areas outside of the standard input focus)
                                </div>
                            </div> */}

                         
                            {/*----- Cancel & Done Button -----*/}
                            <div className="botttombtn jc-center">
                                <button
                                    className="cancel-btn"
                                    onClick={() => setOpenViewFullNomination(false)}
                                    color="transparent"
                                    variant="outlined"
                                >
                                    Close
                                </button>
                             
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ViewFullNomination;
