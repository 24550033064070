import React, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CertificateIcon from "../../../../assets/images/MyCommunity/certificateIcon.png";
import RoleCloseIcon from "../../../../assets/images/successionPlan/roleClose.svg";

const CertificateModal = ({ openCertificateModal, handleCertificateModalClose }) => {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery("(max-width: 480px)");

    return (
        <div>
            <Dialog
                open={openCertificateModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                PaperProps={{
                    style: {
                        backgroundColor: "#F9F9F9",
                        borderRadius: "20px",
                        // width: "52%",
                        width: isMobile ? "100%" : "52%",
                    },
                }}
            >
                <DialogContent>
                    <Grid container className="certificate-modal">
                        <Grid item xs={12} md={12} sm={12} className="model-close-grid">
                            {isMobile && <Typography className="model-title">{"Outstanding Effort 2024-25"}</Typography>}
                            <img src={RoleCloseIcon} className="role-close-icon" onClick={handleCertificateModalClose}></img>
                        </Grid>

                        {!isMobile &&
                            <Grid item xs={12} md={12} sm={12} className="model-title-grid">
                                <Typography className="model-title">{"Outstanding Effort 2024-25"}</Typography>
                                <button
                                    className="download-btn"
                                    // onClick={}
                                    color="transparent"
                                    variant="outlined"
                                >
                                    Download
                                </button>
                            </Grid>
                        }

                        <Grid item xs={12} md={12} sm={12}>
                            <img src={CertificateIcon} className="certificate-icon"></img>
                        </Grid>

                        {isMobile &&
                            <Grid item xs={12} md={12} sm={12} className="model-title-grid jc-center-mb">
                                <button
                                    className="download-btn"
                                    // onClick={}
                                    color="transparent"
                                    variant="outlined"
                                >
                                    Download
                                </button>
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default CertificateModal;
