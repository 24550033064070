import React, { useState } from "react";
import { Grid, Button, Typography, MenuItem, Select, Box, TextField, Drawer, Card, CardContent, } from "@mui/material";
import EditUserProfile from "../../../../assets/images/successionPlan/editUserProfile.svg";
import { MdCloudUpload, MdDelete, MdTabUnselected } from "react-icons/md";
import plusIcon from "../../../../assets/images/plusIcon.png";
import RoleCloseIcon from "../../../../assets/images/successionPlan/roleClose.svg";
import UserRightArrow from "../../../../assets/images/promotions/userRightArrow.svg";
import filecircle from "../../../../assets/images/filecircle.svg";
import circleDownload from "../../../../assets/images/circleDownload.svg";
import dotIcon from "../../../../assets/images/dotIcon.svg";
import deletered from "../../../../assets/images/deletered.svg";
import shareIcon from "../../../../assets/images/header/shareIcon.svg";
import { AiOutlineDown } from "react-icons/ai";
import CommentsDrawer from './FormsStakeholdersDrawer/CommentsDrawer';
import RightDrawer from './FormsStakeholdersDrawer/RightDrawer';
import UserRightDrawer from './FormsStakeholdersDrawer/UserRightDrawer';
import RightShareDrawer from "./FormsStakeholdersDrawer/RightShareDrawer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const roleDataList = [
  { roleType: "Customer Obsession" },
  { roleType: "Ownership" },
  { roleType: "Invent And Simplify" },
  { roleType: "Invent And Simplify" },
  { roleType: "Hire and Develop the Best" },
  { roleType: "Ownership" },
  { roleType: "Customer Obsession" },
  { roleType: "Customer Obsession" },
  { roleType: "Customer Obsession" },
];
const EditPramotionForm = () => {

  const history = useHistory();

  const totalNumbers = [1, 2, 3, 4, 5];

  const [state, setState] = useState({
    right: false,
  });
  const [stateUser, setStateUser] = useState({
    right: false,
  });
  const [commentState, setCommentState] = useState({
    right: false,
  });
  const [stateShare, setStateShare] = useState({
    right: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ right: open });
  };

  const toggleCommentsDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setCommentState({ right: open });
  };

  const toggleUserDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setStateUser({ right: open });
  };

  const toggleShareDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setStateShare({ right: open });
  };

  const handleSubmit = () => {
    history.push("/my-team-promotion");
  }


  return (
    <div className="promotions-form">
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4} md={4} className="title-grid">
          <Typography className="page-title">{"Promotion Justification"}</Typography>
        </Grid>
        <Grid item xs={8} sm={8} md={8} className="title-grid1">
          <Button className="view-stakeholder-feedback-btn" onClick={toggleShareDrawer(true)}>
            <img
              src={shareIcon} className="mrleft-1" alt="Share"
            /> {"Share"}
          </Button>
          <Button className="view-stakeholder-feedback-btn" onClick={toggleCommentsDrawer(true)}>
            {"View Comments"}
          </Button>
          <Button className="view-stakeholder-feedback-btn" onClick={toggleDrawer(true)}>
            {"View Stakeholder feedback"}
          </Button>
        </Grid>

        {/*----- Feedback Comparison -----*/}
        <Grid item xs={12} sm={12} md={12}>
          <Grid container className="user-form-card">
            <Grid item xs={12} sm={12} md={12} className="user-card">
              <Grid container spacing={2} className="mt-1">
                <Grid item xs={12} md={2} sm={2} className="ta-center right-border pl-0">
                  <img
                    src={EditUserProfile}
                    alt="EditUserProfile"
                    height={77}
                    width={77}
                  />
                  <Typography className="profile-name">{"Akshay C "}<span><img src={UserRightArrow} alt="rightArrow" height={16} width={10} onClick={toggleUserDrawer(true)} style={{ cursor: "pointer" }} /></span></Typography>
                  <Typography className="profile-field-label">{"Emp No. "}<span className="profile-emp-no">{"10614"}</span></Typography>
                </Grid>
                <Grid item xs={12} md={10} sm={10} className="pl-35">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4} sm={4}>
                      <Typography className="profile-field-label">{"Level"}</Typography>
                      <Typography className="profile-field-value">{"L4"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} sm={4}>
                      <Typography className="profile-field-label">{"Tenure"}</Typography>
                      <Typography className="profile-field-value">{"2 Years"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} sm={4}>
                      <Typography className="profile-field-label">{"Performance  Rating (2023)"}</Typography>
                      <Typography className="profile-field-value">{"A"}</Typography>
                    </Grid>


                    <Grid item xs={12} md={4} sm={4}>
                      <Typography className="profile-field-label">{"Job Family "}</Typography>
                      <Typography className="profile-field-value">{"Assistant Manager"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} sm={4}>
                      <Typography className="profile-field-label">{"Skill Assessments"}</Typography>
                      <Typography className="profile-field-value">{"Proficient, Hard Working"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} sm={4}>
                      <Typography className="profile-field-label">{"Performance  Rating (2024)"}</Typography>
                      <Typography className="profile-field-value">{"A+"}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className="boxpadding">
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text'>{"Next recommended role"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9} className="pl-15">
                <div className='role-container'>

                  <div className='role-border-div'>
                    <div className='d-flex ai-center'>
                      <Select
                        size="small"
                        name="promotionStatus"
                        fullWidth
                        value={"5"}
                        className="text-field-area"
                        IconComponent={AiOutlineDown}
                      >
                        {totalNumbers?.map((report) => {
                          return (
                            <MenuItem value={report} key={report}>{report}</MenuItem>
                          )
                        })}
                      </Select>
                    </div>
                  </div>

                </div>

              </Grid>
            </Grid>
            <Grid container className="borderpadding">
              <Grid item xs={12} sm={12} md={12}>


                <Grid className='horizontal-border mtb-5' />

              </Grid>
            </Grid>
            <Grid container className="boxpadding">
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text'>{"Scope of role"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9} className="pl-15">
                <div className='role-container'>

                  <div className='role-border-div'>
                    <div className='d-flex ai-center'>
                      <Select
                        size="small"
                        name="promotionStatus"
                        fullWidth
                        value={"5"}
                        className="text-field-area"
                        IconComponent={AiOutlineDown}
                      >
                        {totalNumbers?.map((report) => {
                          return (
                            <MenuItem value={report} key={report}>{report}</MenuItem>
                          )
                        })}
                      </Select>
                    </div>
                  </div>

                </div>


              </Grid>
            </Grid>
            <Grid container className="borderpadding">
              <Grid item xs={12} sm={12} md={12}>


                <Grid className='horizontal-border mtb-5' />

              </Grid>
            </Grid>
            <Grid container className="boxpadding">
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text'>{"Assess competencies"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9} className="pl-15">
                <div className='role-container'>

                  <div className='role-border-div'>
                    <div className='d-flex ai-center mb-3'>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography className="alllabeltext">{"Select competencies"}</Typography>
                          <Select
                            size="small"
                            name="promotionStatus"
                            fullWidth
                            value={"5"}
                            className="text-field-area"
                            IconComponent={AiOutlineDown}
                            sx={{

                              mt: '6px',
                              "& fieldset": {
                                borderRadius: '8px',
                              },
                            }}
                          >
                            {totalNumbers?.map((report) => {
                              return (
                                <MenuItem value={report} key={report}>{report}</MenuItem>
                              )
                            })}
                          </Select>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                          <div className="w-100 deleteSelectBox">
                            <Typography className="alllabeltext" >{"Comments"}</Typography>
                            <div className="selectIcondelete">
                              <TextField
                                name="sessionTitle"
                                id="outlined-multiline-static"
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={"Employee is good for customer obsession"}
                                sx={{
                                  width: '97%',
                                  mt: '6px',
                                  marginRight: '4px',
                                  "& fieldset": {
                                    borderRadius: '8px',
                                  },
                                }}
                              />
                              <div className="delete-icon">
                                <img src={deletered} alt="reload" />
                              </div>
                            </div>
                          </div>
                        </Grid>

                      </Grid>

                    </div>
                  </div>

                </div>
                <div className='role-container'>

                  <div className='role-border-div'>
                    <div className='d-flex ai-center mb-3'>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography className="alllabeltext" >{"Select competencies"}</Typography>
                          <Select
                            size="small"
                            name="promotionStatus"
                            fullWidth
                            value={"5"}
                            className="text-field-area"
                            IconComponent={AiOutlineDown}
                            sx={{

                              mt: '6px',
                              "& fieldset": {
                                borderRadius: '8px',
                              },
                            }}
                          >
                            {totalNumbers?.map((report) => {
                              return (
                                <MenuItem value={report} key={report}>{report}</MenuItem>
                              )
                            })}
                          </Select>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                          <div className="w-100 deleteSelectBox">
                            <Typography className="alllabeltext" >{"Comments"}</Typography>
                            <div className="selectIcondelete">
                              <TextField
                                name="sessionTitle"
                                id="outlined-multiline-static"
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={"Employee is good for customer obsession"}
                                sx={{
                                  width: '97%',
                                  mt: '6px',
                                  marginRight: '4px',
                                  "& fieldset": {
                                    borderRadius: '8px',
                                  },
                                }}
                              />
                              <div className="delete-icon">
                                <img src={deletered} alt="reload" />
                              </div>
                            </div>
                          </div>
                        </Grid>

                      </Grid>

                    </div>
                  </div>

                </div>
                <div className='role-container'>

                  <div className='role-border-div'>
                    <div className='d-flex ai-center mb-3'>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography className="alllabeltext" >{"Select competencies"}</Typography>
                          <Select
                            size="small"
                            name="promotionStatus"
                            fullWidth
                            value={"5"}
                            className="text-field-area"
                            IconComponent={AiOutlineDown}
                            sx={{

                              mt: '6px',
                              "& fieldset": {
                                borderRadius: '8px',
                              },
                            }}
                          >
                            {totalNumbers?.map((report) => {
                              return (
                                <MenuItem value={report} key={report}>{report}</MenuItem>
                              )
                            })}
                          </Select>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                          <div className="w-100 deleteSelectBox">
                            <Typography className="alllabeltext" >{"Comments"}</Typography>
                            <div className="selectIcondelete">
                              <TextField
                                name="sessionTitle"
                                id="outlined-multiline-static"
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={"Employee is good for customer obsession"}
                                sx={{
                                  width: '97%',
                                  mt: '6px',
                                  marginRight: '4px',
                                  "& fieldset": {
                                    borderRadius: '8px',
                                  },
                                }}
                              />
                              <div className="delete-icon">
                                <img src={deletered} alt="reload" />
                              </div>
                            </div>
                          </div>
                        </Grid>

                      </Grid>

                    </div>
                  </div>

                </div>
                <Grid item xs={12} sm={12} md={12}>
                  <div className="uploadDoc"> + Add More Competencies</div>
                </Grid>

              </Grid>
            </Grid>
            <Grid container className="borderpadding">
              <Grid item xs={12} sm={12} md={12}>


                <Grid className='horizontal-border mtb-5' />

              </Grid>
            </Grid>
            <Grid container className="boxpadding">
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text'>{"Justify reasons to promote"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9} className="pl-15">
                <div className='role-container'>

                  <div className='role-border-div'>
                    <div className='d-flex ai-center'>
                      <TextField
                        name="demonstrated"
                        id="outlined-multiline-static"
                        variant="outlined"
                        placeholder="Please provide Overall feedback"
                        multiline
                        rows={3}
                        value="Enter Reason"
                        style={{ width: "100%" }}
                        InputProps={{
                          style: {
                            color: "#7E7E7E",
                            fontSize: "16px",
                            fontWeight: "lighter",
                            borderColor: "#D9D9D9",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "8px",
                          },
                        }}
                      />
                    </div>
                  </div>

                </div>


              </Grid>
            </Grid>
            <Grid container className="borderpadding">
              <Grid item xs={12} sm={12} md={12}>


                <Grid className='horizontal-border mtb-5' />

              </Grid>
            </Grid>
            <Grid container className="boxpadding">
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text'>{"Provide risks for promotion"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9} className="pl-15">
                <div className='role-container'>

                  <div className='role-border-div'>
                    <div className='d-flex ai-center'>
                      <TextField
                        name="demonstrated"
                        id="outlined-multiline-static"
                        variant="outlined"
                        placeholder="Please provide Overall feedback"
                        multiline
                        rows={3}
                        value="Enter Reason"
                        style={{ width: "100%" }}
                        InputProps={{
                          style: {
                            color: "#7E7E7E",
                            fontSize: "16px",
                            fontWeight: "lighter",
                            borderColor: "#D9D9D9",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "8px",
                          },
                        }}
                      />
                    </div>
                  </div>

                </div>


              </Grid>
            </Grid>
            <Grid container className="borderpadding">
              <Grid item xs={12} sm={12} md={12}>


                <Grid className='horizontal-border mtb-5' />

              </Grid>
            </Grid>
            <Grid container className="boxpadding">
              <Grid item xs={12} sm={3} md={3}>
                <Typography className='comm-left-text mt-2'>{"Attach supporting documents"}</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9} className="pl-15">
                <div className='role-container'>

                  <div className='role-border-div'>
                    <div className='d-block ai-center'>
                      <div className="head-delete">
                        <img src={filecircle} alt="reload" />
                        <Button className="btnpadding">
                          <div className="title">
                            Certificafied_professional.doc
                          </div>
                        </Button>
                        <div className="delete-icon">
                          <img src={deletered} alt="reload" />
                        </div>
                      </div>
                      <div className="head-delete">
                        <img src={filecircle} alt="reload" />
                        <Button className="btnpadding">
                          <div className="title">
                            Certificafied_professional.doc
                          </div>
                        </Button>
                        <div className="delete-icon">
                          <img src={deletered} alt="reload" />
                        </div>
                      </div>
                      <div className="head-delete">
                        <img src={filecircle} alt="reload" />
                        <Button className="btnpadding">
                          <div className="title">
                            Certifications03.doc
                          </div>
                        </Button>
                        <div className="delete-icon">
                          <img src={deletered} alt="reload" />
                        </div>
                      </div>
                      <div className="head-delete">
                        <img src={filecircle} alt="reload" />
                        <Button className="btnpadding">
                          <div className="title">
                            Certifications03.doc
                          </div>
                        </Button>
                        <div className="delete-icon">
                          <img src={deletered} alt="reload" />
                        </div>
                      </div>
                      <div className="head-delete">
                        <img src={filecircle} alt="reload" />
                        <Button className="btnpadding">
                          <div className="title">
                            Certificafied_professional_1.doc
                          </div>
                        </Button>
                        <div className="delete-icon">
                          <img src={deletered} alt="reload" />

                        </div>
                      </div>
                    </div>
                  </div>


                </div>

                <Grid item xs={12} sm={12} md={12}>
                  <div className="uploadDoc"> + Upload Doc</div>
                </Grid>



              </Grid>
            </Grid>
            {/* <Grid container className="borderpadding">
              <Grid item xs={12} sm={12} md={12}>


                <Grid className='horizontal-border mtb-5' />

              </Grid>
            </Grid> */}
            <Grid container className="boxpadding">
              <div className="d-flex jc-end mt-20 w-100">
                <button className="plan-cancel-btn">
                  {"Cancel"}
                </button>

                <button className="plan-save-btn" onClick={handleSubmit}>
                  {"Submit"}
                </button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/*----- Right Drawer -----*/}
      <RightDrawer
        anchorRightDrawer="right"
        openStateRight={state.right}
        onCloseRightDrawer={toggleDrawer(false)}
      >
      </RightDrawer>
      <CommentsDrawer
        anchorRightDrawer="right"
        openStateRight={commentState.right}
        onCloseRightDrawer={toggleCommentsDrawer(false)}
      >
      </CommentsDrawer>

      <UserRightDrawer
        anchorUserRightDrawer="right"
        openUserStateRight={stateUser.right}
        onCloseUserRightDrawer={toggleUserDrawer(false)}
      >
      </UserRightDrawer>

      <RightShareDrawer
        anchorRightShareDrawer="right"
        openShareRight={stateShare.right}
        onCloseRightShareDrawer={toggleShareDrawer(false)}
      />

    </div>
  );
};

export default EditPramotionForm;
