import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const RejectedFeedback = ({ }) => {

    return (
        <Grid>
            <Typography>{"Rejected Feedback"}</Typography>
        </Grid>
    )
}

export default RejectedFeedback;