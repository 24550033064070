import { useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const NoActionTaken = ({ }) => {

    return (
        <Grid>
            {/*----- User 1 -----*/}
            <Grid item xs={12} sm={12} md={12}>
                <Grid className="user-unfilled-expanded-card">
                    <Grid className="d-flex">
                        <Box className="user-box">{"SJ"}</Box>
                        <Typography className="user-name">{"Somil J."}</Typography>
                    </Grid>
                    <Grid className="description-grid">
                        <Grid className="border-horizontal" />
                    </Grid>
                </Grid>
            </Grid>

            {/*----- User 2 -----*/}
            <Grid item xs={12} sm={12} md={12}>
                <Grid className="user-unfilled-expanded-card">
                    <Grid className="d-flex">
                        <Box className="user-box">{"HJ"}</Box>
                        <Typography className="user-name">{"Haesh"}</Typography>
                    </Grid>
                    {/* <Grid className="description-grid">
                        <Grid className="border-horizontal" />
                    </Grid> */}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NoActionTaken;