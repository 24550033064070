import React, { useState } from 'react';
import { Grid, Typography, Button, Checkbox, Popover } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { FaSort } from "react-icons/fa6";
import { AiFillCaretRight } from "react-icons/ai";
import { AiFillCaretDown } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import { BsChevronDown } from 'react-icons/bs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { accordianSVGstyle } from '../../../utils/utils';
import { accordianSVGstyle1 } from '../../../utils/utils';


const FeedbackRequests = ({ }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);

    const nominateEmps = [
        {
            id: 1,
            empCode: 10614,
            promoName: 'Mihir Jain',
            empName: 'Ashish Reji',
            designation: '20 Nov, 2024',
            perfRating2023: '20 Dec, 2024',
            perfRating20233: '2:00 pm - 4 :00 pm',
            recommendForPromotion: 'Yes',
            actionButton: 'Action',
            feedbackButton: '',
            declinedButton: '',
            panelVote: '6/8 Approved',
        },
        {
            id: 2,
            empCode: 10680,
            promoName: 'Tanya Sharma',
            empName: 'Akshay C ',
            designation: '20 Nov, 2024',
            perfRating20233: '2:00 pm - 4 :00 pm',
            perfRating2023: '20 Nov, 2024',
            recommendForPromotion: 'Yes',
            actionButton: '',
            feedbackButton: '',
            declinedButton: 'Declined',
            panelVote: '1/8 Approved',
        },
        {
            id: 3,
            empCode: 10681,
            promoName: 'Mihir Jain',
            empName: 'Govind H',
            designation: '20 Nov, 2024',
            perfRating20233: '2:00 pm - 4 :00 pm',
            perfRating2023: '20 Nov, 2024',
            recommendForPromotion: 'No',
            actionButton: 'Action',
            feedbackButton: '',
            declinedButton: '',
            panelVote: '6/8 Approved',
        },
        {
            id: 4,
            empCode: 10692,
            promoName: 'Mihir Jain',
            empName: 'Umashankar Aravallil',
            designation: '20 Nov, 2024',
            perfRating20233: '2:00 pm - 4 :00 pm',
            perfRating2023: '20 Nov, 2024',
            recommendForPromotion: 'Yes',
            actionButton: '',
            feedbackButton: 'Feedback Provided',
            declinedButton: '',
            panelVote: '5/6 Approved',
        },
        {
            id: 5,
            empCode: 10691,
            promoName: 'Tanya Sharma',
            empName: 'Akshay C',
            designation: '20 Nov, 2024',
            perfRating20233: '2:00 pm - 4 :00 pm',
            perfRating2023: '20 Nov, 2024',
            recommendForPromotion: 'Yes',
            actionButton: 'Action',
            feedbackButton: '',
            declinedButton: '',
            panelVote: '6/8 Approved',
        },
        {
            id: 6,
            empCode: 10614,
            promoName: 'Mihir Jain',
            empName: 'Ashish Reji',
            designation: '20 Nov, 2024',
            perfRating20233: '2:00 pm - 4 :00 pm',
            perfRating2023: '20 Nov, 2024',
            recommendForPromotion: 'Yes',
            actionButton: '',
            feedbackButton: '',
            declinedButton: 'Declined',
            panelVote: '2/8 Approved',
        },
        {
            id: 7,
            empCode: 10680,
            promoName: 'Tanya Sharma',
            empName: 'Akshay C ',
            designation: '20 Nov, 2024',
            perfRating20233: '2:00 pm - 4 :00 pm',
            perfRating2023: '20 Nov, 2024',
            recommendForPromotion: 'Yes',
            actionButton: 'Action',
            feedbackButton: '',
            declinedButton: '',
            panelVote: '7/9 Approved',
        },
        {
            id: 8,
            empCode: 10681,
            promoName: 'Mihir Jain',
            empName: 'Govind H',
            designation: '20 Nov, 2024',
            perfRating20233: '2:00 pm - 4 :00 pm',
            perfRating2023: '20 Nov, 2024',
            recommendForPromotion: 'No',
            actionButton: '',
            feedbackButton: 'Feedback Provided',
            declinedButton: '',
            panelVote: '6/8 Approved',
        },
        {
            id: 9,
            empCode: 10692,
            promoName: 'Tanya Sharma',
            empName: 'Umashankar Aravallil',
            designation: '20 Nov, 2024',
            perfRating20233: '2:00 pm - 4 :00 pm',
            perfRating2023: '20 Nov, 2024',
            recommendForPromotion: 'Yes',
            actionButton: '',
            feedbackButton: 'Feedback Provided',
            declinedButton: '',
            panelVote: '6/8 Approved',
        },
        {
            id: 10,
            empCode: 10691,
            promoName: 'Mihir Jain',
            empName: 'Akshay C',
            designation: '20 Nov, 2024',
            perfRating20233: '2:00 pm - 4 :00 pm',
            perfRating2023: '20 Nov, 2024',
            recommendForPromotion: 'Yes',
            actionButton: '',
            feedbackButton: 'Feedback Provided',
            declinedButton: '',
            panelVote: '6/8 Approved',
        },
    ];
    const handleCheckboxChange = (event, item) => {
        const isChecked = event.target.checked;
        item.recommendForPromotion = isChecked ? "Yes" : "No";
    };

    const handleClickAction = (event, emp) => {
        // setSelectedPeer(emp);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Grid container className="table-card">
            <Grid item xs={12} sm={12} md={12}>
                <div className="nominate-promo-table">
                    <table className="emp-list-table">
                        <tr className="table-head-emp-list">
                            <th className="w-200 pl-20">Promo. Sent by &nbsp;<FaSort className="c-pointer" /></th>
                            <th className="w-200">Promotion Nominee &nbsp;<FaSort className="c-pointer" /></th>
                            <th>Request Date  &nbsp;<FaSort className="c-pointer" /></th>
                            <th>Request End Date  &nbsp;<FaSort className="c-pointer" /></th>
                            <th>Panel Votes  &nbsp;<FaSort className="c-pointer" /></th>

                            <th className="ta-end pr-20">Status &nbsp;<FaSort className="c-pointer" /></th>
                        </tr>

                        {nominateEmps.map((item, index) => (
                            <tr>
                                <td className="pl-20">
                                    <div>
                                        <Typography className="emp-name-checkbox">{item.promoName}</Typography>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <Typography className="emp-name-checkbox">{item.empName}</Typography>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <Typography className="emp-table-value">{item.designation}
                                            <br />
                                            <span className='asasas'> {item.perfRating20233}</span>
                                        </Typography>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <Typography className="emp-table-value">
                                            {item.perfRating2023}<br />
                                            <span className='asasas'> {item.perfRating20233}</span>
                                        </Typography>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <Typography className="emp-table-value">
                                            {item.panelVote}

                                        </Typography>
                                    </div>
                                </td>

                                <td className="ta-end pr-20">
                                    <div>


                                        {item.actionButton === "Action" &&
                                            <Button className="action-btn" onClick={(event) => handleClickAction(event, item)}>
                                                {item.actionButton}
                                                <BsChevronDown
                                                    style={{ ...accordianSVGstyle }}
                                                />
                                            </Button>
                                        }
                                        {item.feedbackButton === "Feedback Provided" &&
                                            <Button className="feedback-btn">
                                                {item.feedbackButton} <BsChevronDown
                                                    style={{ ...accordianSVGstyle1 }}
                                                />
                                            </Button>
                                        }
                                        {item.declinedButton === "Declined" &&
                                            <Button className="declined-btn">
                                                {item.declinedButton}
                                                <BsChevronDown
                                                    style={{ ...accordianSVGstyle1 }}
                                                />
                                            </Button>
                                        }

                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                            sx={{
                                                "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
                                                    boxShadow: "4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                    border: "1px solid  #e9e9e9",
                                                    // padding:"0.25rem",
                                                    fontFamily: "poppins",
                                                    fontSize: "0.75rem",
                                                    borderRadius: "10px",
                                                    padding: "5px 0px",
                                                    cursor: "pointer",
                                                    marginLeft: "-1rem"
                                                },
                                                "& .MuiPopover-paper": {
                                                    boxShadow: "4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                    border: "1px solid  #e9e9e9",
                                                    // padding:"0.25rem",
                                                    fontFamily: "poppins",
                                                    fontSize: "0.75rem",
                                                    borderRadius: "10px",
                                                    padding: "5px 0px",
                                                    cursor: "pointer",
                                                    marginLeft: "-1rem"
                                                }
                                            }}
                                        >
                                            <div
                                                onClick={() => history.push("/promotion-form")}
                                                className="popover-btns" >
                                                Give Feedback
                                            </div>
                                            <div
                                                // onClick={() => history.push("/promotion-form")}
                                                className="popover-btns" >
                                                Decline
                                            </div>

                                            <div className="popover-btns">
                                                View Manager's Form
                                            </div>
                                            <div className="popover-btns">
                                                Approve
                                            </div>
                                            <div className="popover-btns" >
                                                Decline
                                            </div>
                                        </Popover>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>

                {/* <div className="save-submit-btn-div">
                <Button className="save-draft">{"Save Draft"}</Button>
                <Button className="submit-list">{"Submit List"}</Button>
            </div> */}
            </Grid>
        </Grid>
    );
};

export default React.memo(FeedbackRequests);
