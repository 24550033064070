import React, { useState } from 'react';
import { Button, Grid, Typography, Drawer } from '@mui/material';
import EditUserProfile from "../../../../../assets/images/successionPlan/editUserProfile.png";
import DrawerCloseIcon from "../../../../../assets/images/successionPlan/drawerCloseIcon.svg";
import Profile from './components/Profile';
import Rating from './components/Rating';
import Assessment from './components/Assessment';


const UserRightDrawer = ({ anchorUserRightDrawer, openUserStateRight, onCloseUserRightDrawer }) => {

    const [ProfileView, setProfileView] = useState(true);
    const [RatingView, setRatingView] = useState(false);
    const [AssessmentView, setAssessmentView] = useState(false);

    const handleProfile = () => {
        setProfileView(true);
        setRatingView(false);
        setAssessmentView(false);
    };

    const handleRating = () => {
        setProfileView(false);
        setRatingView(true);
        setAssessmentView(false);
    };

    const handleAssessment = () => {
        setProfileView(false);
        setRatingView(false);
        setAssessmentView(true);
    };

    return (
        <Drawer
            anchor={anchorUserRightDrawer}
            open={openUserStateRight}
        // onClose={onCloseRightDrawer}
        >
            <Grid container spacing={2} className="from-stakeholders-drawer">
                <Grid container item xs={12} md={10} sm={10}>
                    <img
                        src={EditUserProfile}
                        alt="EditUserProfile"
                        height={50}
                        width={50}
                    />
                    <Grid className="user-grid">
                        <Typography className="right-user-name">{"Abishek"}</Typography>
                        <Typography className="user-id">{"ID: 26k7"}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={2} sm={2} className="ta-right">
                    <img
                        src={DrawerCloseIcon}
                        alt="DrawerCloseIcon"
                        height={17}
                        width={17}
                        onClick={onCloseUserRightDrawer}
                    />
                </Grid>


                <Grid item xs={12} sm={12} md={12}>

                    {/* <Typography className="title-stakeholders">{"Forms from Stakeholders"}</Typography> */}

                    {/*----- Top Button Section -----*/}
                    <Grid class="top-btn-List">

                        <a onClick={handleRating} class={RatingView === true ? "active-btn" : "inActive-btn"}>{"Rating"}</a>
                        <a onClick={handleAssessment} class={AssessmentView === true ? "active-btn" : "inActive-btn"}>{"Assessment"}</a>
                        <a onClick={handleProfile} class={ProfileView === true ? "active-btn" : "inActive-btn"}>{"Profile"}</a>
                    </Grid>

                    {/* <Grid className="border-horizontal" /> */}

                    

                    {RatingView === true &&
                        <Rating />
                    }

                    {AssessmentView === true &&
                        <Assessment />
                    }
                    {ProfileView === true &&
                        <Profile />
                    }
                </Grid>
            </Grid>
        </Drawer>
    );
};

export default UserRightDrawer;