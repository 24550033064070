import React, { useState } from "react";
import { Grid, Button, Typography,RadioGroup,FormControl, FormControlLabel, MenuItem, Select, Box, TextField} from "@mui/material";
import EditUserProfile from "../../../../assets/images/successionPlan/editUserProfile.png";
import { MdCloudUpload, MdDelete, MdTabUnselected } from "react-icons/md";
import plusIcon from "../../../../assets/images/plusIcon.png";
import RoleCloseIcon from "../../../../assets/images/successionPlan/roleClose.svg";
import UserRightArrow from "../../../../assets/images/promotions/userRightArrow.svg";
import filecircle from "../../../../assets/images/filecircle.svg";
import circleDownload from "../../../../assets/images/circleDownload.svg";
import dotIcon from "../../../../assets/images/dotIcon.svg";
import deletered from "../../../../assets/images/deletered.svg";
import Radio from "@mui/material/Radio";
import { AiOutlineDown } from "react-icons/ai";
import UserRightDrawer from "./FormsStakeholdersDrawer/UserRightDrawer";
// import { color } from "html2canvas/dist/types/css/types/color";

const roleDataList = [
  { roleType: "Customer Obsession" },
  { roleType: "Ownership" },
  { roleType: "Invent And Simplify" },
  { roleType: "Invent And Simplify" },
  { roleType: "Hire and Develop the Best" },
  { roleType: "Ownership" },
  { roleType: "Customer Obsession" },
  { roleType: "Customer Obsession" },
  { roleType: "Customer Obsession" },
];
const ViewPramotionForm = () => {
  const totalNumbers = [1, 2, 3, 4, 5];

  const [stateUser, setStateUser] = useState({right: false});
  const toggleUserDrawer = (open) => (event) => {
    setStateUser({ ...stateUser, right: open });
  };

  const [selectedValue, setSelectedValue] = useState(true); // Initial value

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div className="promotions-form-view">
    <Grid container spacing={2}>
      <Grid item xs={4} sm={4} md={4} className="title-grid">
        <Typography className="page-title">{"Promotion Justification"}</Typography>
      </Grid>
      <Grid item xs={8} sm={8} md={8} className="title-grid1">
      <Button className="view-stakeholder-feedback-btn">
          {"View Panelists feedback"}
        </Button>
        <Button className="view-stakeholder-feedback-btn">
          {"View Stakeholder feedback"}
        </Button>
      </Grid>

      {/*----- Feedback Comparison -----*/}
      <Grid item xs={12} sm={12} md={12}>
        <Grid container className="user-form-card">
          <Grid item xs={12} sm={12} md={12} className="user-card">
            <Grid container spacing={2} className="mt-1">
              <Grid item xs={12} md={2} sm={2} className="ta-center right-border pl-0">
                <img
                  src={EditUserProfile}
                  alt="EditUserProfile"
                  height={77}
                  width={77}
                />
                <Typography className="profile-name mt-1">{"Akshay C "}<span><img src={UserRightArrow} onClick={toggleUserDrawer(true)} alt="rightArrow" height={16} width={10} style={{cursor: "pointer"}} /></span></Typography>
                <Typography className="profile-field-label">{"Emp No. "}<span className="profile-emp-no">{"10614"}</span></Typography>
              </Grid>
              <Grid item xs={12} md={10} sm={10} className="pl-35">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} sm={4}>
                    <Typography className="profile-field-label">{"Level"}</Typography>
                    <Typography className="profile-field-value">{"L4"}</Typography>
                  </Grid>
                  <Grid item xs={12} md={4} sm={4}>
                    <Typography className="profile-field-label">{"Tenure"}</Typography>
                    <Typography className="profile-field-value">{"2 Years"}</Typography>
                  </Grid>
                  <Grid item xs={12} md={4} sm={4}>
                    <Typography className="profile-field-label">{"Performance  Rating (2023)"}</Typography>
                    <Typography className="profile-field-value">{"A"}</Typography>
                  </Grid>
                  

                  <Grid item xs={12} md={4} sm={4}>
                    <Typography className="profile-field-label">{"Job Family "}</Typography>
                    <Typography className="profile-field-value">{"Assistant Manager"}</Typography>
                  </Grid>
                  <Grid item xs={12} md={4} sm={4}>
                    <Typography className="profile-field-label">{"Skill Assessments"}</Typography>
                    <Typography className="profile-field-value">{"Proficient, Hard Working"}</Typography>
                  </Grid>
                  <Grid item xs={12} md={4} sm={4}>
                    <Typography className="profile-field-label">{"Performance  Rating (2024)"}</Typography>
                    <Typography className="profile-field-value">{"A+"}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
         
          <Grid container className="boxpadding">
            
            <Grid item xs={12} sm={12} md={12} className="pl-15 pr-15">
              <div className='role-container'>                 
                  <div className='blueTitle'>  
                  Next recommended role                   
                  </div>
                  <div className='grayTitle'>   
                  I'll be sharing over 200 business names ideas and examples to help you come up with business names that are catchy, personal, cryptic, thought-provoking, bold                  
                    </div>                
              </div>               
            </Grid>
          </Grid>
        
          <Grid container className="boxpadding">
          <Grid item xs={12} sm={12} md={12} className="pl-15 pr-15">
              <div className='role-container'>                 
                  <div className='blueTitle'>  
                  Scope of role                   
                  </div>
                  <div className='grayTitle'>   
                  This employee comes under qualitative feedback, measurable performance metrics                  
                    </div>                
              </div>               
            </Grid>
          </Grid>
          <Grid container className="boxpadding">
          <Grid item xs={12} sm={12} md={12} className="pl-15 pr-15">
              <div className='role-container'>                 
                  <div className='blueTitle mb-3'>  
                  Rate Competency                  
                  </div>
                                
              </div>               
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pl-15 pr-15">
              <div className='role-container mb-3'> 
               <div className="doticontext"> 
               <img src={dotIcon} alt="reload" />
                 <div className="text">             
                  <div className='blueTitle'>  
                  Customer Obsession                 
                  </div>
                  <div className='grayTitle'>   
                  I'll be sharing over 200 business names ideas and examples to help you come up with business names that are catchy,                  
                    </div> 
                 </div>
               </div>               
              </div>               
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pl-15 pr-15">
              <div className='role-container mb-3'> 
              <div className="doticontext"> 
               <img src={dotIcon} alt="reload" />
                 <div className="text">                
                  <div className='blueTitle'>  
                  Ownership                  
                  </div>
                  <div className='grayTitle'>   
                  Employee is good for Ownership                  
                    </div>
                 </div>
                 </div>                
              </div>               
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pl-15 pr-15">
              <div className='role-container mb-2'>  
              <div className="doticontext"> 
               <img src={dotIcon} alt="reload" />
                 <div className="text">               
                  <div className='blueTitle'>  
                  Invent And Simplify                  
                  </div>
                  <div className='grayTitle'>   
                  Employee is good for Invent And Simplify                  
                    </div>  
                 </div>
                 </div>              
              </div>               
            </Grid>
          </Grid>
          <Grid container className="boxpadding">
            
            <Grid item xs={12} sm={12} md={12} className="pl-15 pr-15">
              <div className='role-container'>                 
                  <div className='blueTitle'>  
                  Justify reasons to promote                   
                  </div>
                  <div className='grayTitle'>   
                  This employee comes under qualitative feedback, measurable performance metrics                
                    </div>                
              </div>               
            </Grid>
          </Grid>
        
          <Grid container className="boxpadding">
          <Grid item xs={12} sm={12} md={12} className="pl-15 pr-15">
              <div className='role-container'>                 
                  <div className='blueTitle'>  
                  Provide risks for promotion                
                  </div>
                  <div className='grayTitle'>   
                  This employee comes under qualitative feedback, measurable performance metrics                  
                    </div>                
              </div>               
            </Grid>
          </Grid>

          <Grid container className="boxpadding">
            <Grid item xs={12} sm={4} md={4}>
              <div className='blueTitle pl-15'>{"View supporting documents"}</div>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <div className='role-container'>
                
                  <div className='role-border-div'>
                    <div className='d-block ai-center'>
                    <div className="head-delete">
                    <img src={circleDownload} alt="reload" />
                                              <Button className="btnpadding">
                                                  <div className="title">
                                                  Certificafied_professional.doc                                                       
                                                  </div>
                                              </Button>
                                              
                                          </div>
                                          <div className="head-delete">
                    <img src={circleDownload} alt="reload" />
                                              <Button className="btnpadding">
                                                  <div className="title">
                                                  Certificafied_professional.doc                                                       
                                                  </div>
                                              </Button>
                                              
                                          </div>
                                          <div className="head-delete">
                    <img src={circleDownload} alt="reload" />
                                              <Button className="btnpadding">
                                                  <div className="title">
                                                  Certifications03.doc                                                     
                                                  </div>
                                              </Button>
                                             
                                          </div>
                                          <div className="head-delete">
                    <img src={circleDownload} alt="reload" />
                                              <Button className="btnpadding">
                                                  <div className="title">
                                                  Certifications03.doc                                                       
                                                  </div>
                                              </Button>
                                              
                                          </div>
                                          <div className="head-delete">
                    <img src={circleDownload} alt="reload" />
                                              <Button className="btnpadding">
                                                  <div className="title">
                                                  Certificafied_professional_1.doc                                                      
                                                  </div>
                                              </Button>
                                             
                                          </div>
                    </div>
                  </div>
                  
               
              </div>

              
            </Grid>
          </Grid>
          <Grid container className="boxpadding">
          <Grid item xs={12} sm={12} md={12} className="pl-15 pr-15">
              <div className='role-container'>                 
                  <div className='blueTitle'>  
                  <span className="supported">Supported</span> promotion             
                  </div>
                  <div className='grayTitle'>   
                  Yes, I support for promotion as this employee comes under qualitative feedback, measurable performance metrics                  
                    </div>                
              </div>               
            </Grid>
          </Grid>
          <Grid container className="boxpadding">
          <Grid item xs={12} sm={12} md={12} className="pl-15 pr-15">
              <div className='role-container'>                 
                  <div className='blueTitle'>  
                  <span className="notsupported">Not supported</span> promotion                
                  </div>
                  <div className='grayTitle'>   
                  I Do not support for promotion as this employee comes under qualitative feedback, measurable performance metrics                  
                    </div>                
              </div>               
            </Grid>
          </Grid>
          <Grid container className="boxpadding">
          <Grid item xs={12} sm={12} md={12} className="pl-15 pr-15">
              <div className='role-container'>                 
                  <div className='blueTitle'>  
                  You <span className="supported">Supported</span> promotion             
                  </div>
                  <div className='grayTitle'>   
                  Yes, I support for promotion as this employee comes under qualitative feedback, measurable performance metrics                  
                    </div>                
              </div>               
            </Grid>
          </Grid>
          <Grid container className="boxpadding">
          <Grid item xs={12} sm={12} md={12} className="pl-15 pr-15">
              <div className='role-container'>                 
                  <div className='blueTitle'>  
                  You <span className="notsupported">Not supported</span> promotion                
                  </div>
                  <div className='grayTitle'>   
                  I Do not support for promotion as this employee comes under qualitative feedback, measurable performance metrics                  
                    </div>                
              </div>               
            </Grid>
          </Grid>
          <Grid container className="boxpadding">
          <Grid item xs={12} sm={12} md={12} className="pl-15 pr-15">
              <div className='role-container'>                 
                  <div className='blueTitle'>  
                  <span className="supported">You approved promotion</span>            
                  </div>
                               
              </div>               
            </Grid>
          </Grid>
          <Grid container className="boxpadding">
          <Grid item xs={12} sm={12} md={12} className="pl-15 pr-15">
              <div className='role-container'>                 
                  <div className='blueTitle'>  
                  <span className="notsupported">You declined promotion</span>                 
                  </div>
                              
              </div>               
            </Grid>
          </Grid>
          
          <Grid container className="boxpadding">
            <Grid item xs={12} sm={4} md={4}>
              <div className='blueTitle pl-15'>{"Your feedback with justification"}</div>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <div className='role-container pr-15'>
                
                  <div className='role-border-div'>
                    <div className='d-block ai-center customRadiocolor'>
                    <FormControl>
      <RadioGroup
        name="responseBoolean"
        value={selectedValue}
        onChange={handleChange}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
      >
        <FormControlLabel
          value={"true"}
          control={<Radio />}
          label={
            <span>
              Support
            </span>
          }
        />
        <FormControlLabel
          value={"false"}
          control={<Radio />}
          label={
            <span>
              Do not support
            </span>
          }
        />
      </RadioGroup>
    </FormControl>
                    </div>
                  </div>
                  <div className='role-border-div'>
                    <div className='d-block ai-center'>
                    {/* <Typography className='field-label'>{"Your Message"}</Typography> */}
                    <TextField
                        name="yourMessage"
                        id="outlined-multiline-static"
                        variant="outlined"
                        placeholder="Enter your message"
                        multiline
                        rows={4}
                        value="I would like him to Thing Big and Strategically as a VP (showcase this in areas outside of the standard input focus)"
                        style={{ width: "100%", marginTop: "5px" }}
                        InputProps={{
                            style: {
                                color: "#7E7E7E",
                                fontSize: "16px",
                                fontWeight: "lighter",
                                backgroundColor: "#FFFFFF",
                                borderRadius: "8px",
                            },
                        }}
                    />
                    </div>
                  </div>
                  
               
              </div>

              
            </Grid>
          </Grid>
            <div className="save-submit-btn-div-right">
                    <Button className="save-draft">{"Cancel"}</Button>
                    <Button className="submit-list">{"Submit"}</Button>
                </div> 
        </Grid>
      </Grid>
    </Grid>

    <UserRightDrawer
        anchorUserRightDrawer="right"
        openUserStateRight={stateUser.right}
        onCloseUserRightDrawer={toggleUserDrawer(false)}
      >
      </UserRightDrawer>

  </div>
    



  );
};

export default ViewPramotionForm;
