import React from "react";

function CommunityIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27" 
      height="28" 
      fill="none"
      viewBox="0 0 16 17"
    >
      <g clipPath="url(#clip0_4873_891)">
        <path
          fill="#fff"
          d="M7.992 16.793L.825 12.646V4.37L7.992.242l7.168 4.146v8.276l-7.168 4.129zM1.54 12.235l6.452 3.735 6.453-3.735V4.782L7.992 1.064 1.54 4.782v7.453z"
        ></path>
        <path
          fill="#fff"
          d="M.235 4.451l.358-.619 15.169 8.758-.358.62L.235 4.45z"
        ></path>
        <path
          fill="#fff"
          d="M.231 12.59l15.17-8.759.357.62-15.17 8.757-.357-.619z"
        ></path>
        <path fill="#fff" d="M7.636-.044h.715v17.212h-.715V-.044z"></path>
        <path
          fill="#004259"
          d="M2.255 4.62a.957.957 0 01-1.912 0c0-.518.429-.947.947-.947.519 0 .965.429.965.947z"
        ></path>
        <path
          fill="#fff"
          d="M1.29 5.943c-.715 0-1.305-.59-1.305-1.305s.59-1.304 1.305-1.304 1.305.59 1.305 1.304c.018.715-.572 1.305-1.305 1.305zm0-1.912a.595.595 0 00-.59.59c0 .321.269.59.59.59.322 0 .59-.269.59-.59.018-.322-.25-.59-.59-.59z"
        ></path>
        <path
          fill="#004259"
          d="M15.66 4.62a.957.957 0 01-1.913 0c.001-.518.43-.947.948-.947.519 0 .966.429.966.947z"
        ></path>
        <path
          fill="#fff"
          d="M14.695 5.943c-.715 0-1.304-.59-1.304-1.305s.59-1.304 1.304-1.304c.715 0 1.305.59 1.305 1.304.018.715-.572 1.305-1.305 1.305zm0-1.912a.595.595 0 00-.59.59c0 .321.269.59.59.59.322 0 .59-.269.59-.59.018-.322-.25-.59-.59-.59z"
        ></path>
        <path
          fill="#004259"
          d="M8.922.688a.954.954 0 01-.947.947.954.954 0 01-.948-.947c0-.518.43-.947.948-.947s.947.429.947.947z"
        ></path>
        <path
          fill="#fff"
          d="M7.975 2.01C7.26 2.01 6.67 1.422 6.67.707c0-.715.59-1.305 1.305-1.305S9.28-.009 9.28.706s-.59 1.305-1.305 1.305zm0-1.912a.595.595 0 00-.59.59c0 .322.268.59.59.59.322 0 .59-.268.59-.59a.595.595 0 00-.59-.59z"
        ></path>
        <path
          fill="#004259"
          d="M8.923 16.31a.954.954 0 01-.947.947.954.954 0 01-.948-.947c0-.518.43-.947.948-.947s.947.41.947.947z"
        ></path>
        <path
          fill="#fff"
          d="M7.975 17.615c-.715 0-1.305-.59-1.305-1.305s.59-1.305 1.305-1.305 1.305.59 1.305 1.305-.59 1.305-1.305 1.305zm0-1.913a.595.595 0 00-.59.59c0 .322.268.59.59.59.322 0 .59-.268.59-.59a.595.595 0 00-.59-.59z"
        ></path>
        <path
          fill="#004259"
          d="M2.255 12.485a.957.957 0 01-1.912 0c0-.518.429-.947.947-.947s.965.43.965.947z"
        ></path>
        <path
          fill="#fff"
          d="M1.29 13.807c-.715 0-1.305-.59-1.305-1.305s.59-1.305 1.305-1.305 1.305.59 1.305 1.305c.018.715-.572 1.305-1.305 1.305zm0-1.913a.595.595 0 00-.59.59c0 .322.268.59.59.59.322 0 .59-.268.59-.59.018-.322-.25-.59-.59-.59z"
        ></path>
        <path
          fill="#004259"
          d="M15.66 12.485a.957.957 0 01-1.912 0c0-.518.43-.947.947-.947.519 0 .966.43.966.947z"
        ></path>
        <path
          fill="#fff"
          d="M14.695 13.807c-.715 0-1.305-.59-1.305-1.305s.59-1.305 1.305-1.305S16 11.787 16 12.502c.018.715-.572 1.305-1.305 1.305zm0-1.913a.595.595 0 00-.59.59c0 .322.268.59.59.59.322 0 .59-.268.59-.59.018-.322-.25-.59-.59-.59zM7.993 12.36a3.867 3.867 0 01-3.861-3.862 3.867 3.867 0 013.86-3.86 3.867 3.867 0 013.861 3.86 3.867 3.867 0 01-3.86 3.861z"
        ></path>
        <path
          fill="#fff"
          d="M7.993 12.717A4.226 4.226 0 013.774 8.5 4.226 4.226 0 017.993 4.28a4.226 4.226 0 014.218 4.218 4.226 4.226 0 01-4.218 4.218zm0-7.721c-1.93 0-3.486 1.573-3.486 3.503a3.509 3.509 0 003.503 3.503A3.509 3.509 0 0011.514 8.5c-.018-1.93-1.591-3.503-3.521-3.503z"
        ></path>
        <path
          fill="#fff"
          d="M5.866 9.983a2.562 2.562 0 01-.465-1.484c0-1.43 1.162-2.61 2.61-2.61 1.43 0 2.61 1.162 2.61 2.61 0 .536-.161 1.055-.465 1.484a1.382 1.382 0 00-1.287-.876h-1.68c-.626 0-1.126.357-1.323.876z"
        ></path>
        <path
          fill="#fff"
          d="M9.118 8.124c0 .626-.5 1.126-1.126 1.126-.625 0-1.126-.5-1.126-1.126 0-.625.5-1.126 1.126-1.126.626 0 1.126.5 1.126 1.126z"
        ></path>
        <path
          fill="#004259"
          d="M8 9c-1.108 0-2-.892-2-2s.892-2 2-2 2 .892 2 2-.892 2-2 2zm0-3.036c-.578 0-1.036.458-1.036 1.036 0 .578.458 1.036 1.036 1.036.578 0 1.036-.458 1.036-1.036 0-.578-.458-1.036-1.036-1.036z"
        ></path>
        <path
          fill="#004259"
          d="M11 12h-.923v-1.872c0-.615-.439-1.102-.992-1.102h-2.17c-.553 0-.992.487-.992 1.102V12H5v-1.872C5 8.948 5.854 8 6.915 8h2.17C10.146 8 11 8.949 11 10.128V12z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_4873_891">
          <path
            fill="#fff"
            d="M0 0H16V16H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default CommunityIcon;
