import React from "react";

function RewardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27" 
      height="28" 
      fill="none"
      viewBox="0 0 16 17"
    >
      <g fill="#fff" clipPath="url(#clip0_4873_923)">
        <path d="M13.973 13.813L11.99 9.846a5.957 5.957 0 001.029-3.354A5.998 5.998 0 007.027.5a5.998 5.998 0 00-5.992 5.992c0 1.241.38 2.396 1.03 3.354L.08 13.813a.47.47 0 00.419.679h2.276l1.366 1.82a.469.469 0 00.794-.071l1.88-3.762a5.872 5.872 0 00.421 0l1.881 3.762a.469.469 0 00.794.072l1.366-1.821h2.276a.469.469 0 00.42-.679zm-9.536 1.33l-1.052-1.401a.468.468 0 00-.375-.188H1.258l1.455-2.909a5.99 5.99 0 003.114 1.718l-1.39 2.78zM1.973 6.492a5.06 5.06 0 015.054-5.054 5.06 5.06 0 015.054 5.054 5.06 5.06 0 01-5.054 5.054 5.06 5.06 0 01-5.054-5.054zm9.07 7.062a.47.47 0 00-.374.188l-1.051 1.401-1.39-2.78a5.989 5.989 0 003.113-1.718l1.455 2.91h-1.752z"></path>
        <path d="M9.42 7.27l1.31-1.563a.469.469 0 00-.246-.756l-1.978-.495-1.082-1.728a.469.469 0 00-.794 0L5.548 4.456l-1.977.495a.469.469 0 00-.245.756l1.309 1.562-.141 2.034a.47.47 0 00.643.467l1.89-.762 1.89.762a.469.469 0 00.644-.467L9.42 7.269zm-.84-.458a.468.468 0 00-.108.333l.102 1.475-1.372-.552a.469.469 0 00-.35 0l-1.371.552.102-1.475a.47.47 0 00-.108-.333l-.95-1.133L5.96 5.32a.469.469 0 00.284-.206l.784-1.253.785 1.253a.468.468 0 00.283.206l1.435.359-.95 1.133z"></path>
      </g>
      <defs>
        <clipPath id="clip0_4873_923">
          <path
            fill="#fff"
            d="M0 0H16V16H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default RewardIcon;
