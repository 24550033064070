import React, { useEffect, useState } from "react";
import { IoMdSearch } from "react-icons/io";
import {
    Grid,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import GreetingCard from './components/GreetingCard';

const SendAGreetingCardModal = ({ openSendAGreetingCardModal, handleSendAGreetingCardModalClose }) => {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery("(max-width: 480px)");
    const [anniversaryView, setAnniversaryView] = useState(true);
    const [birthdayView, setBirthdayView] = useState(false);
    const [babyBirthView, setBabyBirthView] = useState(false);
    const [appreciationView, setAppreciationView] = useState(false);

    const handleAnniversary = () => {
        setAnniversaryView(true);
        setBirthdayView(false);
        setBabyBirthView(false);
        setAppreciationView(false);
    };

    const handleBirthday = () => {
        setAnniversaryView(false);
        setBirthdayView(true);
        setBabyBirthView(false);
        setAppreciationView(false);
    };

    const handleBabyBirth = () => {
        setAnniversaryView(false);
        setBirthdayView(false);
        setBabyBirthView(true);
        setAppreciationView(false);
    };

    const handleAppreciation = () => {
        setAnniversaryView(false);
        setBirthdayView(false);
        setBabyBirthView(false);
        setAppreciationView(true);
    };

    return (
        <div>
            <Dialog
                open={openSendAGreetingCardModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                PaperProps={{
                    style: {
                        backgroundColor: "#F9F9F9",
                        borderRadius: "20px",
                        // width: "52%",
                        width: isMobile ? "100%" : "52%",
                    },
                }}
            >
                <DialogContent>
                    <Grid container spacing={4} className="kudos-for-modal">
                        <Grid item xs={12} md={12} sm={12}>
                            {/*----- Send A Greeting Card & Deattach Greeting Card -----*/}
                            <div className="greeting-card">
                                <div className="greeting-heading">
                                    <Typography className="greeting-text">{"Send A Greeting Card"}</Typography>
                                </div>

                                {/*----- Anniversary & Birthday & Baby Birth & Appreciation Tabbar -----*/}
                                <Grid class="greeting-tab-btn-List">
                                    <a onClick={handleAnniversary} class={anniversaryView === true ? "active-btn" : "inActive-btn"}>{"Anniversary"}</a>
                                    <a onClick={handleBirthday} class={birthdayView === true ? "active-btn" : "inActive-btn"}>{"Birthday"}</a>
                                    <a onClick={handleBabyBirth} class={babyBirthView === true ? "active-btn" : "inActive-btn"}>{"Baby Birth"}</a>
                                    <a onClick={handleAppreciation} class={appreciationView === true ? "active-btn" : "inActive-btn"}>{"Appreciation"}</a>
                                </Grid>

                                {/*----- Anniversary & Birthday & Baby Birth & Appreciation View -----*/}
                                <Grid>
                                    {anniversaryView === true &&
                                        <GreetingCard />
                                    }

                                    {birthdayView === true &&
                                        <GreetingCard />
                                    }

                                    {babyBirthView === true &&
                                        <GreetingCard />
                                    }

                                    {appreciationView === true &&
                                        <GreetingCard />
                                    }
                                </Grid>
                            </div>


                            {/*----- Your Message -----*/}
                            <div className="textAreaSection">
                                <label htmlFor="outlined-multiline-static">{"Your Message "}</label>
                                <TextField
                                    name="demonstrated"
                                    id="outlined-multiline-static"
                                    variant="outlined"
                                    placeholder="Please provide Overall feedback"
                                    multiline
                                    rows={3}
                                    value="I would like him to Thing Big and Strategically as a VP (showcase this in areas outside of the standard input focus)"
                                    style={{ width: "100%" }}
                                    InputProps={{
                                        style: {
                                            color: "#7E7E7E",
                                            fontSize: "16px",
                                            fontWeight: "lighter",
                                            border: "1px solid #D9D9D9",
                                            backgroundColor: "#FFFFFF",
                                            borderRadius: "8px",
                                        },
                                    }}
                                />
                            </div>

                            {/*----- Cancel & Done Button -----*/}
                            <div className="botttombtn">
                                <button
                                    className="cancel-btn"
                                    onClick={handleSendAGreetingCardModalClose}
                                    color="transparent"
                                    variant="outlined"
                                >
                                    Cancel
                                </button>
                                <button
                                    className="update-btn"
                                    // onClick={() => {}}
                                    type="submit"
                                    variant="outlined"
                                >
                                    Done
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default SendAGreetingCardModal;
