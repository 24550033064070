import React, { useState } from 'react';
import { Button, Grid, Typography,TextField, Drawer,InputAdornment,Box,Checkbox,item } from '@mui/material';
import EditUserProfile from "../../../../../assets/images/successionPlan/editUserProfile.png";
import DrawerCloseIcon from "../../../../../assets/images/successionPlan/drawerCloseIcon.svg";
import Filled from './components/Filled';
import UnFilled from './components/UnFilled';
import NoActionTaken from './components/NoActionTaken';
import searchIcon from "../../../../../assets/images/promotions/searchIcon.svg";
import CheckCircle from "../../../../../assets/images/successionPlan/checkCircle.svg";
import BlueIcon from "../../../../../assets/images/successionPlan/blueDot.svg";
import PlusIcon from "../../../../../assets/images/successionPlan/plusIcon.svg";

const CommentsDrawer = ({ anchorRightDrawer, openStateRight, onCloseRightDrawer }) => {

    const [filledView, setFilledView] = useState(true);
    const [unFilledView, setUnFilledView] = useState(false);
    const [noActionTakenView, setNoActionTakenView] = useState(false);

    const handleFilled = () => {
        setFilledView(true);
        setUnFilledView(false);
        setNoActionTakenView(false);
    };

    const handleUnFilled = () => {
        setFilledView(false);
        setUnFilledView(true);
        setNoActionTakenView(false);
    };

    const handleNoActionTaken = () => {
        setFilledView(false);
        setUnFilledView(false);
        setNoActionTakenView(true);
    };

    const [isChecked, setIsChecked] = useState(false); // Initialize state

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev); // Toggle state
  };

    return (
        <Drawer
            anchor={anchorRightDrawer}
            open={openStateRight}
        // onClose={onCloseRightDrawer}
        >
            <Grid container spacing={2} className="from-stakeholders-drawer">
                <Grid container item xs={12} md={10} sm={10}>
                    <img
                        src={EditUserProfile}
                        alt="EditUserProfile"
                        height={50}
                        width={50}
                    />
                    <Grid className="user-grid">
                        <Typography className="right-user-name">{"Ashish Reji"}</Typography>
                        <Typography className="user-id">{"ID: 26k7"}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={2} sm={2} className="ta-right">
                    <img
                        src={DrawerCloseIcon}
                        alt="DrawerCloseIcon"
                        height={17}
                        width={17}
                        onClick={onCloseRightDrawer}
                    />
                </Grid>


                <Grid item xs={12} sm={12} md={12}>
                <TextField
                        name="scopeOfRole"
                        id="outlined-multiline-static"
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder='Search names, parameter'
                        value={""}
                        sx={{
                            width: '100%',
                            mt: '0px',
                            "& fieldset": {
                                borderRadius: '8px',
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img
                                        src={searchIcon}
                                        className="searchIcon"
                                        alt="Search Icon"
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Typography className="title-stakeholders mt-3">{"Comments"}</Typography>

                    {/*----- Top Button Section -----*/}
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className="description-title">
                            <img src={BlueIcon} alt={"Blue Icon"} className="dot-icon"/> {"Next recommended role"}
                        </Typography>
                        <Grid item xs={12} sm={12} md={12} className="is-comments-container opacityBox">
                            <Grid className="user-comments-card">
                                <Grid className="d-flex align-items-center justify-content-between mb-2">
                                    <Typography className="hr-name">
                                    <span className="hrname">{"HR - John Doe"}</span> {"24 Nov 2024, 04:00PM"}
                                    </Typography>
                                    <img
                                    src={CheckCircle}
                                    alt={"Check Circle"}
                                    height={20}
                                    width={20}
                                    className="dotBlue-icon"
                                    />
                                </Grid>
                                <Grid className="d-flex align-items-center justify-content-between">
                                    <Typography className="hr-desc">
                                    {"Can you please make this sentence a short n single liner not more than 3-4 words?"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="is-comments-container">
                            <Grid className="user-comments-card">
                                {/*----- User 1 -----*/}
                                <Grid className="d-flex align-items-center justify-content-between mb-2">
                                    <Typography className="hr-name">
                                    <span className="hrname">{"Sr. Manager - Jessy R."}</span> {"24 Nov 2024, 04:00PM"}
                                    </Typography>
                                    <Checkbox
                                        checked={isChecked} // Bind state to `checked`
                                        onChange={handleCheckboxChange} // Handle toggle
                                        sx={{
                                            '&.Mui-checked': {
                                            color: '#00425A',
                                            '& .MuiSvgIcon-root': {
                                                fill: 'transparent',
                                                stroke: '#00425A',
                                                padding: '0px',
                                            },
                                            },
                                            '&.MuiCheckbox-root': {
                                            // Additional styles for unchecked state (if needed)
                                            },
                                        }}
                                        />  
                                </Grid>
                                <Grid className="d-flex align-items-center justify-content-between">
                                    <Typography className="hr-desc">
                                    {"Can you please make this sentence a short n single liner not more than 3-4 words?"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid className="border-devider" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className="description-title">
                            <img src={BlueIcon} alt={"Blue Icon"} className="dot-icon"/> {"Scope of role"}
                        </Typography>
                        <Grid item xs={12} sm={12} md={12} className="is-comments-container opacityBox">
                            <Grid className="user-comments-card">
                                <Grid className="d-flex align-items-center justify-content-between mb-2">
                                    <Typography className="hr-name">
                                    <span className="hrname">{"HR - John Doe"}</span> {"Ramesh Sahu"}
                                    </Typography>
                                    <img
                                    src={CheckCircle}
                                    alt={"Check Circle"}
                                    height={20}
                                    width={20}
                                    className="dotBlue-icon"
                                    />
                                </Grid>
                                <Grid className="d-flex align-items-center justify-content-between">
                                    <Typography className="hr-desc">
                                    {"Can you please make this sentence a short n single liner not more than 3-4 words?"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="is-comments-container">
                            <Grid className="user-comments-card">
                                {/*----- User 1 -----*/}
                                <Grid className="d-flex align-items-center justify-content-between mb-2">
                                    <Typography className="hr-name">
                                    <span className="hrname">{"Sr. Manager - Jessy R."}</span> {"24 Nov 2024, 04:00PM"}
                                    </Typography>
                                    <Checkbox
                                        checked={isChecked} // Bind state to `checked`
                                        onChange={handleCheckboxChange} // Handle toggle
                                        sx={{
                                            '&.Mui-checked': {
                                            color: '#00425A',
                                            '& .MuiSvgIcon-root': {
                                                fill: 'transparent',
                                                stroke: '#00425A',
                                                padding: '0px',
                                            },
                                            },
                                            '&.MuiCheckbox-root': {
                                            // Additional styles for unchecked state (if needed)
                                            },
                                        }}
                                        />  
                                </Grid>
                                <Grid className="d-flex align-items-center justify-content-between">
                                    <Typography className="hr-desc">
                                    {"Can you please make this sentence a short n single liner not more than 3-4 words?"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid className="border-devider" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className="description-title">
                            <img src={BlueIcon} alt={"Blue Icon"} className="dot-icon"/> {"Assess competencies"}
                        </Typography>
                        <Grid item xs={12} sm={12} md={12} className="is-comments-container opacityBox">
                            <Grid className="user-comments-card">
                                <Grid className="d-flex align-items-center justify-content-between mb-2">
                                    <Typography className="hr-name">
                                    <span className="hrname">{"HR - John Doe"}</span> {"Ramesh Sahu"}
                                    </Typography>
                                    <img
                                    src={CheckCircle}
                                    alt={"Check Circle"}
                                    height={20}
                                    width={20}
                                    className="dotBlue-icon"
                                    />
                                </Grid>
                                <Grid className="d-flex align-items-center justify-content-between">
                                    <Typography className="hr-desc">
                                    {"Can you please make this sentence a short n single liner not more than 3-4 words?"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="is-comments-container">
                            <Grid className="user-comments-card">
                                {/*----- User 1 -----*/}
                                <Grid className="d-flex align-items-center justify-content-between mb-2">
                                    <Typography className="hr-name">
                                    <span className="hrname">{"Sr. Manager - Jessy R."}</span> {"24 Nov 2024, 04:00PM"}
                                    </Typography>
                                    <Typography component="label" className="emp-name-checkbox">
                                    <Checkbox
                                        checked={isChecked} // Bind state to `checked`
                                        onChange={handleCheckboxChange} // Handle toggle
                                        sx={{
                                            '&.Mui-checked': {
                                            color: '#00425A',
                                            '& .MuiSvgIcon-root': {
                                                fill: 'transparent',
                                                stroke: '#00425A',
                                                padding: '0px',
                                            },
                                            },
                                            '&.MuiCheckbox-root': {
                                            // Additional styles for unchecked state (if needed)
                                            },
                                        }}
                                        />                                            
                                        </Typography>                                   
                                </Grid>
                                <Grid className="d-flex align-items-center justify-content-between">
                                    <Typography className="hr-desc">
                                    {"Can you please make this sentence a short n single liner not more than 3-4 words?"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid className="border-devider" />
                    </Grid>
                </Grid>
            </Grid>
        </Drawer>
    );
};

export default CommentsDrawer;