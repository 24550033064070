import React, { useState } from 'react';
import { Grid, Typography, Checkbox } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'bootstrap';
import UserName from "../../../../assets/images/MyTeamCompensation/userName.svg";

const dummyData1 = [
    {
        pic: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1gdnMYvRNlY_xomNI9zzdKvFq-QDAIoq9mCH9qvGb-xE9bVS4V9g3j08bS46aprGHt4c&usqp=CAU",
        name: "Wallace Wi",
        selected: true,
        id: 41
    },
    {
        pic: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSi8EO26WdA-KDg_Hn5Wx2X2FNAOxMeycBvhgmX5lTG7yJi_vgzMPM52cpPKwOE6sPDJO8&usqp=CAU",
        name: "Tory Lee",
        selected: false,
        id: 42
    },
    {
        pic: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRgt5EvNX0PUKkxCIReP47zMXXz89eMYWzaFR_W_sXGs5cZqW8aN85ZRrw_y_8hJyEmsM&usqp=CAU",
        name: "John Doe",
        selected: true,
        id: 43
    },
    {
        pic: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAEYEYOfbkP1cdW0M56pMNLvJUzQ5LhboinQ7EFP8ZMYbe9EHOm2wN-79RgI58YqBFitk&usqp=CAU",
        name: "Jane Doe",
        selected: false,
        id: 44
    }
]


const MyTeam = ({ }) => {
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState("All");
    const [dummyData, setDummyData] = useState(dummyData1);

    const handleCheckboxChange = (id) => {
        const newData = dummyData.map((item) => {
            if (item?.id === id) {
                return { ...item, selected: !item?.selected };
            }
            return item;
        });
        setDummyData(newData);
    }

    return (
        <Grid item xs={12} sm={12} md={12}>
            <Grid item xs={12} sm={12} md={12} className="tabs-outer">
                <Grid className="tabs">
                    <button className={`tab ${activeTab === "All" ? "active" : ""}`} onClick={() => { setActiveTab("All"); }}>{"All"}</button>
                    <button className={`tab ${activeTab === "Selected" ? "active" : ""}`} onClick={() => { setActiveTab("Selected"); }}>{"Selected"} ({dummyData.filter((item) => item?.selected).length})</button>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} className="table-card">
                {/*----- Header -----*/}
                
                <Grid>
                    <Grid container className="table-item">
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography className="table-header-txt ml-30">{"Team member name"}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} className="ta-center">
                            <Typography className="table-header-txt">{"Send"}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} className="ta-center">
                            <Typography className="table-header-txt">{"Download"}</Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} className="item-border"></Grid>
                </Grid>

                {/*----- Table List -----*/}
                {
                    dummyData.filter((item) => activeTab === "All" ? true : item?.selected).map((item, index) => (
                        <Grid>
                            <Grid container className="table-item">
                        <Grid container item xs={12} sm={4} md={4}>
                            <Checkbox 
                            checked={item?.selected} 
                            onChange={() => handleCheckboxChange(item?.id)}
                            className="marginLeft-9" 
                            />
                            <img
                                src={item?.pic}
                                alt="Icon"
                                height={40}
                                width={40}
                            />
                                <Grid className="ml-10">
                                    <Typography className="table-user-name">{item?.name}</Typography>
                                    <Typography className="table-user-id">{"ID: " + item?.id}</Typography>
                                </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} className="ta-center">
                            <button className="send-download-btn">{"Send Letter"}</button>
                        </Grid>
                                <Grid item xs={12} sm={4} md={4} className="ta-center">
                                    <button className="send-download-btn">{"Download Letter"}</button>
                                </Grid>
                            </Grid>

                        <Grid item xs={12} sm={12} md={12} className="item-border"></Grid>
                    </Grid>
                ))
                }              
            </Grid>
        </Grid>
    );
};

export default React.memo(MyTeam);
