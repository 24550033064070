import React from "react";

function LeaveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27" 
      height="28" 
      fill="none"
      viewBox="0 0 16 17"
    >
      <g
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.3"
        clipPath="url(#clip0_4873_847)"
      >
        <path d="M11.551 7.815a.296.296 0 00-.419.42l1.076 1.075H8.77a.297.297 0 000 .593h3.437l-1.076 1.076a.296.296 0 00.323.488.296.296 0 00.096-.07l1.582-1.58a.297.297 0 000-.42l-1.582-1.582z"></path>
        <path d="M14.703 1.796h-2.287a1.582 1.582 0 00-2.953-.566 1.582 1.582 0 00-2.768 0 1.582 1.582 0 00-2.952.566H1.455a.297.297 0 00-.297.296v13.249a.296.296 0 00.297.296h13.248a.296.296 0 00.297-.296V2.092a.296.296 0 00-.297-.296zm-3.855-.791a.989.989 0 01.968.79H9.88a.989.989 0 01.969-.79zm-2.769 0a.989.989 0 01.97.79H7.11a.989.989 0 01.97-.79zm-2.768 0a.989.989 0 01.969.79H4.342a.989.989 0 01.969-.79zM3.78 2.389a1.582 1.582 0 001.53 1.186h.06a.297.297 0 00-.01-.593h-.05a.989.989 0 01-.906-.593H6.55a1.582 1.582 0 001.53 1.186h.06a.297.297 0 00-.01-.593h-.05a.989.989 0 01-.905-.593h2.143a1.582 1.582 0 001.53 1.186h.06a.297.297 0 00-.01-.593h-.05a.989.989 0 01-.905-.593h4.465v1.977H1.752V2.39H3.78zM1.75 15.044V4.959h12.656v10.085H1.752z"></path>
        <path d="M7.387 7.333H3.433a.297.297 0 00-.297.297v3.954a.297.297 0 00.297.297h3.954a.297.297 0 00.297-.297V7.63a.297.297 0 00-.297-.297zm-.296 3.955H3.729V7.926h3.362v3.362z"></path>
      </g>
      <defs>
        <clipPath id="clip0_4873_847">
          <path
            fill="#fff"
            d="M0 0H16V16H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LeaveIcon;
