import React from "react";

function PayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27" 
      height="28" 
      fill="none"
      viewBox="0 0 16 17"
    >
      <g
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.3"
        clipPath="url(#clip0_4873_930)"
      >
        <path d="M8.223 7.747a.267.267 0 00-.102-.004c-.34-.042-.598-.348-.598-.713a.326.326 0 00-.087-.223.289.289 0 00-.21-.093.289.289 0 00-.211.093.326.326 0 00-.087.223c0 .609.383 1.141.935 1.302v.316c0 .084.032.165.088.224.055.06.131.092.21.092a.289.289 0 00.21-.092.326.326 0 00.087-.224v-.297c.637-.141 1.07-.772 1.003-1.46-.067-.687-.612-1.21-1.263-1.21-.373 0-.675-.322-.675-.718 0-.396.302-.718.675-.718.373 0 .675.322.675.718 0 .084.032.164.088.224.055.059.131.092.21.092a.289.289 0 00.21-.092.326.326 0 00.087-.224c0-.639-.422-1.19-1.01-1.321v-.288a.326.326 0 00-.087-.223.289.289 0 00-.21-.093.289.289 0 00-.21.093.326.326 0 00-.088.223v.307c-.613.178-1.008.811-.924 1.482.085.67.623 1.17 1.26 1.17.367 0 .667.313.674.704.007.39-.282.715-.65.73z"></path>
        <path d="M3.884 6.02c0 2.532 1.931 4.584 4.313 4.584 2.383 0 4.314-2.052 4.314-4.583S10.58 1.438 8.197 1.438c-2.38.002-4.31 2.053-4.313 4.583zm4.313-3.95c2.054 0 3.719 1.769 3.719 3.95 0 2.183-1.665 3.952-3.719 3.952-2.053 0-3.718-1.769-3.718-3.951C4.48 3.84 6.145 2.072 8.197 2.07z"></path>
        <path d="M13.977 11.384l-2.33 1.17a1.971 1.971 0 00-.675-.762 1.818 1.818 0 00-.943-.3l-2.15-.062a2.168 2.168 0 01-.978-.268l-.22-.12a3.679 3.679 0 00-1.788-.465c-.622 0-1.236.161-1.787.468l.013-.527a.334.334 0 00-.08-.226.299.299 0 00-.209-.098l-2.359-.07a.281.281 0 00-.212.087.316.316 0 00-.093.22l-.142 5.5a.334.334 0 00.08.227.297.297 0 00.209.099l2.358.069h.009a.289.289 0 00.207-.09.326.326 0 00.09-.218l.007-.263.613-.349a.996.996 0 01.784-.09l3.66 1.09a3.704 3.704 0 00.824.093c.571.001 1.137-.13 1.657-.384a.274.274 0 00.037-.021l5.305-3.647a.316.316 0 00.127-.19.334.334 0 00-.03-.231c-.393-.743-1.267-1.025-1.984-.642zm-13.35 4.25l.125-4.869 1.764.052-.126 4.868-1.764-.052zm9.627-.083a3.192 3.192 0 01-2.063.244l-3.649-1.088a1.559 1.559 0 00-1.226.142l-.313.178.084-3.236a3.11 3.11 0 013.322-.188l.218.12c.383.21.805.326 1.235.339l2.151.063c.604.018 1.117.475 1.24 1.104l-3.204-.094c-.164-.004-.301.133-.306.308a.328.328 0 00.082.226.29.29 0 00.207.098l3.536.104h.009a.289.289 0 00.207-.09.326.326 0 00.09-.218 2.132 2.132 0 00-.029-.41l2.39-1.2a.863.863 0 011.035.15l-5.016 3.448zM8.495 2.549V-.453a.326.326 0 00-.087-.224.289.289 0 00-.21-.093.289.289 0 00-.21.093.326.326 0 00-.088.224v3.002c0 .084.031.164.087.224.056.059.132.092.21.092a.289.289 0 00.21-.092.326.326 0 00.088-.224zm2.23 0V1.127a.326.326 0 00-.086-.224.289.289 0 00-.21-.092.289.289 0 00-.21.092.326.326 0 00-.088.224v1.422c0 .084.031.164.087.224.056.059.132.092.21.092a.289.289 0 00.21-.092.326.326 0 00.088-.224zm-4.46 0V1.127a.326.326 0 00-.088-.224.289.289 0 00-.21-.092.289.289 0 00-.21.092.326.326 0 00-.088.224v1.422c0 .084.032.164.087.224.056.059.132.092.21.092a.289.289 0 00.211-.092.326.326 0 00.087-.224z"></path>
      </g>
      <defs>
        <clipPath id="clip0_4873_930">
          <path
            fill="#fff"
            d="M0 0H16V16H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default PayIcon;
