import React from "react";

function CareerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27" 
      height="28" 
      fill="none"
      viewBox="0 0 16 17"
    >
      <g clipPath="url(#clip0_4873_865)">
        <path
          fill="#fff"
          d="M15.36 13.54h-1.2v-1.52a.4.4 0 00-.4-.4h-1.2V10.1a.4.4 0 00-.4-.4H3.84a.4.4 0 00-.4.4v1.52h-1.2a.4.4 0 00-.4.4v1.52H.64a.4.4 0 00-.4.4v1.92c0 .221.18.4.4.4h14.72a.4.4 0 00.4-.4v-1.92a.4.4 0 00-.4-.4zM4.24 10.5h7.52v1.12H4.24V10.5zm-1.6 1.92h10.72v1.12H2.64v-1.12zm12.32 3.04H1.04v-1.12h13.92v1.12zM5.731 5.748l-.4 2.327a.4.4 0 00.58.422L8 7.397l2.09 1.097a.4.4 0 00.579-.423l-.4-2.326 1.69-1.648a.4.4 0 00-.221-.682l-2.336-.339L8.358.961a.4.4 0 00-.716 0L6.598 3.076l-2.336.34a.4.4 0 00-.22.681l1.69 1.651zm1.19-1.907c.132-.02.244-.1.301-.218L8 2.043l.778 1.577c.057.119.17.199.3.218l1.741.253-1.26 1.228a.406.406 0 00-.116.356l.298 1.734-1.559-.82a.4.4 0 00-.37 0l-1.56.82.298-1.734a.397.397 0 00-.115-.356l-1.26-1.228 1.747-.25z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_4873_865">
          <path
            fill="#fff"
            d="M0 0H16V16H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default CareerIcon;
