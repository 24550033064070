import React, { useState } from 'react';
import { Grid, Typography, Button, Checkbox } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { FaSort } from "react-icons/fa6";
import { AiFillCaretRight } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import { BsChevronDown } from 'react-icons/bs';
import { accordianSVGstyle } from '../../../utils/utils';
import { accordianSVGstyle1 } from '../../../utils/utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PromotionFeedbackRightDrawer from './PromotionFeedbackRightDrawer';


const RequestPromotionFeedback = ({ }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [stateUser, setStateUser] = useState({right: false});
    const toggleUserDrawer = (open) => (event) => {
        setStateUser({ ...stateUser, right: open });
    };

    const nominateEmps = [
        {
            id: 1,
            empCode: 10614,
            empName: 'Ashish Reji',
            designation: 'Assistant Manager',
            perfRating2024: '-',
            perfRating2023: 'Request F/b',
            //   demo: '',
            demo1: '',
            // recommendForPromotion: 'Yes',
            editViewButton: 'View',
        },
        {
            id: 2,
            empCode: 10680,
            empName: 'Akshay C ',
            designation: 'Senior Associate',
            perfRating2024: '3/4 Accepted',
            perfRating2023: 'add',
            demo: 'Umashankar Patidar',
            demo1: '+2',
            // recommendForPromotion: 'Yes',
            editViewButton: 'View',
        },
        {
            id: 3,
            empCode: 10681,
            empName: 'Govind H',
            designation: 'Assistant Manager',
            perfRating2024: '7/7',
            perfRating2023: 'add',
            demo: 'Mihir Jain',
            demo1: '+6',
            // recommendForPromotion: 'No',
            editViewButton: 'View',
        },
        {
            id: 4,
            empCode: 10692,
            empName: 'Umashankar Aravallil',
            designation: 'Senior Associate',
            perfRating2024: '3/3',
            perfRating2023: 'add',
            demo: 'Adarh Sharma',
            demo1: '+2',
            // recommendForPromotion: 'Yes',
            editViewButton: 'View',
        },
        {
            id: 5,
            empCode: 10691,
            empName: 'Akshay C',
            designation: 'Assistant Manager',
            perfRating2024: '-',
            perfRating2023: 'add',
            demo: '',
            demo1: '',
            // recommendForPromotion: 'Yes',
            editViewButton: 'View',
        },
        {
            id: 6,
            empCode: 10614,
            empName: 'Ashish Reji',
            designation: 'Assistant Manager',
            perfRating2024: '2/2',
            perfRating2023: 'add',
            demo: 'Dinesh Rawal',
            demo1: '+1',
            // recommendForPromotion: 'Yes',
            editViewButton: 'View',
        },
        {
            id: 7,
            empCode: 10680,
            empName: 'Akshay C ',
            designation: 'Senior Associate',
            perfRating2024: '-',
            perfRating2023: 'add',
            demo: '',
            demo1: '',
            // recommendForPromotion: 'Yes',
            editViewButton: 'View',
        },
        {
            id: 8,
            empCode: 10681,
            empName: 'Govind H',
            designation: 'Assistant Manager',
            perfRating2024: '5/7',
            perfRating2023: 'add',
            demo: 'Manisha S.',
            demo1: '+6',
            // recommendForPromotion: 'No',
            editViewButton: 'View',
        },
        {
            id: 9,
            empCode: 10692,
            empName: 'Umashankar Aravallil',
            designation: 'Senior Associate',
            perfRating2024: '-',
            perfRating2023: 'add',
            demo: '',
            demo1: '',
            // recommendForPromotion: 'Yes',
            editViewButton: 'View',
        },
        {
            id: 10,
            empCode: 10691,
            empName: 'Akshay C',
            designation: 'Assistant Manager',
            perfRating2024: '4/7',
            perfRating2023: 'add',
            demo: 'Laxman M.',
            demo1: '+6',
            // recommendForPromotion: 'Yes',
            editViewButton: 'View',
        },
    ];




    return (
        <Grid container className="table-card">
            <Grid item xs={12} sm={12} md={12}>
                <div className="nominate-promo-table">
                    <table className="emp-list-table">
                        <tr className="table-head-emp-list">
                            <th className="w-130 pl-20">Emp Code &nbsp;<FaSort className="c-pointer" /></th>
                            <th className="w-130">Emp Name &nbsp;<FaSort className="c-pointer" /></th>
                            <th>Current Designation</th>
                            <th>Feedback Providers</th>
                            <th>Feedback Req. Status</th>
                            {/* <th className="w-130">Recommend for promotion</th> */}
                            <th className="">F/B requests</th>
                        </tr>

                        {nominateEmps.map((item, index) => (
                            <tr>
                                <td className="pl-20">
                                    <div>
                                        <Typography className="emp-table-value-data">{item.empCode}</Typography>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <Typography className="emp-name-checkbox">{item.empName}</Typography>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <Typography className="emp-table-value-data">{item.designation}</Typography>
                                    </div>
                                </td>
                                <td>

                                <div>
                                        <Typography className="emp-table-value-blue-data">{item.demo} 
                                         {item.demo1 === ''?
                                          <div></div> :  <div className="circleText">
                                            
                                          {item.demo1 === "Edit" &&
                                              <AiFillEye className="mr-5" />
                                          }
                                          {item.demo1}
                                      </div>
                                        }   
                                       
                                        <Button className="edit-small-btn" onClick={toggleUserDrawer(true)}>
                                            
                                            {item.perfRating2023 === "Edit" &&
                                                <AiFillEye className="mr-5" />
                                            }
                                            {item.perfRating2023}
                                        </Button> 

                                        </Typography>
                                </div>
                                    {/* <div>
                                        <Typography className="emp-table-value-data">{item.perfRating2023}</Typography>
                                    </div> */}
                                </td>
                                <td>
                                    <div className='text-center'>
                                        <Typography className="emp-table-value-data">{item.perfRating2024}</Typography>
                                    </div>
                                </td>
                                {/* <td>
                                    <div>
                                        <Typography component="label" className="emp-name-checkbox">
                                            <Checkbox
                                                checked={item.recommendForPromotion === "Yes"}
                                                onChange={(event) => handleCheckboxChange(event, item)}
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: '#00425A',
                                                        backgroundColor: 'transparent',
                                                        '& .MuiSvgIcon-root': {
                                                            fill: 'transparent',
                                                            stroke: '#00425A',
                                                        }
                                                    },
                                                    '&.MuiCheckbox-root': {
                                                        backgroundColor: 'transparent',
                                                    }
                                                }}
                                            />
                                            {item.recommendForPromotion}
                                        </Typography>
                                    </div>
                                </td> */}
                                <td className="">
                                    <div>
                                        
                                            <Button className="edit-small-btn" onClick={() => history.push("/manager-feedbacks-member")}>
                                                
                                                {item.editViewButton === "Edit" &&
                                                    <AiFillEye className="mr-5" />
                                                }
                                                {item.editViewButton}
                                            </Button> <div />
                                        
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>

                {/* <div className="save-submit-btn-div">
                    <Button className="save-draft">{"Save Draft"}</Button>
                    <Button className="submit-list">{"Submit List"}</Button>
                </div> */}
            </Grid>

            <PromotionFeedbackRightDrawer
                anchorRightDrawer="right"
                openStateRight={stateUser.right}
                onCloseRightDrawer={toggleUserDrawer(false)}
            >
            </PromotionFeedbackRightDrawer>

        </Grid>
    );
};

export default React.memo(RequestPromotionFeedback);
