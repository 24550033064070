import React, { Suspense, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "../components/pages/dashboard";
import UserProfilePage from "../components/pages/profile";
import { Route, useHistory, useLocation } from "react-router-dom";
import Main from "../components/pages/main";
import LogIn from "../components/common/Login";
import { useSelector } from "react-redux";
import SelfAssesmentPage from "../components/pages/selfAssesment";
import FeedbackPage from "../components/pages/feedback";
import PeedFeedBack from "../components/pages/peerFeedBack"
import ManagerFeedBack from "../components/pages/managerFeedBack"
import InitialPage from '../components/pages/selfAssesment/Initial-Page';
import PageTwo from '../components/pages/selfAssesment/page-two';
import PageThree from '../components/pages/selfAssesment/Page-three';
import PageFour from '../components/pages/selfAssesment/page-four';
import EmpFeedback from "../components/pages/feedback/components/empFeedback";
import Header from "../components/common/Header";
import selfAssessmentAllinone from "../components/pages/selfAssesmentAllinone";
import ChangePassword from "../components/pages/changePassword";
import ResetPassword from "../components/pages/ForgotPassword/ResetPassword"
import ForgotPassword from "../components/pages/ForgotPassword/index"
import ViewFeedback from "../components/pages/feedback/components/viewFeedback";
import ViewFeedback1 from "../components/pages/feedback/components/viewFeedback1";
import NoFeedback from "../components/pages/feedback/components/noFeedback";
import RequestFeedback from "../components/pages/requestFeedback";
import GivePeerFedback from "../components/pages/givePeerFeedback";
import RatingCalibration from "../components/pages/ratingCalibration";
import ManagerCalibration from "../components/pages/managerCalibration";
import AddSession from "../components/pages/ratingCalibration/addSession";
import ViewReport from "../components/pages/ratingCalibration/viewReport";
import ApproveRequest from "../components/pages/approveRequest";
import SentRequestTab from "../components/pages/requestFeedback/sentRequestTab";
import ViewSession from "../components/pages/ratingCalibration/viewSession1";
import MyTeam from "../components/pages/myTeam";
import functionalLeader from "../components/pages/functionalLeader";
import AccessDenied from "../components/common/accessDenied";
import ReadOnlyReport from "../components/pages/ratingCalibration/readOnlyReport";
import ChangedReportPage from "../components/pages/ratingCalibration/viewReport/changeReportPage";
import { isEmptyNullUndefined } from "../components/utils/utils";
import ChangedReportPageForFunctionalLeader from "../components/pages/functionalLeader/finalReport/changeReportPage";
import AuditReport from "../components/pages/ratingCalibration/auditReport";
import PrmotionApprove from "../components/pages/ratingCalibration/viewReport/promotionApprove";
import FViewReport from "../components/pages/functionalLeader/functionalViewReport";
import Compensation from "../components/pages/compensation"
import CompensationAnalytics from "../components/pages/compensationAnalytics";
import EmployeeProfile from "../components/pages/EmployeeProfile";
import CompMyTeam from "../components/pages/compensationMyTeam";
import FormBuilder from "../components/pages/FormBuilder1.0";
import EmployeeGoalView from "../components/pages/goals/employee/goalView";
import ViewGoalsRequest from "../components/pages/goals/manager/viewRequests";
import goalForm from "../components/pages/goals/goalsForm"
import EEPPage from "../components/pages/eep";
import IDPPage from "../components/pages/idp";
import SuccessionPlan from "../components/pages/successionPlan";
import HrSuccessionPlan from "../components/pages/hr/hrSuccessionPlan";
import ReportPerformance from "../components/pages/ReportPerformance/index";
import SchedulingSession from "../components/pages/hr/hrSuccessionPlan/SchedulingSession";
import HrViewSuccessionPlan from "../components/pages/hr/HrViewSuccessionPlan/index";
import HrEditSuccessionPlan from "../components/pages/hr/HrViewSuccessionPlan/components/EditPlan";
import ViewGoal from "../components/pages/goals/manager/viewGoal";
import DevelopmentActionPlans from "../components/pages/idp/components/DevelopmentActionPlans";
import EmployeeEngagement from "../components/pages/EmployeeEngagement/index";
import MyTeamPage from "../components/pages/myTeamPage";
import MyCommunity from "../components/pages/MyCommunity";
import AllView from "../components/pages/MyCommunity/MyTeam/components/AllView";
import MyTeamCommunityViewAll from "../components/pages/MyCommunity/MyTeam";
import Promotions from "../components/pages/Promotions/index";
import EditPromotionsForm from "../components/pages/Promotions/PromotionsForm/EditPramotionForm";
import ViewPromotionsForm from "../components/pages/Promotions/PromotionsForm/ViewPramotionForm";
import ManagerFeedbacksMember from "../components/pages/ManagerFeedbacksMember/index";
import ViewCandidateInComp from "../components/pages/Candidate";
import CalendarEvents from "../components/pages/MyCommunity/components/CalendarEvents";
import RightSideBlock from "../components/pages/MyCommunity/components/rightSideBlock";


const AppRouters = () => {

  const history = useHistory();
  const location = useLocation();

  const userToken = useSelector(
    (state) => state && state.user && state.user.userToken
  );
  const employeeDetails = useSelector((state) => state?.empData?.empData);

  useEffect(() => {
    if (
      userToken == null &&
      !["/forgot-password", "/account/reset/finish","/login"].includes(location.pathname)
    ) {
      history.push("/login");
    }
  }, [userToken]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
 
  return (
    <Suspense fallback={<div></div>}>
       
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick
        />
        <div>
          <Route exact path="/login" component={LogIn} />
          <Route exact path="/login/:xyz" component={LogIn} />
          <Route exact path="/" component={LogIn} />
          {/* <Route exact path = "/" render={() => {
            if(userToken?.id_token !== undefined){
              history.push('/profile');
            }
          }} /> */}

         {window.location.pathname !== "/login" &&
          window.location.pathname !== "/" &&
          window.location.pathname !== "/forgot-password" &&
          window.location.pathname !== "/account/reset/finish" && 
          (
            <Header />
          )}


         {
          !isEmptyNullUndefined(employeeDetails?.permissions) &&
          <Route
            exact
            path="/dashboard"
            component={employeeDetails?.permissions?.includes("home") ? Dashboard : AccessDenied}
          />
         } 

          <Route
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <Route
            exact
            path="/account/reset/finish"
            component={ResetPassword}
          />

          <Route
            exact
            path="/change-password"
            component={ChangePassword}
          />

          <Route
            exact
            path="/home"
            component={Main}
          />

         {
          !isEmptyNullUndefined(employeeDetails?.permissions) &&
          <Route
            exact
            path="/profile"
            component={employeeDetails?.permissions?.includes("view-profile") ? UserProfilePage : AccessDenied}
          />
         } 

          
          <Route
            exact
            path="/self-assesment"
            component={SelfAssesmentPage}
          />
          {/* <Route
            exact
            path="/feedback"
            component={FeedbackPage}
          /> */}

         {
          !isEmptyNullUndefined(employeeDetails?.permissions) &&
          <Route
              exact
              path="/my-team"
              component={employeeDetails?.permissions?.includes("my-team") ? MyTeam : AccessDenied}
            />
         } 

        {
          !isEmptyNullUndefined(employeeDetails?.permissions) &&
          <Route
              exact
              path="/compensation-analytics"
              component={employeeDetails?.permissions?.includes("compensation-analytics") ? CompensationAnalytics : AccessDenied}
              // component={CompensationAnalytics}
            />
         } 
            
          <Route
            exact
            path="/functional-leader"
            component={functionalLeader}
          />
          <Route
            exact
            path="/functional-leader-view-report"
            component={ChangedReportPageForFunctionalLeader}
          />
          <Route
            exact
            path="/functional-leader-view-report-with-blocker"
            component={FViewReport}
          />
          <Route
            path="/self-assesment/initial-page"
            component={InitialPage}
          />
          <Route
            exact
            path="/self-assesment/page-two"
            component={PageTwo}
          />
          <Route
            exact
            path="/self-assesment/page-three"
            component={PageThree}
          />
          <Route
            exact
            path="/self-assesment/page-four"
            component={PageFour}
          />

          {
           !isEmptyNullUndefined(employeeDetails?.permissions) &&
           <Route
            exact
            path="/self-assesment/allinone"
            component={employeeDetails?.permissions?.includes("self-assessment") ? selfAssessmentAllinone : AccessDenied}
          />
          } 
          
          
          <Route
            exact
            path="/manager-feedBack/:id/:isappraisalsubmitted"
            component={ManagerFeedBack}
          />
          <Route
            exact
            path="/peer-feedBack/:id"
            component={PeedFeedBack}
          />
          <Route
            exact
            path="/emp-feedback/:id"
            component={EmpFeedback}
          />
          <Route
            exact
            path="/no-feedback/:id"
            component={NoFeedback}
          />
          <Route
            exact
            path="/view-feedback/:id"
            component={ViewFeedback1}
          />

           {/* request feedback */}

         {
          !isEmptyNullUndefined(employeeDetails?.permissions) &&
           <Route
            exact
            path="/self-assesment/request-feedback"
            component={employeeDetails?.permissions?.includes("request-peer-feedback") ? RequestFeedback : AccessDenied}
          />
         } 

           {/* give peer feedback */}

         {
          !isEmptyNullUndefined(employeeDetails?.permissions) &&
          <Route
            exact
            path="/give-peer-feedback"
            component={employeeDetails?.permissions?.includes("give-peer-feedback") ? GivePeerFedback : AccessDenied}
          />
         } 
         
           {/* rating calibration */}

         {
          !isEmptyNullUndefined(employeeDetails?.permissions) &&
          <Route
            exact
            path="/hrb"
            component={ employeeDetails?.permissions?.includes("rating-calibration") ? RatingCalibration : AccessDenied}
          />
         }      
         {
          !isEmptyNullUndefined(employeeDetails?.permissions) &&
          <Route
            exact
            path="/manager-calibration"
            component={ 
              ManagerCalibration
              // employeeDetails?.permissions?.includes("manager-calibration") ? ManagerCalibration : AccessDenied
            }
          />
         }       
           
           <Route
            exact
            path="/compensation"
            component={employeeDetails?.permissions?.includes("compensation-planning") ? Compensation : AccessDenied} 
          />
           <Route
            exact
            path="/compensation-team"
            // component={employeeDetails?.permissions?.includes("compenssation") ? Compensation : AccessDenied} 
            component={employeeDetails?.permissions?.includes("compensation-my-team") ? CompMyTeam : AccessDenied} 
          />
          <Route
            exact
            path="/view-candidate-in-compensation"
            component={ViewCandidateInComp}
          />
          <Route
            exact
            path="/add-session"
            component={AddSession}
          />
           <Route
            exact
            path="/view-report"
            component={ViewReport}
          />
           <Route
            exact
            path="/promotion-view-report"
            component={PrmotionApprove}
          />
           <Route
            exact
            path="/view-audit-report"
            component={AuditReport}
          />
           <Route
            exact
            path="/changed-report"
            component={ChangedReportPage}
          />
           <Route
            exact
            path="/read-report"
            component={ReadOnlyReport}
          />
           <Route
            exact
            path="/view-session"
            component={ViewSession}
          />

          {
           !isEmptyNullUndefined(employeeDetails?.permissions) &&
           <Route
            exact
            path="/self-assesment/aprove"
            component={employeeDetails?.permissions?.includes("approve-page") ? ApproveRequest : AccessDenied}
          />
          }           
           
           <Route
            exact
            path="/SentRequestTab"
            component={SentRequestTab}
          />

           <Route
            exact
            path="/form-builder"
            component={FormBuilder}
          />

           <Route
            exact
            path="/employee-goals"
            component={EmployeeGoalView}
          />

           <Route
            exact
            path="/ViewGoalsRequest"
            component={ViewGoalsRequest}
          />
          <Route
            exact
            path="/employee-goals-form/:id/:isReplica"
            component={goalForm}
          />
          
          <Route
            exact
            path="/goals/manager/view-goals"
            component={ViewGoal}
          />

          <Route
            exact
            path="/employee-engagement-program"
            component={EEPPage}
          />

          <Route
            exact
            path="/individual-development-plan"
            component={IDPPage}
          />

          <Route
            exact
            path="/succession-plan"
            component={SuccessionPlan}
          />

          <Route
            exact
            path="/hr-succession-plan"
            component={HrSuccessionPlan}
          />

          <Route
            exact
            path="/hr-scheduling-session"
            component={SchedulingSession}
          />

          <Route
            exact
            path="/hr-view-succession-plan"
            component={HrViewSuccessionPlan}
          />

          <Route
            exact
            path="/idp-goal-developement-form"
            component={DevelopmentActionPlans}
          />
          
           <Route
            exact
            path="/360-report"
            component={ReportPerformance}
          />    

           <Route
          exact
          path="/my-team-page"
          component={MyTeamPage}
        />

        <Route
          exact
          path="/employee-engagement"
          component={MyCommunity}
          />

        <Route
          exact
          path="/my-community-all-view"
          component={MyTeamCommunityViewAll}
        />      

        <Route
          exact
          path="/my-community-calendar"
          component={RightSideBlock}
        />

            <Route
              exact
              path="/my-team-promotion"
              component={
                 Promotions
                // employeeDetails?.permissions?.includes("manager-calibration") ? ManagerCalibration : AccessDenied
              }
            />
            <Route
              exact
              path="/promotion-form"
              component={
                EditPromotionsForm
                // employeeDetails?.permissions?.includes("manager-calibration") ? ManagerCalibration : AccessDenied
              }
            />
            <Route
              exact
              path="/view-promotion-form"
              component={
                ViewPromotionsForm
                // employeeDetails?.permissions?.includes("manager-calibration") ? ManagerCalibration : AccessDenied
              }
            />
        

            <Route
              exact
              path="/manager-feedbacks-member"
              component={
                //  Promotions
                ManagerFeedbacksMember
                // EditPromotionsForm
                // ViewPromotionsForm
                // ManagerCalibration
                // employeeDetails?.permissions?.includes("manager-calibration") ? ManagerCalibration : AccessDenied
              }
            />

            
           
        </div>

       

        

        <Route exact path="/employee-profile" component={employeeDetails?.permissions?.includes("EEP") ? EmployeeProfile : AccessDenied} />
      </>
     
    </Suspense>
  );
};

export default AppRouters;
