import React, { useState } from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import EditUserProfile from "../../../../assets/images/successionPlan/editUserProfile.png";
import UserRightArrow from "../../../../assets/images/promotions/userRightArrow.svg";
import AssessmentIcon from "../../../../assets/images/promotions/assessmentIcon.png";
import ListBottomImg from "../../../../assets/images/promotions/listBottomImg.png";
import ChartImg from "../../../../assets/images/promotions/chartImg.png";


const ViewCandidate = ({ name }) => {
    const dispatch = useDispatch();

    const historicalList = [
        { year: "2021-22", performanceRating: "B", baseSalary: "5,00,000", variablePay: "10,000", equity: "10,000", totalCompensation: "5,20,000", tcIncrease: "15%" },
        { year: "2022-23", performanceRating: "A", baseSalary: "7,00,000", variablePay: "12,000", equity: "20,000", totalCompensation: "7,32,000", tcIncrease: "20%" },
        { year: "2023-24", performanceRating: "B", baseSalary: "9,00,000", variablePay: "15,000", equity: "20,000", totalCompensation: "9,35,000", tcIncrease: "19%" },
    ]

    return (
        <Grid item xs={12} sm={12} md={12}>
            {/*----- User Form -----*/}
            <Grid container className="user-form-card">
                <Grid item xs={12} sm={12} md={12} className="user-card">
                    {/*----- User Name & Profile & Emp No -----*/}
                    <Grid className="ta-center">
                        <img
                            src={EditUserProfile}
                            alt="EditUserProfile"
                            height={50}
                            width={50}
                        />
                        <Typography className="profile-name">{name}<span><img src={UserRightArrow} alt="rightArrow" height={16} width={10} /></span></Typography>
                        <Typography className="profile-emp-lable">{"Emp No. "}<span className="profile-emp-no">{"10614"}</span></Typography>
                    </Grid>

                    {/*----- Vertical Border -----*/}
                    <Grid className='vertical-border'></Grid>

                    <Grid>
                        <Grid>
                            <Typography className="profile-field-label">{"Level"}</Typography>
                            <Typography className="profile-field-value">{"L4"}</Typography>
                        </Grid>

                        <Grid className="mt-15">
                            <Typography className="profile-field-label">{"Job Family"}</Typography>
                            <Typography className="profile-field-value">{"Assistant Manager"}</Typography>
                        </Grid>
                    </Grid>

                    <Grid>
                        <Grid>
                            <Typography className="profile-field-label">{"Role"}</Typography>
                            <Typography className="profile-field-value">{"IC"}</Typography>
                        </Grid>

                        <Grid className="mt-15">
                            <Typography className="profile-field-label">{"Retention Risk"}</Typography>
                            <Typography className="profile-field-value">{"No"}</Typography>
                        </Grid>
                    </Grid>

                    <Grid>
                        <Grid>
                            <Typography className="profile-field-label">{"Performance Rating(2023-24)"}</Typography>
                            <Typography className="profile-field-value">{"A"}</Typography>
                        </Grid>

                        <Grid className="mt-15">
                            <Typography className="profile-field-label">{"Rating Multiplier"}</Typography>
                            <Typography className="profile-field-value">{"0.7"}</Typography>
                        </Grid>
                    </Grid>

                    <Grid>
                        <Grid>
                            <Typography className="profile-field-label">{"Suggested TC Placement"}</Typography>
                            <Typography className="profile-field-value">{"TC Min"}</Typography>
                        </Grid>

                        <Grid className="mt-15">
                            <Typography className="profile-field-label">{"Proration Indicatot"}</Typography>
                            <Typography className="profile-field-value">{"Yes"}</Typography>
                        </Grid>
                    </Grid>

                    <Grid>
                        <Grid>
                            <Button className="profile-btn">
                                <img src={AssessmentIcon} alt="reload" />
                                <div>
                                    <Typography className="profile-btn-txt">{"View Performance Assessment"}</Typography>
                                </div>
                            </Button>
                        </Grid>

                        <Grid className="mt-15">
                            <Button className="profile-btn">
                                <img src={AssessmentIcon} alt="reload" />
                                <div>
                                    <Typography className="profile-btn-txt">{"View Employee Profile"}</Typography>
                                </div>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} className="form-card">
                    <Grid container>
                        {/*----- No Salary -----*/}
                        <Grid item xs={12} sm={6} md={6}>
                            <Typography className="form-title">{"No Salary"}</Typography>
                            <Typography className="form-description">{"Lorem ipsum is simply text of printing and type setting"}</Typography>
                            <Typography className="form-sub-title">{"This employee comes under salary increase"}</Typography>
                        </Grid>

                        {/*----- Pro-ration check -----*/}
                        <Grid item xs={12} sm={6} md={6}>
                            <Typography className="form-title">{"Pro-ration check"}</Typography>
                            <Typography className="form-description">{"Lorem ipsum is simply text of printing and type setting"}</Typography>
                            <Typography className="form-sub-title">{"This employee comes under pro-ration"}</Typography>
                        </Grid>

                        {/*----- Horizontal Border -----*/}
                        <Grid item xs={12} sm={12} md={12} className="horizontal-border"></Grid>

                        {/*----- Base Salary -----*/}
                        <Grid item xs={12} sm={12} md={12} className="list-container">
                            <Grid>
                                <Typography className="list-left-title">{"Base Salary"}</Typography>
                            </Grid>
                            <Grid>
                                <Grid className="right-list-container">
                                    <Grid>
                                        <Typography className="list-right-title">{"Current"}</Typography>
                                        <Typography className="list-right-value">{"5,00,000"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Recommended Inc %"}</Typography>
                                        <Typography className="list-right-value">{"5%"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Manager Inc %"}</Typography>
                                        <Typography className="list-right-value">{"7"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Incremented Salary"}</Typography>
                                        <Typography className="list-right-value">{"35,000"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"New Base Salary"}</Typography>
                                        <Typography className="list-right-value">{"5,35,000"}</Typography>
                                    </Grid>
                                </Grid>

                                {/*----- Horizontal Border -----*/}
                                <Grid item xs={12} sm={12} md={12} className="horizontal-border"></Grid>
                            </Grid>
                        </Grid>

                        {/*----- Stocks -----*/}
                        <Grid item xs={12} sm={12} md={12} className="list-container">
                            <Grid>
                                <Typography className="list-left-title">{"Stocks"}</Typography>
                            </Grid>
                            <Grid>
                                <Grid className="right-list-container">
                                    <Grid>
                                        <Typography className="list-right-title">{"Current"}</Typography>
                                        <Typography className="list-right-value">{"10,000"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Recommended stocks"}</Typography>
                                        <Typography className="list-right-value">{"5000"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Manager's proposal"}</Typography>
                                        <Typography className="list-right-value">{"7000"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Total"}</Typography>
                                        <Typography className="list-right-value">{"7,000"}</Typography>
                                    </Grid>
                                    <Grid></Grid>
                                </Grid>

                                {/*----- Horizontal Border -----*/}
                                <Grid item xs={12} sm={12} md={12} className="horizontal-border"></Grid>
                            </Grid>
                        </Grid>

                        {/*----- Variable -----*/}
                        <Grid item xs={12} sm={12} md={12} className="list-container">
                            <Grid>
                                <Typography className="list-left-title">{"Variable"}</Typography>
                            </Grid>
                            <Grid>
                                <Grid className="right-list-container">
                                    <Grid>
                                        <Typography className="list-right-title">{"Current"}</Typography>
                                        <Typography className="list-right-value">{"15,000"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Recommended"}</Typography>
                                        <Typography className="list-right-value">{"12,000"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Manager's"}</Typography>
                                        <Typography className="list-right-value">{"18,000"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Total"}</Typography>
                                        <Typography className="list-right-value">{"18,000"}</Typography>
                                    </Grid>
                                    <Grid></Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/*----- Total Compensation -----*/}
                        <Grid item xs={12} sm={12} md={12} className="list-container mt-40">
                            <Grid>
                                <Typography className="list-left-title">{"Total Compensation"}</Typography>
                            </Grid>
                            <Grid>
                                <Grid className="right-list-container">
                                    <Grid>
                                        <Typography className="list-right-title">{"Current"}</Typography>
                                        <Typography className="list-right-value">{"5,20,000"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Changed"}</Typography>
                                        <Typography className="list-right-value">{"52,000"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"New"}</Typography>
                                        <Typography className="list-right-value">{"5,72,000"}</Typography>
                                    </Grid>
                                    <Grid></Grid>
                                    <Grid></Grid>
                                </Grid>

                                {/*----- Horizontal Border -----*/}
                                <Grid item xs={12} sm={12} md={12} className="horizontal-border"></Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} className="list-container">
                            <Grid></Grid>
                            <Grid container>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Typography className="list-left-title">{"Base Compa Ratio Placement"}</Typography>
                                    <img src={ListBottomImg} alt="reload" className="list-bottom-img" />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Typography className="list-left-title">{"TC Compa ratio Analytics"}</Typography>
                                    <img src={ListBottomImg} alt="reload" className="list-bottom-img" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/*----- Historical Section -----*/}
            <Typography className="historical-section-title">{"Historical Section"}</Typography>
            <Grid container className="historical-section-card">
                <Grid item xs={12} sm={12} md={12}>
                    <Grid className="historical-section-list">
                        <Grid><Typography className="historical-section-head">{"Year"}</Typography></Grid>
                        <Grid><Typography className="historical-section-head">{"Performance Rating"}</Typography></Grid>
                        <Grid><Typography className="historical-section-head">{"Base Salary"}</Typography></Grid>
                        <Grid><Typography className="historical-section-head">{"Variable Pay"}</Typography></Grid>
                        <Grid><Typography className="historical-section-head">{"Equity"}</Typography></Grid>
                        <Grid><Typography className="historical-section-head">{"Total Compensation"}</Typography></Grid>
                        <Grid><Typography className="historical-section-head">{"TC-% Increase"}</Typography></Grid>
                    </Grid>

                    {/*----- Horizontal Border -----*/}
                    <Grid item xs={12} sm={12} md={12} className="historical-border"></Grid>
                </Grid>

                {historicalList.map((item, index) => (
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid className="historical-section-list">
                            <Grid><Typography className="historical-section-value">{item.year}</Typography></Grid>
                            <Grid><Typography className="historical-section-value">{item.performanceRating}</Typography></Grid>
                            <Grid><Typography className="historical-section-value">{item.baseSalary}</Typography></Grid>
                            <Grid><Typography className="historical-section-value">{item.variablePay}</Typography></Grid>
                            <Grid><Typography className="historical-section-value">{item.equity}</Typography></Grid>
                            <Grid><Typography className="historical-section-value">{item.totalCompensation}</Typography></Grid>
                            <Grid><Typography className="historical-section-value">{item.tcIncrease}</Typography></Grid>
                        </Grid>

                        {/*----- Horizontal Border -----*/}
                        <Grid item xs={12} sm={12} md={12} className="historical-border"></Grid>
                    </Grid>
                ))}
            </Grid>

            {/*----- Future Compensation projection -----*/}
            <Grid container className="future-compensation-card">
                <Typography className="future-compensation-title">{"Future Compensation projection"}</Typography>
                <Grid item xs={12} sm={12} md={12} className="ta-center">
                    <img src={ChartImg} alt="reload" />
                </Grid>

                <Grid item xs={12} sm={12} md={12} className="chart-bottom-container">
                    <Grid></Grid>
                    <Grid className="chart-btm-grid">
                        <Grid className="chart-txt-box light-pink-bg"></Grid>
                        <Typography className="chart-txt">{"Base Pay"}</Typography>
                    </Grid>
                    <Grid className="chart-btm-grid">
                        <Grid className="chart-txt-box blue-bg"></Grid>
                        <Typography className="chart-txt">{"Stock value"}</Typography>
                    </Grid>
                    <Grid className="chart-btm-grid">
                        <Grid className="chart-txt-box green-bg"></Grid>
                        <Typography className="chart-txt">{"Total compensation(TC)"}</Typography>
                    </Grid>
                    <Grid className="chart-btm-grid">
                        <Grid className="chart-txt-box dark-red-bg"></Grid>
                        <Typography className="chart-txt">{"Total compensation target(TCT) "}</Typography>
                    </Grid>
                    <Grid></Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} className="chart-bottom-container">
                    <Grid></Grid>
                    <Grid className="chart-btm-grid">
                        <Grid className="chart-txt-box light-pink-bg"></Grid>
                        <Typography className="chart-txt">{"Future Base pay"}</Typography>
                    </Grid>
                    <Grid className="chart-btm-grid">
                        <Grid className="chart-txt-box blue-bg"></Grid>
                        <Typography className="chart-txt">{"Future Stock value"}</Typography>
                    </Grid>
                    <Grid></Grid>
                    <Grid></Grid>
                    <Grid></Grid>
                </Grid>

            </Grid>
        </Grid>
    );
};

export default React.memo(ViewCandidate);
