import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      fill="none"
      viewBox="0 0 27 27"
    >
      <path
        fill="#F9F9F9"
        d="M21.848 7.73l-4.458-.648-1.995-4.04c-.77-1.561-2.997-1.56-3.767 0l-1.994 4.04-4.459.648c-1.722.25-2.41 2.368-1.164 3.583l3.227 3.145-.762 4.44c-.294 1.716 1.508 3.024 3.048 2.215l3.988-2.097 3.988 2.097c1.538.808 3.342-.5 3.048-2.215l-.762-4.44 3.227-3.145c1.246-1.215.557-3.333-1.165-3.583zm-.092 2.293L18.19 13.5a.9.9 0 00-.259.796l.842 4.908a.3.3 0 01-.435.316l-4.407-2.317a.9.9 0 00-.838 0L8.686 19.52a.3.3 0 01-.436-.316l.842-4.907a.9.9 0 00-.259-.797l-3.565-3.476a.3.3 0 01.166-.512l4.927-.716a.9.9 0 00.678-.492l2.204-4.465a.3.3 0 01.538 0l2.203 4.465a.9.9 0 00.678.492l4.927.716a.3.3 0 01.167.512zM7.104 2.324L6.027.842A.9.9 0 004.57 1.9l1.077 1.482a.9.9 0 101.457-1.058zM3.534 15.605a.9.9 0 00-1.134-.578l-1.778.577a.9.9 0 00.557 1.713l1.777-.578a.9.9 0 00.578-1.134zM22.233.643a.9.9 0 00-1.258.199L19.9 2.324a.9.9 0 101.456 1.058L22.432 1.9a.9.9 0 00-.2-1.257zM26.378 15.604l-1.777-.577a.9.9 0 10-.557 1.712l1.778.578a.9.9 0 00.556-1.713zM13.51 22.582a.9.9 0 00-.9.9v1.827a.9.9 0 001.8 0v-1.827a.9.9 0 00-.9-.9z"
      ></path>
    </svg>
  );
}

export default Icon;