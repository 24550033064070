import { useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import MinusIcon from "../../../../../../assets/images/successionPlan/minusIcon.svg";
import PlusIcon from "../../../../../../assets/images/successionPlan/plusIcon.svg";

const Filled = ({ }) => {

    return (
        <Grid>
            {/*----- User 1 -----*/}
            <Grid item xs={12} sm={12} md={12} className="is-expanded-container">
                <Grid>
                    <img
                        src={MinusIcon}
                        alt={"MinusIcon"}
                        height={20}
                        width={20}
                        className="plus-minus-icon"
                    />
                </Grid>
                <Grid className="user-expanded-card">
                    {/*----- User 1 -----*/}
                    <Grid className="d-flex">
                        <Box className="user-box">{"RS"}</Box>
                        <Typography className="user-name">{"Ramesh Sahu"}<span className="support-promotion">{"Support Promotion"}</span></Typography>
                    </Grid>
                    <Grid className="description-grid">
                        <Typography className="description-title">{"Reason to promote"}</Typography>
                        <Typography className="description">{"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available."}</Typography>

                        <Typography className="description-title">{"Risks for promotion"}</Typography>
                        <Typography className="description">{"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available."}</Typography>

                        <Grid className="border-horizontal" />
                    </Grid>
                </Grid>
            </Grid>

            {/*----- User 2 -----*/}
            <Grid item xs={12} sm={12} md={12} className="is-expanded-container">
                <Grid>
                    <img
                        src={MinusIcon}
                        alt={"MinusIcon"}
                        height={20}
                        width={20}
                        className="plus-minus-icon"
                    />
                </Grid>
                <Grid className="user-expanded-card">
                    <Grid className="d-flex">
                        <Box className="user-box">{"GJ"}</Box>
                        <Typography className="user-name">{"Girish J"}<span className="do-not-support-promotion">{"Do not support promotion"}</span></Typography>
                    </Grid>
                    <Grid className="description-grid">
                        <Typography className="description-title">{"Reason not to promote"}</Typography>
                        <Typography className="description">{"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available."}</Typography>

                        <Grid className="border-horizontal" />
                    </Grid>
                </Grid>
            </Grid>

            {/*----- User 3 -----*/}
            <Grid item xs={12} sm={12} md={12} className="is-expanded-container">
                <Grid>
                    <img
                        src={PlusIcon}
                        alt={"MinusIcon"}
                        height={20}
                        width={20}
                        className="plus-minus-icon"
                    />
                </Grid>
                <Grid className="user-expanded-card">
                    <Grid className="d-flex">
                        <Box className="user-box">{"VD"}</Box>
                        <Typography className="user-name">{"Vinit Dhamani"}<span className="support-promotion">{"Support Promotion"}</span></Typography>
                    </Grid>
                    <Grid className="description-grid">
                        {/* <Typography className="description-title">{"Reason not to promote"}</Typography>
                        <Typography className="description">{"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available."}</Typography> */}
                        
                        <Grid className="border-horizontal" />
                    </Grid>
                </Grid>
            </Grid>


            {/*----- User 4 -----*/}
            <Grid item xs={12} sm={12} md={12} className="is-expanded-container">
                <Grid>
                    <img
                        src={PlusIcon}
                        alt={"MinusIcon"}
                        height={20}
                        width={20}
                        className="plus-minus-icon"
                    />
                </Grid>
                <Grid className="user-expanded-card">
                    <Grid className="d-flex">
                        <Box className="user-box">{"KS"}</Box>
                        <Typography className="user-name">{"Koyal Sen"}<span className="support-promotion">{"Support Promotion"}</span></Typography>
                    </Grid>
                    <Grid className="description-grid">
                        {/* <Typography className="description-title">{"Reason not to promote"}</Typography>
                        <Typography className="description">{"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available."}</Typography> */}

                        <Grid className="border-horizontal" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Filled;