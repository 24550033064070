import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { allRoutes } from "./routes";
import Avatar from "react-avatar";
import { actions as logInActions } from "../../../redux/login/actions";
import { IoIosArrowBack } from "react-icons/io";
import Logout from "../../../assets/images/sidebar/logout";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import APIList from "../../../api";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import PropTypes from 'prop-types';
import { isEmptyNullUndefined } from "../../utils/utils";
import { BASE_URL } from "../../../api/apiServices";
import { IoReorderThree } from "react-icons/io5";
import { MdArrowBackIos } from "react-icons/md";


const DynamicSidebar = ({ children }) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const employeeDetails = useSelector((state) => state?.empData?.empData);

  const userToken = useSelector(
    (state) => state?.user?.userToken
  );

  const [routes, setRoutes] = useState(null);
  const [sidebarCollide, setSidebarCollide] = useState(false);
  const [aciveTab, setAciveTab] = useState("");
  const [activeHead, setActiveHead] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);
  console.log("aciveTab", aciveTab);
  console.log("activeHead", activeHead);

  // for deep links navigation redirect (start)
  // if(!window.location.pathname.split("/").includes("login") && isEmptyNullUndefined(userToken?.id_token)){
  //   console.log("window.location.pathname", window.location.pathname);
  //   localStorage.setItem("gotToPath", window.location.pathname);
  // }
  // for deep links navigation redirect (end)

  let currentPath = window.location.pathname;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (aciveTab !== currentPath) {
      setAciveTab(currentPath);
    }
  }, [currentPath])


  useEffect(() => {
    setRoutes(allRoutes);
  }, [employeeDetails]);

  const handleSidebarCollide = () => {
    if (windowWidth < 900) {
      setShowSidebar(false);
      return;
    }
    setSidebarCollide(!sidebarCollide);
    // console.log('in')
    // console.log(sidebarCollide)
  }

  const handleSidebarCollideTypeClassName = (element) => {
    if (isLoginRelatedPage()) {
      return getLoginPageClassName(element);
    } else {
      return getRegularPageClassName(element);
    }
  };

  const isLoginRelatedPage = () => {
    const loginPaths = ['/login', '/forgot-password', '/account/reset/finish'];
    return loginPaths.includes(location.pathname);
  };

  const getLoginPageClassName = (element) => {
    const elementClassMap = {
      'sidebar': 'sidebar-login-page',
      'main': 'main-login-page'
    };
    return elementClassMap[element] || '';
  };

  const getRegularPageClassName = (element) => {
    const elementClassMap = {
      'sidebar': sidebarCollide ? 'sidebar-Collide' : 'sidebar',
      'main': sidebarCollide ? 'main-Collide' : 'main'
    };
    return elementClassMap[element] || '';
  };

  const handleNavigation = (route, isSubcat) => {
    if (route?.name === "Admin") {
      window.open(
        `${BASE_URL}/admin/login/${userToken?.id_token}`,
      );
      return;
    }
    console.log("route", route);
    setAciveTab(route?.path);

    if (!route?.subcategory) {

      history.push(route?.path);

      if (!isSubcat) {
        setActiveHead(null);
      }

    } else {
      setSidebarCollide(false);
      if (activeHead === route?.path) {
        !sidebarCollide && setActiveHead(null);
      } else {
        setActiveHead(route?.path);
      }
    }

  }

  const handleLogOut = () => {

    APIList.logout({ token: userToken?.id_token })
      .then((res) => {
      })
      .catch((err) => {
        toast.error(
          <div style={{ display: "flex", flexDirection: "row" }}>
            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        );
      })

    dispatch(logInActions.clearLogInToken());
    localStorage.setItem("loggedOutFromFooter", true);
    setTimeout(() => {
      history.push('/login');
    }, 1000);

  }


  return (
    <div className="main-container ">
      {
        windowWidth < 900 && (
          <div className="sidebar-collapse-icon">
            <IoReorderThree onClick={() => setShowSidebar(true)} />
          </div>
        )
      }
      <div className={handleSidebarCollideTypeClassName('sidebar')} style={{ display: windowWidth < 900 && !showSidebar ? "none" : "" }}>



        <div className="sidebar-main-outer">
          <div className="sidebarMain">
            <div className="headMiddle">
              <div className="header">

                <div className="user-container">
                  <div className="user-container-margin">
                    <div className="icon-container">
                      <div className="icon-size">
                        {employeeDetails?.profilePhoto ?
                          <img src={employeeDetails?.profilePhoto?.path} className="user" alt="User" />
                          :
                          <Avatar
                            name={employeeDetails.name}
                            size="45"
                            className="user"
                            color={Avatar.getRandomColor('sitebase', ['orange', 'violet', 'maroon', 'teal', 'brown'])}
                          />
                        }
                      </div>
                    </div>
                    <div className="title-container">
                      <div className="align-title">
                        <div className="upper-title">{employeeDetails?.name?.split(' ').slice(0, 2).join(' ')}</div>
                        <div className="lower-title">{employeeDetails?.employeeDesignation ? employeeDetails?.employeeDesignation : "Employee"}</div>
                      </div>
                    </div>
                  </div>
                </div>

                {
                    showSidebar && windowWidth < 900 &&
                    <div className="back-arrow">
                      <MdArrowBackIos onClick={() => setShowSidebar(false)} />
                    </div>
                  }

              </div>
              <div className="middle">
                <section className="routes">
                  {
                    routes?.map((route, index) => {
                      if (route?.permission && employeeDetails?.permissions?.includes(route?.permission)) {
                        return (

                          <div className="route-item">
                            <div className={`category ${(aciveTab === route?.path || activeHead === route?.path) ? "active" : ""}`} onClick={() => handleNavigation(route, false)}>
                              <span> {route?.icon} </span>
                              <span>
                                {route?.name}
                                {
                                  route?.subcategory ? activeHead === route?.path ? <BsChevronUp /> : <BsChevronDown /> : ""
                                }
                              </span>
                            </div>
                            {
                              route?.subcategory &&
                              activeHead === route?.path &&
                              !sidebarCollide &&
                              route?.subcategory?.map((sub) => {
                                if (sub?.permission && employeeDetails?.permissions?.includes(sub?.permission)) {
                                  return (
                                    <div className={`sub-cat ${(aciveTab === sub?.path || window.location.pathname === sub?.path) ? "active" : ""}`} onClick={() => handleNavigation(sub, true)}>
                                      {sub?.name}
                                      {/* {sub?.name === "Give Feedback" ? `${sub?.name} (${allRequestCounts?.pendingRequestCount})` : sub?.name} */}
                                    </div>
                                  )
                                }

                              })
                            }
                          </div>


                        )
                      }

                    })
                  }
                </section>
              </div>
            </div>
            <div className="footer">
              {
                // employeeDetails?.company?.showAppraisal && 
                <div className="logout-size">
                  <div className="title">
                    Logout
                  </div>
                  <button
                    onClick={() => handleLogOut()} className="logout-icon"
                  >
                    <Logout />
                  </button>
                </div>
              }

            </div>

          </div>
          <button className="for-collapse" onClick={() => handleSidebarCollide()}>
            <div className="collapsible-nav-bar">
              <div className="collapsible-nav-size">
                <IoIosArrowBack style={{ transform: sidebarCollide ? "rotate(-180deg)" : "rotate(0deg)" }} />
              </div>
            </div>
          </button>
        </div>
      </div>
      <main className={handleSidebarCollideTypeClassName('main')}>
        <div style={{
          height: '3.6rem'
        }} />
        {children}
      </main>
    </div>
  );
}

DynamicSidebar.propTypes = {
  children: PropTypes.node,
};

export default DynamicSidebar;