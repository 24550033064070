import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import MyAccomplish1 from "../../../../assets/images/MyCommunity/myAccomplish1.svg";
import MyAccomplish2 from "../../../../assets/images/MyCommunity/myAccomplish2.svg";
import MyAccomplish3 from "../../../../assets/images/MyCommunity/myAccomplish3.svg";
import SliderIcon from "../../../../assets/images/MyCommunity/sliderIcon.png";


const MyAccomplishments = ({ setOpenCertificateModal }) => {
    const dispatch = useDispatch();

    const myAccomplishList = [
        {
            id: 1,
            icon: MyAccomplish1,
            title: 'Achieved Milestone Q1 2023',
        },
        {
            id: 2,
            icon: MyAccomplish2,
            title: 'Outstanding Effort 2023-24',
        },
        {
            id: 3,
            icon: MyAccomplish3,
            title: 'Employee of the month Aug 2023',
        },
    ];

    const handleCertificatePopup = (title) => {
        if (title === "Outstanding Effort 2023-24") {
            setOpenCertificateModal(true);
        }
    }

    return (
        <Grid container className="my-accomplishment-card">
            <Grid item xs={12} sm={12} md={12}>
                <Typography className="my-accomplishments">{"My Accomplishments"}<span className="awards">{"10 Awards"}</span></Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} className="my-accom-container">
                <Grid container direction="row" wrap="nowrap" className="my-accom-scroll">
                    {myAccomplishList.map((item, index) => (
                        <Grid key={index} item className="my-accom-item" onClick={() => handleCertificatePopup(item?.title)}>
                            <img src={item.icon} alt="Slider" className="my-accom-icon" />
                            <div className="my-accom-border"/>
                            <Typography className="my-accom-title">{item.title}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
                <img src={SliderIcon} alt="Slider" className="my-accom-slider-icon" />
            </Grid>
        </Grid>  
    );  
};

export default React.memo(MyAccomplishments);
