import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="28"
      fill="none"
      viewBox="0 0 27 28"
    >
      <path
        fill="#fff"
        d="M23.046 4.294A13.412 13.412 0 0013.5.34a13.412 13.412 0 00-9.546 3.954A13.412 13.412 0 000 13.84c0 3.606 1.404 6.996 3.954 9.546A13.412 13.412 0 0013.5 27.34c3.606 0 6.996-1.404 9.546-3.954A13.412 13.412 0 0027 13.84c0-3.606-1.404-6.996-3.954-9.546zM5.859 22.978c.446-3.835 3.744-6.796 7.641-6.796 2.055 0 3.987.8 5.44 2.253a7.687 7.687 0 012.201 4.543 11.864 11.864 0 01-7.641 2.78c-2.905 0-5.57-1.046-7.64-2.78zm7.641-8.426c-2.26 0-4.1-1.84-4.1-4.1 0-2.261 1.84-4.1 4.1-4.1s4.1 1.839 4.1 4.1c0 2.26-1.84 4.1-4.1 4.1zm9.003 7.089a9.275 9.275 0 00-2.445-4.324 9.246 9.246 0 00-3.377-2.16 5.681 5.681 0 002.501-4.705A5.689 5.689 0 0013.5 4.77a5.689 5.689 0 00-5.682 5.682 5.68 5.68 0 002.503 4.707 9.253 9.253 0 00-3.141 1.927 9.246 9.246 0 00-2.684 4.554 11.867 11.867 0 01-2.914-7.8c0-6.572 5.346-11.918 11.918-11.918 6.572 0 11.918 5.346 11.918 11.918 0 2.98-1.1 5.71-2.915 7.801z"
      ></path>
    </svg>
  );
}

export default Icon;