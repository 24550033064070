import { useState } from "react";
import { Grid, Typography, Box, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import MinusIcon from "../../../../../assets/images/successionPlan/minusIcon.svg";
import hmLogo from "../../../../../assets/images/successionPlan/hmLogo.svg";
import PlusIcon from "../../../../../assets/images/successionPlan/plusIcon.svg";

const AllFeedback = ({ }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isReplying, setIsReplying] = useState(false);

    // Toggle function for expanding/collapsing
    const handleToggleExpand = () => {
        setIsExpanded((prev) => !prev);
    };

    // Function to handle reply button click
    const handleReplyClick = () => {
        setIsReplying(true);
    };

    // Function to handle cancel button click
    const handleCancelClick = () => {
        setIsReplying(false);
    };

    return (
        <>
            {/*----- Card 1 -----*/}
            <Grid item xs={12} sm={12} md={12} className="is-expanded-container">
                <Grid>
                    <img
                        // src={isExpanded ? MinusIcon : PlusIcon}
                        src={MinusIcon}
                        alt={isExpanded ? "MinusIcon" : "PlusIcon"}
                        height={20}
                        width={20}
                        className="plus-minus-icon"
                    // onClick={handleToggleExpand}
                    />
                </Grid>
                <Grid className="user-expanded-card">
                    {/*----- User 1 -----*/}
                    <Grid className="d-flex-jc-sb">
                        <Grid className="d-flex">
                            <Box className="user-box">{"RS"}</Box>
                            <Typography className="user-name">{"Ramesh Sahu"}<span className="user-accepted">{"Accepted"}</span><spna className="feedback-request">{"feedback request"}</spna></Typography>
                        </Grid>
                        <Typography className="user-date">{"15 Oct 2024"}</Typography>
                    </Grid>
                    {/* {isExpanded && ( */}
                    <Grid className="description-grid">
                        <Typography className="description">{"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available."}</Typography>
                        <Grid className="border-horizontal" />
                    </Grid>
                    {/* )} */}

                    {/*----- User 2 -----*/}
                    <Grid className="d-flex-jc-sb user-mt-15">
                        <Grid className="d-flex">
                            <Box className="user-box">
                            <img src={hmLogo}
                        className="plus-minus-icon1"
                    />
                            </Box>
                            <Typography className="user-name">{"Harry Mustain (You)"}<span className="user-accepted">{""}</span><spna className="feedback-request">{""}</spna></Typography>
                        </Grid>
                        <Typography className="user-date">{"16 Oct 2024"}</Typography>
                    </Grid>
                    {/* {isExpanded && ( */}
                    <Grid className="description-grid">
                        <Typography className="description">{"Thank you for the Feedback, can you please send feedback by this EOM"}</Typography>
                        <Grid className="border-horizontal" />
                    </Grid>
                    {/* )} */}

                    {/*----- User 3 -----*/}
                    <Grid className="d-flex-jc-sb user-mt-15">
                        <Grid className="d-flex">
                            <Box className="user-box">{"RS"}</Box>
                            <Typography className="user-name">{"Ramesh Sahu"}<span className="user-accepted">{""}</span><spna className="feedback-request">{""}</spna></Typography>
                        </Grid>
                        <Typography className="user-date">{"17 Oct 2024"}</Typography>
                    </Grid>

                    {/* {isExpanded && ( */}
                    <Grid className="description-grid">
                        <Typography className="description">{"No, I can not. Next Month I can."}</Typography>
                        {/* <Grid className="border-horizontal" /> */}

                        {/*----- Reply Button -----*/}
                        {!isReplying && (
                            <button className="reply-btn" onClick={handleReplyClick}>
                                {"Reply"}
                            </button>
                        )}

                        {/*----- Reply Field -----*/}
                        {isReplying && (
                            <Grid className="user-mt-15">
                                <Typography className="description">{"Reply"}</Typography>
                                <TextField
                                    id="outlined-basic"
                                    name="reply"
                                    value="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying."
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                    sx={{
                                        width: "100%",
                                        my: 0.5,
                                        color: "rgba(126, 126, 126, 1)", // Not necessary here, just for clarity
                                        "& .MuiInputBase-input": {
                                        color: "rgba(126, 126, 126, 1)", // Value's text color
                                        },
                                        "& fieldset": {
                                        border: "1px solid #dedede",
                                        },
                                        "& .css-k4qjio-MuiFormHelperText-root": {
                                        backgroundColor: "#ffffff",
                                        margin: "0px",
                                        paddingLeft: "0.5rem",
                                        },
                                    }}
                                    />

                                <Grid className="cancel-send-btn-grid">
                                    <button className="cancel-btn" onClick={handleCancelClick}>
                                        {"Cancel"}
                                    </button>

                                    <button className="send-reply-btn">
                                        {"Send Reply"}
                                    </button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    {/* )} */}
                </Grid>
            </Grid>

            {/*----- Card 2 -----*/}
            <Grid item xs={12} sm={12} md={12} className="is-expanded-container user-mt-15">
                <Grid>
                    <img
                        // src={isExpanded ? MinusIcon : PlusIcon}
                        // alt={isExpanded ? "MinusIcon" : "PlusIcon"}
                        src={PlusIcon}
                        alt={"PlusIcon"}
                        height={20}
                        width={20}
                        className="plus-minus-icon"
                    // onClick={handleToggleExpand}
                    />
                </Grid>
                <Grid className="user-expanded-card">
                    <Grid className="d-flex-jc-sb">
                        <Grid className="d-flex">
                            <Box className="user-box">{"GJ"}</Box>
                            <Typography className="user-name">{"Girish J"}<span className="user-denied">{"Denied"}</span><spna className="feedback-request">{"feedback request"}</spna></Typography>
                        </Grid>
                        {/* <Typography className="user-date">{"15 Oct 2024"}</Typography> */}
                    </Grid>
                    {/* {isExpanded && (
                        <Grid className="description-grid">
                            <Typography className="description">{"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available."}</Typography>
                            <Grid className="border-horizontal" />
                        </Grid>
                    )} */}
                </Grid>
            </Grid>


            {/*----- Card 3 -----*/}
            <Grid item xs={12} sm={12} md={12} className="is-expanded-container user-mt-15">
                <Grid>
                    <img
                        // src={isExpanded ? MinusIcon : PlusIcon}
                        // alt={isExpanded ? "MinusIcon" : "PlusIcon"}
                        src={PlusIcon}
                        alt={"PlusIcon"}
                        height={20}
                        width={20}
                        className="plus-minus-icon"
                    // onClick={handleToggleExpand}
                    />
                </Grid>
                <Grid className="user-expanded-card">
                    <Grid className="d-flex-jc-sb">
                        <Grid className="d-flex">
                            <Box className="user-box">{"GJ"}</Box>
                            <Typography className="user-name">{"Girish J"}<span className="user-accepted">{"Accepted"}</span><spna className="feedback-request">{"feedback request"}</spna></Typography>
                        </Grid>
                        {/* <Typography className="user-date">{"15 Oct 2024"}</Typography> */}
                    </Grid>
                    {/* {isExpanded && (
                        <Grid className="description-grid">
                            <Typography className="description">{"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available."}</Typography>
                            <Grid className="border-horizontal" />
                        </Grid>
                    )} */}
                </Grid>
            </Grid>

            {/*----- Card 4 -----*/}
            <Grid item xs={12} sm={12} md={12} className="is-expanded-container user-mt-15">
                <Grid>
                    <img
                        // src={isExpanded ? MinusIcon : PlusIcon}
                        // alt={isExpanded ? "MinusIcon" : "PlusIcon"}
                        src={PlusIcon}
                        alt={"PlusIcon"}
                        height={20}
                        width={20}
                        className="plus-minus-icon"
                    // onClick={handleToggleExpand}
                    />
                </Grid>
                <Grid className="user-expanded-card">
                    <Grid className="d-flex-jc-sb">
                        <Grid className="d-flex">
                            <Box className="user-box">{"GJ"}</Box>
                            <Typography className="user-name">{"Girish J"}<span className="user-accepted">{"Accepted"}</span><spna className="feedback-request">{"feedback request"}</spna></Typography>
                        </Grid>
                        {/* <Typography className="user-date">{"15 Oct 2024"}</Typography> */}
                    </Grid>
                    {/* {isExpanded && (
                        <Grid className="description-grid">
                            <Typography className="description">{"In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available."}</Typography>
                            <Grid className="border-horizontal" />
                        </Grid>
                    )} */}
                </Grid>
            </Grid>
        </>
    )
}

export default AllFeedback;