import { useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Profile = ({ }) => {

    return (
        <Grid>
        <Grid className="grayCard">
            {/*----- User 1 -----*/}
            <Grid item xs={12} sm={12} md={12}>
            <Typography className="personalDeatilTitle">{"Personal Details"}</Typography>
            </Grid> 
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={6} sm={6} md={6}>
                    <Typography className="personalDeatilleftTitle">{"Employee Name"}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'right' }}>
                    <Typography className="personalDeatilRightTitle">{"Demonstrated"}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <Typography className="personalDeatilleftTitle">{"Employee Id"}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'right' }}>
                    <Typography className="personalDeatilRightTitle">{"Demonstrated"}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <Typography className="personalDeatilleftTitle">{"Email Id"}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'right' }}>
                    <Typography className="personalDeatilRightTitle">{"Demonstrated"}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <Typography className="personalDeatilleftTitle">{"Employee type"}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'right' }}>
                    <Typography className="personalDeatilRightTitle">{"Demonstrated"}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <Typography className="personalDeatilleftTitle">{"Date of joining"}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'right' }}>
                    <Typography className="personalDeatilRightTitle">{"Demonstrated"}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <Typography className="personalDeatilleftTitle">{"Level"}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'right' }}>
                    <Typography className="personalDeatilRightTitle">{"Demonstrated"}</Typography>
                </Grid>
            </Grid>
                      
        </Grid>
        <Grid className="grayCard">
        {/*----- User 1 -----*/}
        <Grid item xs={12} sm={12} md={12}>
        <Typography className="personalDeatilTitle">{"Personal Details"}</Typography>
        </Grid> 
        <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={6} sm={6} md={6}>
                <Typography className="personalDeatilleftTitle">{"Manager Name"}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'right' }}>
                <Typography className="personalDeatilRightTitle">{"Demonstrated"}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
                <Typography className="personalDeatilleftTitle">{"Manager Id"}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'right' }}>
                <Typography className="personalDeatilRightTitle">{"Demonstrated"}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
                <Typography className="personalDeatilleftTitle">{"Email Id"}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'right' }}>
                <Typography className="personalDeatilRightTitle">{"Demonstrated"}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
                <Typography className="personalDeatilleftTitle">{"Function"}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'right' }}>
                <Typography className="personalDeatilRightTitle">{"Demonstrated"}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
                <Typography className="personalDeatilleftTitle">{"Sub function"}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'right' }}>
                <Typography className="personalDeatilRightTitle">{"Demonstrated"}</Typography>
            </Grid>
            
        </Grid>
                  
    </Grid>
    </Grid>
    )
}

export default Profile;