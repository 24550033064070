
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import APIList from "../../../api";
import React from "react";
import BarGraph from "./BarGraph";
import { Button,Accordion, AccordionDetails, AccordionSummary, Stack, CircularProgress, Skeleton } from "@mui/material";
import { BsChevronDown } from "react-icons/bs";
import FilterSelectForMyTeam from "../../common/filterSelectForMyTeam";
import { accordianSVGstyle,isEmptyNullUndefined } from "../../utils/utils";
import BarGraph1 from "./newbar";
import BarChart from "./newbar";
import CompensationChart from "../compensationChart";

const ProfileMenuItem = [
    'Personal Information',
    'Rating History'
]

const CompensationAnalytics = () => {

    const employeeDetails = useSelector((state) => state?.empData?.empData);

    const [expanded, setExpanded] = useState(null);
    const [selectedMenu, setSelectedMenu] = React.useState(null);
    const [dropdownData, setDropdownData] = useState([]);
    const [dropdownLoader, setDropdownLoader] = useState(false);
    const [searchLoader, setSearchLoader] = useState(false);
    const [filterPayload, setFilterPayload] = useState({});
    const [activeTab, setActiveTab] = useState("Increment Rating");

    const [graphData, setGraphData] = useState(null);

    React.useEffect(() => {
        if(!isEmptyNullUndefined(employeeDetails.id)) {
            setSelectedMenu(ProfileMenuItem[0])
            getDropdownData()

            getDashboard(filterPayload);

        }
    }, [employeeDetails])

    const getDropdownData = () => {
        // console.log("getPageData");
        setDropdownLoader(true);
        APIList.getDropdownDataForCompAnalytics({companyId: employeeDetails?.company?.id})
        .then((res) => {
            setDropdownData(res.data);
            setDropdownLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDropdownLoader(false);
        })
    }

    const getDashboard = (fp) => {
        if(!employeeDetails?.permissions?.includes("for-demo-purpose")){
            setSearchLoader(true);
            APIList.get_dashboard_graphs({
            payload : {
                employeeEligibilityDTO : fp,
                filterData: Object.keys(filterPayload).length === 0 ? false : true , // Default is false. Set to true if filtering is required.
                companyId : employeeDetails?.company?.id,
                // companyId: "ca4aed7d-6289-4840-a9b5-fef5c6626ec0",
            },
        })   
        .then((res) => {
            setSearchLoader(false);
            setExpanded(null)

            setGraphData(() => res?.data)
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setSearchLoader(false);
        })
    }
    }

    const handleChange = (index) => {
        if(expanded === index) {
            setExpanded(null);
        } else {
            setExpanded(index)
        }       
    };

    const handleResetFilter = () => {
        setFilterPayload({}); 
        getDashboard({});     
    }

    const handleSubmitFilter = () => {
        getDashboard(filterPayload);       
    }

    const filterDom = () => {
        return (
            <>
                <div className="acc-wrapper">
                    {
                        dropdownLoader ? 
                        <Skeleton sx={{ fontSize: '2.5rem', width:"100%"  }} />
                        :
                        <Stack sx={{
                        margin:"1rem 0px",
                        "& .MuiAccordion-rounded":{
                            border:"1px solid #dad2d2",
                            borderRadius:"10px !important",
                            boxShadow:"none"
                        }                           
                    }}>
                        <Accordion
                            expanded={expanded === "filters"} 
                            onChange={() => handleChange("filters")} 
                            data-value={"filters"}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
                            sx={{
                                width:"100%",   
                                fontSize:"1.25rem",
                                fontWeight:"600",
                                "& .MuiAccordionSummary-content":{
                                    width:"100%", 
                                    display:"flex", 
                                    justifyContent:"space-between",
                                }}}
                        >
                            <p className="accordian-heading" >Filters</p>
                            <BsChevronDown
                                style={{...accordianSVGstyle ,transform: expanded !== "filters" ? "rotate(0deg)" : "rotate(180deg)"}}
                        />          
                        </AccordionSummary>
                        <AccordionDetails
                        sx={{
                            fontSize:"1rem"
                        }}
                        >
                            <Stack sx={{display:"flex", flexDirection:"row" , flexWrap:"wrap"}}>
                                {
                                !isEmptyNullUndefined(dropdownData) 
                                && Object.keys(dropdownData).map((key) => {
                                    // if(key === "employeeLevel"){
                                    //     return null;
                                    // }
                                        return(
                                            <FilterSelectForMyTeam title={key} filterPayload={filterPayload} setFilterPayload={setFilterPayload} dropdownData={dropdownData} />
                                        )
                                    })
                                }
                            </Stack>
                            <Stack sx={{
                                display:"flex",
                                flexDirection:"row",
                                justifyContent:"flex-end",
                                marginTop:"1rem"
                                }}>

                                <Button
                                variant="outlined"
                                sx={{
                                    color:"var(--primary)",
                                    borderColor:"var(--primary)",
                                    marginRight:"1rem",
                                    width:"7rem"
                                }}
                                disabled={Object.keys(filterPayload)?.length === 0}
                                onClick={() =>  handleResetFilter()}
                                >
                                    Reset
                                </Button>
                                <Button 
                                variant="contained"
                                sx={{
                                    color:"var(--secondary)",
                                    backgroundColor:"var(--primary)",
                                    borderColor:"var(--primary)",
                                    "&:hover":{
                                        backgroundColor:"#035c7c",
                                        borderColor:"#035c7c",
                                    },
                                    width:"7rem"
                                }}
                                disabled={searchLoader}
                                onClick={() => handleSubmitFilter({})}
                                >
                                    Submit                                     
                                </Button>
                                
                            </Stack>
                        </AccordionDetails>
                        </Accordion>
                    </Stack>
                    }
                    
                </div>
            
            </>
        )
    }


    return (
        employeeDetails?.permissions?.includes("for-demo-purpose") ?
        // true ?
        <CompensationChart />
        :
            <div className="compensation-analytics-user-profile-page px-10 container">

                <div className="tabs">

                    <div className={`tab ${activeTab === "Increment Rating" ? "active" : ""}`} onClick={() => setActiveTab("Increment Rating")}>Increment Rating</div>
                    <div className={`tab ${activeTab === "Cost Summary" ? "active" : ""}`} onClick={() => setActiveTab("Cost Summary")}>Cost Summary</div>
                </div>

                <div className="col-12" style={{marginLeft: '1rem', width: 'calc(100% - 1rem)'}}>
                    {filterDom()}
                </div>
                
                 {
                    (activeTab == 'Increment Rating') &&
                    <div class="d-flex">

                    <div className="col-6"> 

                        {
                            searchLoader ? 
                            <CircularProgress />
                            :
                            (selectedMenu == 'Personal Information') && (
                                <>
                                    <div className="accorDetails personalInfo">
                                        <p className="accordian-title">Average Increment Rating Graph Of Employee</p>
                                        <div className="accordian-data">
                                          <BarGraph data={graphData} bar={graphData?.avgIncrementRatingGraphAvgOfEmployee?.ratingCombos} average={graphData?.avgIncrementRatingGraphAvgOfEmployee?.average} count={graphData?.avgIncrementRatingGraphAvgOfEmployee?.avgCount} yAxisTitle="Average Count" />                                       
                                        </div>
                                    </div>
                                </>
                            )
                        }

                    


                    </div>

                    <div className="col-6"> 

                        {
                            searchLoader ? 
                            <CircularProgress />
                            :
                            (selectedMenu == 'Personal Information') && (
                                <>
                                    <div className="accorDetails personalInfo">
                                        <p className="accordian-title">Average Increment Rating Graph</p>
                                        <div className="accordian-data">
                                            <BarGraph data={graphData} bar={graphData?.avgIncrementRatingGraph.ratingCombos} average={graphData?.avgIncrementRatingGraph.average} count={graphData?.avgIncrementRatingGraph.count} yAxisTitle="Count" />
                                        </div>
                                    </div>
                                </>
                            )
                        }

                    


                    </div>

                   

                </div>
                 }
                




                <div className="col-6"> 


{
    (activeTab == 'Cost Summary') &&
    <>
    {
 searchLoader ? 
 <CircularProgress />
 :
  (
     <>
         <div className="accorDetails personalInfo">
             <p className="accordian-title">Compensation cost summary</p>
             <div className="accordian-data">
                 {/* <BarGraph1 
                 data={graphData} 
                 bar={["Current Fixed Salary", "New Fixed Salary", "Current Total Comp", "New Total Comp"]} 
                 average={[graphData?.compensationCostSummary.currentFixedSalary, graphData?.compensationCostSummary.newFixedSalary, graphData?.compensationCostSummary.currentTotalComp, graphData?.compensationCostSummary.newTotalComp]} 
                 count={[graphData?.compensationCostSummary.currentFixedSalary, graphData?.compensationCostSummary.newFixedSalary, graphData?.compensationCostSummary.currentTotalComp, graphData?.compensationCostSummary.newTotalComp]} 
                 yAxisTitle="Count" 
                 /> */}
                 <BarChart
                 bar={["Current Fixed Salary", "New Fixed Salary", "Current Total Comp", "New Total Comp"]} 
                 average={[graphData?.compensationCostSummary.currentFixedSalary, graphData?.compensationCostSummary.newFixedSalary, graphData?.compensationCostSummary.currentTotalComp, graphData?.compensationCostSummary.newTotalComp]} 
                 drawDottedLines={true} chartId="12345"
                 />
             </div>
         </div>
     </>
 )
    }
    </>
   
}




</div>
                    
            </div>
    )
};

export default CompensationAnalytics; 
