import React, { useState } from 'react';
import { Grid, Typography, Checkbox } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';


const MyOrg = ({ }) => {
    const dispatch = useDispatch();

    return (
        <Grid item xs={12} sm={12} md={12}>
            <Typography>{"My Org"}</Typography>
        </Grid>
    );
};

export default React.memo(MyOrg);
