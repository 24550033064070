import React, { useState } from 'react';
import { Grid, Typography, Button, Popover, List, ListItem, ListItemText } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { FaSort } from "react-icons/fa6";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { AiFillEye } from "react-icons/ai";
import ValidationIcon from "../../../../assets/images/HRPromotions/validationIcon.svg";
import MenuIcon from "../../../../assets/images/HRPromotions/menuIcon.svg";



const ViewSharedDocuments = ({ }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const hrPromotionsList = [
        {
            id: 1,
            empCode: 10614,
            empName: 'Ashish Reji',
            validIcon: '',
            currentDesignation: 'Assistant Manager',
            proposedDesignation: 'Assistant Manager',
            perfRating2024: 'A',
            perfRating2023: 'A',
            editViewButton: 'View Promotion Doc',
        },
        {
            id: 2,
            empCode: 10680,
            empName: 'Akshay C ',
            validIcon: '',
            currentDesignation: 'Senior Associate',
            proposedDesignation: 'Senior Associate',
            perfRating2024: 'A',
            perfRating2023: 'A+',
            editViewButton: 'View Promotion Doc',
        },
        {
            id: 3,
            empCode: 10681,
            empName: 'Govind H',
            validIcon: ValidationIcon,
            currentDesignation: 'Assistant Manager',
            proposedDesignation: '-',
            perfRating2024: 'C',
            perfRating2023: 'C',
            editViewButton: 'View Promotion Doc',
        },
        {
            id: 4,
            empCode: 10692,
            empName: 'Umashankar Aravallil',
            validIcon: '',
            currentDesignation: 'Senior Associate',
            proposedDesignation: 'Senior Associate',
            perfRating2024: 'A',
            perfRating2023: 'A+',
            editViewButton: 'View Promotion Doc',
        },
        {
            id: 5,
            empCode: 10691,
            empName: 'Akshay C',
            validIcon: '',
            currentDesignation: 'Assistant Manager',
            proposedDesignation: 'Assistant Manager',
            perfRating2024: 'A+',
            perfRating2023: 'B',
            editViewButton: 'View Promotion Doc',
        },
        {
            id: 6,
            empCode: 10614,
            empName: 'Ashish Reji',
            validIcon: ValidationIcon,
            currentDesignation: 'Assistant Manager',
            proposedDesignation: 'Assistant Manager',
            perfRating2024: 'A',
            perfRating2023: 'A',
            editViewButton: 'View Promotion Doc',
        },
        {
            id: 7,
            empCode: 10680,
            empName: 'Akshay C ',
            validIcon: '',
            currentDesignation: 'Senior Associate',
            proposedDesignation: 'Senior Associate',
            perfRating2024: 'A',
            perfRating2023: 'A+',
            editViewButton: 'View',
            editViewButton: 'View Promotion Doc',
        },
        {
            id: 8,
            empCode: 10681,
            empName: 'Govind H',
            validIcon: ValidationIcon,
            currentDesignation: 'Assistant Manager',
            proposedDesignation: '-',
            perfRating2024: 'C',
            perfRating2023: 'C',
            editViewButton: 'View',
            editViewButton: 'View Promotion Doc',
        },
        {
            id: 9,
            empCode: 10692,
            empName: 'Umashankar Aravallil',
            validIcon: '',
            currentDesignation: 'Senior Associate',
            proposedDesignation: 'Senior Associate',
            perfRating2024: 'A',
            perfRating2023: 'A+',
            editViewButton: 'View',
            editViewButton: 'View Promotion Doc',
        },
        {
            id: 10,
            empCode: 10691,
            empName: 'Akshay C',
            validIcon: '',
            currentDesignation: 'Assistant Manager',
            proposedDesignation: 'Assistant Manager',
            perfRating2024: 'A+',
            perfRating2023: 'B',
            editViewButton: 'View',
            editViewButton: 'View Promotion Doc',
        },
    ];

    return (
        <div>
        
        <Grid container className="table-card">
          
            <Grid item xs={12} sm={12} md={12}>
                <div className="nominate-promo-table">
                    <table className="emp-list-table">
                        <tr className="table-head-emp-list">
                            <th className="w-130 pl-20">Emp Code &nbsp;<FaSort className="c-pointer" /></th>
                            <th className="w-130">Emp Name &nbsp;<FaSort className="c-pointer" /></th>
                            <th className="w-130">Current Designation</th>
                            <th className="w-130">Proposed Designation</th>
                            <th>Perf. Rating 2024</th>
                            <th>Perf. Rating 2023</th>
                            <th className="ta-end pr-20">Action </th>
                        </tr>

                        {hrPromotionsList.map((item, index) => (
                            <tr>
                                <td className="pl-20">
                                    <div>
                                        <Typography className="emp-table-value">{item.empCode}</Typography>
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex gap-2'>
                                        <Typography className="emp-name-checkbox">{item.empName}</Typography>
                                        {item.validIcon === ValidationIcon && <img className="validation-icon" src={item.validIcon} alt="ValidationIcon" />}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <Typography className="emp-table-value">{item.currentDesignation}</Typography>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <Typography className="emp-table-value">{item.proposedDesignation}</Typography>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <Typography className="emp-table-value">{item.perfRating2023}</Typography>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <Typography className="emp-table-value">{item.perfRating2024}</Typography>
                                    </div>
                                </td>

                                <td className="">
                                    <div>
                                        
                                            <Button className="edit-small-btn" onClick={() => history.push("/manager-feedbacks-member")}>
                                                
                                                {item.editViewButton === "Edit" &&
                                                    <AiFillEye className="mr-5" />
                                                }
                                                {item.editViewButton}
                                            </Button> <div />
                                        
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
            </Grid>
        </Grid>
        </div>
    );
};

export default React.memo(ViewSharedDocuments);
