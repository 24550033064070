import React, { useState } from 'react';
import { Grid, Typography, TextField, InputAdornment, IconButton, useMediaQuery } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import User1Icon from "../../../../assets/images/EmployeeEngagement/user1.svg";
import User2Icon from "../../../../assets/images/EmployeeEngagement/user2.svg";
import BannerImg from "../../../../assets/images/EmployeeEngagement/bannerImg.png";
import BannerSecondImg from "../../../../assets/images/EmployeeEngagement/bannerSecondImg.png";
import LikeIcon from "../../../../assets/images/EmployeeEngagement/like.svg";
import CommentsIcon from "../../../../assets/images/EmployeeEngagement/comments.svg";
import ShareIcon from "../../../../assets/images/EmployeeEngagement/share.svg";
import SmileyIcon from "../../../../assets/images/MyCommunity/smiley.svg";
import StarIcon from "../../../../assets/images/MyCommunity/star.svg";
import CameraIcon from "../../../../assets/images/MyCommunity/camera.svg";
import SendIcon from "../../../../assets/images/MyCommunity/send.svg";
import AttachmentIcon from "../../../../assets/images/MyCommunity/attachment.svg";


const MyTeamEngagement = ({ }) => {
    const dispatch = useDispatch();

    const isMobile = useMediaQuery("(max-width: 480px)");

    return (
        <>
            {/*----- Frist Card -----*/}
            <Grid container className="my-engagement-card">
                {/*----- User Name Top Section -----*/}
                <Grid container>
                    <Grid item xs={2} sm={1} md={1}>
                        <img src={User1Icon} alt="MediBuddy" className='user-profile' />
                    </Grid>
                    <Grid item xs={7} sm={8} md={8}>
                        <Typography className="user-name">{"Nicholas Fernandis"}</Typography>
                        <Typography className="user-post">{"Senior Business Analyst"}</Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                        <Typography className="user-date">{"22 July, 10:48AM"}</Typography>
                    </Grid>
                </Grid>

                {/*----- Horizontal Border -----*/}
                <Grid item xs={12} sm={12} md={12} className="horizontal-border"></Grid>

                {/*----- Banner Title Like Count -----*/}
                <Grid item xs={12} sm={12} md={12}>
                    <Typography className="banner-title">{"Received 1 Year Anniversary Kudos from John Doe"}</Typography>
                    <img src={BannerImg} alt="MediBuddy" className="banner-img" />
                    <Typography className="link-count">{"You and 127 other liked it. "}</Typography>
                </Grid>

                {/*----- Horizontal Border -----*/}
                <Grid item xs={12} sm={12} md={12} className="horizontal-border"></Grid>

                {/*----- Liked & Comments & Share -----*/}
                <Grid container>
                    <Grid container item xs={3} sm={2} md={2} className="c-pointer">
                        <img src={LikeIcon} alt="MediBuddy" className="like-share-icon" />
                        <Typography className="like-share-lbl blue-drak">{"Liked"}</Typography>
                    </Grid>
                    <Grid container item xs={6} sm={3.5} md={3.5} className="c-pointer">
                        <img src={CommentsIcon} alt="MediBuddy" className="like-share-icon" />
                        <Typography className="like-share-lbl">{"32 Comments"}</Typography>
                    </Grid>
                    <Grid container item xs={3} sm={2} md={2} className="c-pointer">
                        <img src={ShareIcon} alt="MediBuddy" className="like-share-icon" />
                        <Typography className="like-share-lbl">{"Share"}</Typography>
                    </Grid>
                    <Grid item xs={0} sm={4.5} md={4.5}></Grid>
                </Grid>

                {/*----- Write Your Comment -----*/}
                <Grid container className="comment-grid">
                    <Grid item xs={1.5} sm={1} md={1}>
                        <img src={User1Icon} alt="MediBuddy" className='user-profile' />
                    </Grid>
                    <Grid item xs={10.5} sm={11} md={11}>
                        <TextField
                            size="small"
                            name="writeYourComment"
                            placeholder="Write your comment"
                            variant="outlined"
                            fullWidth
                            sx={{
                                marginLeft: "10px",
                                width: "100%",
                                backgroundColor: "#F9F9F9",
                                borderRadius: "10px",
                                "& fieldset": {
                                    border: "1px solid #F9F9F9",
                                    borderRadius: "10px",
                                },
                                "& input::placeholder": {
                                    color: "#A9A9A9",
                                    opacity: 1,
                                },
                                "& .css-k4qjio-MuiFormHelperText-root": {
                                    margin: "0px",
                                    paddingLeft: "0.5rem",
                                }
                            }}

                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {!isMobile && (
                                            <IconButton edge="end" sx={{ marginRight: "2px" }}>
                                                <img src={CameraIcon} alt="Camera" className="like-share-icon" />
                                            </IconButton>
                                        )}

                                        {!isMobile && (
                                            <IconButton edge="end" sx={{ marginRight: "2px" }}>
                                                <img src={StarIcon} alt="Star" className="like-share-icon" />
                                            </IconButton>
                                        )}

                                        {isMobile && (
                                            <IconButton edge="end" sx={{ marginRight: "2px" }}>
                                                <img src={AttachmentIcon} alt="Star" className="like-share-icon" />
                                            </IconButton>
                                        )}

                                        <IconButton edge="end" sx={{ marginRight: "2px" }}>
                                            <img src={SmileyIcon} alt="Smiley" className="like-share-icon" />
                                        </IconButton>

                                        <IconButton edge="end">
                                            <img src={SendIcon} alt="Send" className="like-share-icon" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>

                    {/* <Grid sx={{ display: "flex", backgroundColor: "#FFFFFF", boxShadow: "6px 6px 10px 6px #0000000D", borderRadius: "10px", padding: "5px" }}>
                        <IconButton sx={{ marginRight: "2px" }}>
                            <img src={CameraIcon} alt="Camera" className="like-share-icon" />
                        </IconButton>

                        <IconButton sx={{ marginRight: "2px" }}>
                            <img src={StarIcon} alt="Star" className="like-share-icon" />
                        </IconButton>
                    </Grid> */}

                </Grid>

            </Grid>

            {/*----- Second Card -----*/}
            <Grid container className="my-engagement-card">
                {/*----- User Name Top Section -----*/}
                <Grid container>
                    <Grid item xs={2} sm={1} md={1}>
                        <img src={User2Icon} alt="MediBuddy" className='user-profile' />
                    </Grid>
                    <Grid item xs={7} sm={8} md={8}>
                        <Typography className="user-name">{"Leslie Alexander"}</Typography>
                        <Typography className="user-post">{"Senior Software Engineer"}</Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                        <Typography className="user-date">{"21 July, 02:00 PM"}</Typography>
                    </Grid>
                </Grid>

                {/*----- Horizontal Border -----*/}
                <Grid item xs={12} sm={12} md={12} className="horizontal-border"></Grid>

                {/*----- Banner Title Like Count -----*/}
                <Grid item xs={12} sm={12} md={12}>
                    <Typography className="banner-title">{"Achieved a milestone Kudos from Dave whatmore"}</Typography>
                    <img src={BannerSecondImg} alt="MediBuddy" className="banner-img" />
                    <Typography className="link-count">{"You and 127 other liked it."}</Typography>
                </Grid>

                {/*----- Horizontal Border -----*/}
                <Grid item xs={12} sm={12} md={12} className="horizontal-border"></Grid>

                {/*----- Liked & Comments & Share -----*/}
                <Grid container>
                    <Grid container item xs={3} sm={2} md={2} className="c-pointer">
                        <img src={LikeIcon} alt="MediBuddy" className="like-share-icon" />
                        <Typography className="like-share-lbl blue-drak">{"Liked"}</Typography>
                    </Grid>
                    <Grid container item xs={6} sm={3.5} md={3.5} className="c-pointer">
                        <img src={CommentsIcon} alt="MediBuddy" className="like-share-icon" />
                        <Typography className="like-share-lbl">{"32 Comments"}</Typography>
                    </Grid>
                    <Grid container item xs={3} sm={2} md={2} className="c-pointer">
                        <img src={ShareIcon} alt="MediBuddy" className="like-share-icon" />
                        <Typography className="like-share-lbl">{"Share"}</Typography>
                    </Grid>
                    <Grid item xs={0} sm={4.5} md={4.5}></Grid>
                </Grid>

                {/*----- Write Your Comment -----*/}
                <Grid container className="comment-grid">
                    <Grid item xs={1.5} sm={1} md={1}>
                        <img src={User1Icon} alt="MediBuddy" className='user-profile' />
                    </Grid>
                    <Grid item xs={10.5} sm={11} md={11}>
                        <TextField
                            size="small"
                            name="writeYourComment"
                            placeholder="Write your comment"
                            variant="outlined"
                            fullWidth
                            sx={{
                                marginLeft: "10px",
                                width: "100%",
                                backgroundColor: "#F9F9F9",
                                borderRadius: "10px",
                                "& fieldset": {
                                    border: "1px solid #F9F9F9",
                                    borderRadius: "10px",
                                },
                                "& input::placeholder": {
                                    color: "#A9A9A9",
                                    opacity: 1,
                                },
                                "& .css-k4qjio-MuiFormHelperText-root": {
                                    margin: "0px",
                                    paddingLeft: "0.5rem",
                                }
                            }}

                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {!isMobile && (
                                            <IconButton edge="end" sx={{ marginRight: "2px" }}>
                                                <img src={CameraIcon} alt="Camera" className="like-share-icon" />
                                            </IconButton>
                                        )}

                                        {!isMobile && (
                                            <IconButton edge="end" sx={{ marginRight: "2px" }}>
                                                <img src={StarIcon} alt="Star" className="like-share-icon" />
                                            </IconButton>
                                        )}

                                        {isMobile && (
                                            <IconButton edge="end" sx={{ marginRight: "2px" }}>
                                                <img src={AttachmentIcon} alt="Star" className="like-share-icon" />
                                            </IconButton>
                                        )}

                                        <IconButton edge="end" sx={{ marginRight: "2px" }}>
                                            <img src={SmileyIcon} alt="Smiley" className="like-share-icon" />
                                        </IconButton>

                                        <IconButton edge="end">
                                            <img src={SendIcon} alt="Send" className="like-share-icon" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>

                    {/* <Grid sx={{ display: "flex", backgroundColor: "#FFFFFF", boxShadow: "6px 6px 10px 6px #0000000D", borderRadius: "10px", padding: "5px" }}>
                        <IconButton sx={{ marginRight: "2px" }}>
                            <img src={CameraIcon} alt="Camera" className="like-share-icon" />
                        </IconButton>

                        <IconButton sx={{ marginRight: "2px" }}>
                            <img src={StarIcon} alt="Star" className="like-share-icon" />
                        </IconButton>
                    </Grid> */}

                </Grid>

            </Grid>
        </>
    );
};

export default React.memo(MyTeamEngagement);
