import React, { useState } from 'react';
import { Button, Grid, TextField, Typography, Drawer, InputAdornment } from '@mui/material';
import { IoIosSearch } from "react-icons/io";
import RoleCloseIcon from "../../../../../assets/images/successionPlan/roleClose.svg";


const RightShareDrawer = ({ anchorRightShareDrawer, openShareRight, onCloseRightShareDrawer }) => {
    const [search, setSearch] = useState("");

    const searchUserList = ['Isha', 'Jagbir Singh', 'Angie Mary J.', 'Gaurav Malviya', 'Devesh Sharma', 'Monika Jain'];
    return (
        <Drawer
            anchor={anchorRightShareDrawer}
            open={openShareRight}
            PaperProps={{
                sx: {
                    height: "auto",
                    borderRadius: "10px",
                    top: "125px",
                    right: "280px",
                    padding: "20px",
                },
            }}
        >
            <Grid container spacing={2} className="share-drawer">
                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        id="input-with-icon-textfield"
                        placeholder="Search..."
                        fullWidth
                        className="search-bar"
                        onChange={(e) => setSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IoIosSearch />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            '& .MuiInputBase-input': {
                                padding: '10px',
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: 'none',
                                },
                            },
                        }}
                    />

                    <Grid className='role-border-div'>
                        <Grid className='d-flex ai-center'>
                            <Typography className='role-type'>{"Manisha Goal"}</Typography>
                            <img
                                src={RoleCloseIcon}
                                alt="RoleCloseIcon"
                                height={12}
                                width={12}
                                className='role-close-Icon'
                            />
                        </Grid>
                    </Grid>

                    {/*----- Search List -----*/}
                    <Grid>
                        {searchUserList.map(user => (
                            <Typography className="user-name" key={user}>{user}</Typography>
                        ))}
                    </Grid>

                    <Grid className="border-horizontal" />

                    <Grid className="send-btn-grid">
                        <button className="send-btn">
                            {"Send"}
                        </button>
                    </Grid>
                </Grid>
            </Grid>
        </Drawer >
    );
};

export default RightShareDrawer;