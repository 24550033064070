import React from "react";

function InductionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27" 
      height="28" 
      fill="none"
      viewBox="0 0 16 17"
    >
      <g fill="#fff" clipPath="url(#clip0_4873_773)">
        <path d="M8 1c-2.197 0-4.19.946-5.637 2.475-.006.006-.014.008-.02.014s-.008.015-.013.021C.89 5.047 0 7.163 0 9.497c0 2.051.698 4.033 1.966 5.58a.412.412 0 00.6.048l1.672-1.504a.453.453 0 00.15-.314.481.481 0 00-.105-.335 5.445 5.445 0 01-1.236-3.475c0-2.9 2.222-5.26 4.953-5.26a4.79 4.79 0 013.127 1.18c.438.38.981-.325.541-.705a5.633 5.633 0 00-3.24-1.366V1.933c3.74.238 6.715 3.534 6.715 7.564a7.892 7.892 0 01-1.484 4.63l-1.02-.914a6.418 6.418 0 001.17-3.716c0-.599-.08-1.191-.24-1.762-.163-.581-.981-.312-.821.26.136.487.205.993.205 1.502 0 1.28-.439 2.514-1.234 3.474a.462.462 0 00-.106.334.475.475 0 00.15.315l1.675 1.5a.412.412 0 00.6-.05C15.303 13.526 16 11.547 16 9.498 16 4.812 12.411 1 8 1zM4.211 4.829l-.942-1C4.43 2.733 5.925 2.037 7.572 1.932V3.35a5.608 5.608 0 00-3.36 1.48zm-.847 8.386l-1.02.917a7.867 7.867 0 01-1.47-4.18h1.334a6.395 6.395 0 001.156 3.263zM2.212 9.042H.879a7.794 7.794 0 011.784-4.57l.942 1a6.343 6.343 0 00-1.393 3.57z"></path>
        <path d="M8.97 11.814c.403-.429 3.741-5.078 4.12-5.607.294-.409-.182-.938-.578-.654-.86.609-5.175 3.669-5.58 4.098a1.6 1.6 0 000 2.163c.56.595 1.475.598 2.037 0zm-1.432-1.52c.188-.195 1.712-1.31 3.286-2.442-1.158 1.6-2.274 3.12-2.46 3.318-.546.58-1.371-.296-.826-.875zm-1.014 3.983h2.95c.567 0 .567-.91 0-.91h-2.95c-.567 0-.567.91 0 .91zm5.083.814H4.392c-.567 0-.567.91 0 .91h7.215c.567 0 .567-.91 0-.91z"></path>
      </g>
      <defs>
        <clipPath id="clip0_4873_773">
          <path
            fill="#fff"
            d="M0 0H16V16H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default InductionIcon;