import React, { useState } from 'react';
import { Grid, Typography, Button, Checkbox } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { FaSort } from "react-icons/fa6";
import Nominated1 from "../../../../../assets/images/MyCommunity/nominated1.svg";
import Nominated2 from "../../../../../assets/images/MyCommunity/nominated2.svg";
import LeftArrow from "../../../../../assets/images/leftArrow.png";
import RightArrow from "../../../../../assets/images/rightArrow.png";
import ViewFullNomination from '../../Modal/ViewFullNomination';
import ReasonForRejection from '../../Modal/ReasonForRejection';
import RejectTheNomination from '../../Modal/RejectTheNomination';


const AllView = ({ activeTab }) => {

    const dispatch = useDispatch();

    const [openViewFullNomination, setOpenViewFullNomination] = useState(false);
    const [openReasonForRejection, setOpenReasonForRejection] = useState(false);
    const [openRejectTheNomination, setOpenRejectTheNomination] = useState(false);

    const nominateEmps = [
        {
            id: 1,
            nominatedBy: 'Annette Black',
            nominee: 'Savannah Nguyen',
            icon: Nominated1,
            nominatedFor: 'Positive Pat-on-the back',
            reasonForNomination: 'I would like him to Thing Big showcase...',
            rejectBtn: 'Reject',
            acceptBtn: 'Accept',
            seeReasonBtn: '',
            rejectedBtn: '',
            acceptedBtn: '',
        },
        {
            id: 2,
            nominatedBy: 'Jenny Wilson',
            nominee: 'Wade Warren',
            icon: Nominated2,
            nominatedFor: 'Rockstar of the month',
            reasonForNomination: 'I would like him to Thing Big showcase...',
            rejectBtn: 'Reject',
            acceptBtn: 'Accept',
            seeReasonBtn: '',
            rejectedBtn: '',
            acceptedBtn: '',
        },
        {
            id: 3,
            nominatedBy: 'Jenny Wilson',
            nominee: 'Wade Warren',
            icon: Nominated2,
            nominatedFor: 'Rockstar of the month',
            reasonForNomination: 'I would like him to Thing Big showcase...',
            rejectBtn: '',
            acceptBtn: '',
            seeReasonBtn: 'See Reason',
            rejectedBtn: 'Rejected',
            acceptedBtn: '',
        },
        {
            id: 4,
            nominatedBy: 'Jenny Wilson',
            nominee: 'Wade Warren',
            icon: Nominated2,
            nominatedFor: 'Rockstar of the month',
            reasonForNomination: 'I would like him to Thing Big showcase...',
            rejectBtn: '',
            acceptBtn: '',
            seeReasonBtn: '',
            rejectedBtn: '',
            acceptedBtn: 'Accepted',
        },
        {
            id: 5,
            nominatedBy: 'Annette Black',
            nominee: 'Savannah Nguyen',
            icon: Nominated1,
            nominatedFor: 'Positive Pat-on-the back',
            reasonForNomination: 'I would like him to Thing Big showcase...',
            rejectBtn: 'Reject',
            acceptBtn: 'Accept',
            seeReasonBtn: '',
            rejectedBtn: '',
            acceptedBtn: '',
        },
        {
            id: 6,
            nominatedBy: 'Jenny Wilson',
            nominee: 'Wade Warren',
            icon: Nominated2,
            nominatedFor: 'Rockstar of the month',
            reasonForNomination: 'I would like him to Thing Big showcase...',
            rejectBtn: 'Reject',
            acceptBtn: 'Accept',
            seeReasonBtn: '',
            rejectedBtn: '',
            acceptedBtn: '',
        },
        {
            id: 7,
            nominatedBy: 'Jenny Wilson',
            nominee: 'Wade Warren',
            icon: Nominated2,
            nominatedFor: 'Rockstar of the month',
            reasonForNomination: 'I would like him to Thing Big showcase...',
            rejectBtn: '',
            acceptBtn: '',
            seeReasonBtn: 'See Reason',
            rejectedBtn: 'Rejected',
            acceptedBtn: '',
        },
        {
            id: 8,
            nominatedBy: 'Jenny Wilson',
            nominee: 'Wade Warren',
            icon: Nominated2,
            nominatedFor: 'Rockstar of the month',
            reasonForNomination: 'I would like him to Thing Big showcase...',
            rejectBtn: '',
            acceptBtn: '',
            seeReasonBtn: '',
            rejectedBtn: '',
            acceptedBtn: 'Accepted',
        },
        {
            id: 9,
            nominatedBy: 'Jenny Wilson',
            nominee: 'Wade Warren',
            icon: Nominated2,
            nominatedFor: 'Rockstar of the month',
            reasonForNomination: 'I would like him to Thing Big showcase...',
            rejectBtn: '',
            acceptBtn: '',
            seeReasonBtn: 'See Reason',
            rejectedBtn: 'Rejected',
            acceptedBtn: '',
        },
        {
            id: 10,
            nominatedBy: 'Jenny Wilson',
            nominee: 'Wade Warren',
            icon: Nominated2,
            nominatedFor: 'Rockstar of the month',
            reasonForNomination: 'I would like him to Thing Big showcase...',
            rejectBtn: '',
            acceptBtn: '',
            seeReasonBtn: '',
            rejectedBtn: '',
            acceptedBtn: 'Accepted',
        },
    ];

    const getData = () => {
        if (activeTab === "all") {
            return nominateEmps;
        }
        else if (activeTab === "pending") {
            return nominateEmps.filter(item => item.rejectBtn === "Reject" || item.acceptBtn === "Accept");
        }
        else if (activeTab === "accepted") {
            return nominateEmps.filter(item => item.acceptedBtn === "Accepted");
        }
        else if (activeTab === "rejected") {
            return nominateEmps.filter(item => item.rejectedBtn === "Rejected");
        }
    }

    return (
        <>
            <Grid container className="table-card">
                <Grid item xs={12} sm={12} md={12}>
                    <div className="nominate-promo-table">
                        <table className="emp-list-table">
                            <tr className="table-head-emp-list">
                                <th className="w-155 pl-20">Nominated By &nbsp;<FaSort className="c-pointer" /></th>
                                <th className="w-155">Nominee &nbsp;<FaSort className="c-pointer" /></th>
                                <th className="w-230">Nominated for &nbsp;<FaSort className="c-pointer" /></th>
                                <th>Reason for Nomination</th>
                                <th className="ta-end pr-20">Action</th>
                            </tr>

                            {getData().map((item, index) => (
                                <tr>
                                    <td className="pl-20">
                                        <div>
                                            <Typography className="emp-table-value">{item.nominatedBy}</Typography>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <Typography className="emp-table-value">{item.nominee}</Typography>
                                        </div>
                                    </td>
                                    <td>
                                        <Grid className="user-msg-grid">
                                            <img src={item.icon} alt="icon" className="user-msg-icon" />
                                            <Typography className="user-msg">{item.nominatedFor}</Typography>
                                        </Grid>
                                    </td>
                                    <td>
                                        <div>
                                            <Typography className="emp-table-value">
                                                {item.reasonForNomination}
                                                <span className="view-full" onClick={() => setOpenViewFullNomination(true)} style={{cursor: "pointer"}}>{"View Full"}</span>
                                            </Typography>
                                        </div>
                                    </td>
                                    <td className="ta-end pr-20">
                                        <div className='btn-div'>
                                            {item.rejectBtn === "Reject" &&
                                                <Button className="reject-btn" onClick={() => setOpenRejectTheNomination(true)}>
                                                    {"Reject"}
                                                </Button>
                                            }

                                            {item.acceptBtn === "Accept" &&
                                                <Button className="accept-btn">
                                                    {"Accept"}
                                                </Button>
                                            }

                                            {item.seeReasonBtn === "See Reason" &&
                                                <Button className="see-reason-btn" onClick={() => setOpenReasonForRejection(true)} >
                                                    {"See Reason"}
                                                </Button>
                                            }

                                            {item.rejectedBtn === "Rejected" &&
                                                <Button className="rejected-btn">
                                                    {"Rejected"}
                                                </Button>
                                            }

                                            {item.acceptedBtn === "Accepted" &&
                                                <Button className="accepted-btn">
                                                    {"Accepted"}
                                                </Button>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </Grid>
            </Grid>

            {/*----- Pagination -----*/}
            <div className="d-flex jc-end mt-20">
                <div className="d-flex">
                    <img src={LeftArrow} alt="LeftArrow" className="left-right-arrow" />
                    <div className="pagination-border"><Typography className="pagination-count">{"1"}</Typography></div>
                    <div className="pagination-border"><Typography className="pagination-count">{"2"}</Typography></div>
                    <div className="pagination-border"><Typography className="pagination-count">{"3"}</Typography></div>
                    <div className="pagination-border"><Typography className="pagination-count">{"4"}</Typography></div>
                    <div className="pagination-border"><Typography className="pagination-count">{"5"}</Typography></div>
                    <img src={RightArrow} alt="RightArrow" className="left-right-arrow ml-10" />
                </div>
            </div>

            {openViewFullNomination && (
                <ViewFullNomination openViewFullNomination={openViewFullNomination} setOpenViewFullNomination={setOpenViewFullNomination} />
            )}
            {
            openReasonForRejection && (
                <ReasonForRejection openReasonForRejection={openReasonForRejection} setOpenReasonForRejection={setOpenReasonForRejection} />
            )}
            {
                openRejectTheNomination && (
                    <RejectTheNomination openRejectTheNomination={openRejectTheNomination} setOpenRejectTheNomination={setOpenRejectTheNomination} />
                )
            }
        </>
    );
};

export default React.memo(AllView);
