import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import BudgetSummaryChart from './components/BudgetSummaryChart';
import TCIncreaseByLevelChart from './components/TCIncreaseByLevelChart';
import TCIncreaseByRatingChart from './components/TCIncreaseByRatingChart';
import TCIncreaseByFunctionChart from './components/TCIncreaseByFunctionChart';
import TCIncreaseByLevelAndGenderChart from './components/TCIncreaseByLevelAndGenderChart';



const CompensationChart = () => {
    const history = useHistory();

    return (
        <Grid container className="compensation-chart" spacing={2}>
            {/*----- Budget Summary -----*/}
            <Grid item xs={12} sm={12} md={12}>
                <Grid className="chart-card" style={{width:"60%", margin:"0 auto"}}>
                    <Typography className="chart-title">{"Budget Summary"}</Typography>
                    <BudgetSummaryChart />
                </Grid>
            </Grid>

            {/*----- TC Increase by Level -----*/}
            <Grid item xs={12} sm={6} md={6}>
                <Grid className="chart-card">
                    <Typography className="chart-title">{"TC Increase by Level"}</Typography>
                    <TCIncreaseByLevelChart />
                </Grid>
            </Grid>

            {/*----- TC Increase by Rating -----*/}
            <Grid item xs={12} sm={6} md={6}>
                <Grid className="chart-card">
                    <Typography className="chart-title">{"TC Increase by Rating"}</Typography>
                    <TCIncreaseByRatingChart />
                </Grid>
            </Grid>

            {/*----- TC Increase % by Function -----*/}
            <Grid item xs={12} sm={6} md={6}>
                <Grid className="chart-card">
                    <Typography className="chart-title">{"TC Increase % by Function"}</Typography>
                    <TCIncreaseByFunctionChart />
                </Grid>
            </Grid>

            {/*----- TC Increase % by Level and Gender -----*/}
            <Grid item xs={12} sm={6} md={6}>
                <Grid className="chart-card">
                    <Typography className="chart-title">{"TC Increase % by Level and Gender"}</Typography>
                    <TCIncreaseByLevelAndGenderChart />
                </Grid>
            </Grid>

        </Grid>
    )
};

export default CompensationChart; 
