import React, { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import AllView from './components/AllView';
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const MyTeamCommunityViewAll = () => {

  const history = useHistory();
  
  const [activeTab, setActiveTab] = useState("all");

  return (
    <div className="my-community-team">
      <Grid container spacing={2}>
        
        {/*----- Heading & Button -----*/}
        <Grid item xs={12} sm={12} md={12} className="heading-btn">
          <Typography className="manage-rewards">{"Manage Rewards"}</Typography>
          <Button className="back-btn" onClick={() => history.goBack()}>{"Back"}</Button>
        </Grid>


        {/*----- All & Pending & Accepted & Rejected -----*/}
        <Grid item xs={12} sm={12} md={12}>
          <Grid class="tab-btn-List">
            <a onClick={() => setActiveTab("all")} class={activeTab === "all" ? "active-btn" : "inActive-btn"}>{"All"}</a>
            <a onClick={() => setActiveTab("pending")} class={activeTab === "pending" ? "active-btn" : "inActive-btn"}>{"Pending"}</a>
            <a onClick={() => setActiveTab("accepted")} class={activeTab === "accepted" ? "active-btn" : "inActive-btn"}>{"Accepted"}</a>
            <a onClick={() => setActiveTab("rejected")} class={activeTab === "rejected" ? "active-btn" : "inActive-btn"}>{"Rejected"}</a>
          </Grid>
        </Grid>

        {/*----- MyEngagement & MyTeamEngagement & MyOrgEngagement Value Section -----*/}
        <Grid item xs={12} sm={12} md={12}>
          
            <AllView activeTab={activeTab} />
          
        </Grid>
      </Grid>
    </div>
  );
};

export default MyTeamCommunityViewAll;
