import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import SurveyIcon from "../../../../assets/images/MyCommunity/survey.svg";
import PuzzleIcon from "../../../../assets/images/MyCommunity/puzzle.svg";
import RightArrowIcon from "../../../../assets/images/MyCommunity/rightArrow.svg";


const SurveyPuzzle = ({ }) => {
    const dispatch = useDispatch();

    return (
        <Grid container className="survey-puzzle-container">
            <Grid className="survey-item">
                <img src={SurveyIcon} alt="Slider" className="survey-puzzle-icon" />
                <Grid>
                    <Typography className="title">{"Survey"}<span><img src={RightArrowIcon} alt="RightArrow" className="right-arrow-icon" /></span></Typography>
                    <Typography className="description">{"Participate in Internet Survey of..."}</Typography>
                </Grid>
            </Grid>
            <Grid className="puzzle-item">
                <img src={PuzzleIcon} alt="Slider" className="survey-puzzle-icon" />
                <Grid>
                    <Typography className="title">{"Puzzle"}<span><img src={RightArrowIcon} alt="RightArrow" className="right-arrow-icon" /></span></Typography>
                    <Typography className="description">{"Participate in puzzle competitio..."}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default React.memo(SurveyPuzzle);
