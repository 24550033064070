import { useEffect, useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EditUserProfile from "../../../assets/images/successionPlan/editUserProfile.png";
import UserRightArrow from "../../../assets/images/promotions/userRightArrow.svg";
import AllFeedback from './components/AllFeedback';
import AcceptedFeedback from './components/AcceptedFeedback';
import RejectedFeedback from './components/RejectedFeedback';
import NoActionTaken from './components/NoActionTaken';
import UserRightDrawer from "../Promotions/PromotionsForm/FormsStakeholdersDrawer/UserRightDrawer";

const ManagerFeedbacksMember = () => {
    const history = useHistory();

    const [allFeedbackView, setAllFeedbackView] = useState(true);
    const [acceptedFeedbackView, setAcceptedFeedbackView] = useState(false);
    const [rejectedFeedbackView, setRejectedFeedbackView] = useState(false);
    const [noActionTakenView, setNoActionTakenView] = useState(false);
    const [stateUser, setStateUser] = useState({right: false});
    const toggleUserDrawer = (open) => (event) => {
        setStateUser({ ...stateUser, right: open });
    };

    const handleAllFeedback = () => {
        setAllFeedbackView(true);
        setAcceptedFeedbackView(false);
        setRejectedFeedbackView(false);
        setNoActionTakenView(false);
    };

    const handleAcceptedFeedback = () => {
        setAllFeedbackView(false);
        setAcceptedFeedbackView(true);
        setRejectedFeedbackView(false);
        setNoActionTakenView(false);
    };

    const handleRejectedFeedback = () => {
        setAllFeedbackView(false);
        setAcceptedFeedbackView(false);
        setRejectedFeedbackView(true);
        setNoActionTakenView(false);
    };

    const handleNoActionTaken = () => {
        setAllFeedbackView(false);
        setAcceptedFeedbackView(false);
        setRejectedFeedbackView(false);
        setNoActionTakenView(true);
    };

    return (
        <div className="manager-feedbacks-member-form">
            <Grid container spacing={2}>
                {/*----- Title -----*/}
                <Grid item xs={12} sm={12} md={12} className="feedback-title-grid">
                    <Typography className="page-title">{"Feedbacks"}</Typography>
                </Grid>

                {/*----- Akshay C User Card -----*/}
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container className="user-form-card">
                        <Grid item xs={12} sm={12} md={12} className="user-card">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={2} sm={2} className="ta-center right-border pl-0">
                                    <img
                                        src={EditUserProfile}
                                        alt="EditUserProfile"
                                        height={77}
                                        width={77}
                                    />
                                    <Typography className="profile-name">{"Akshay C "}<span><img src={UserRightArrow} onClick={toggleUserDrawer(true)} alt="rightArrow" height={16} width={10} style={{cursor: "pointer"}} /></span></Typography>
                                    <Typography className="profile-field-label">{"Emp No. "}<span className="profile-emp-no">{"10614"}</span></Typography>
                                </Grid>
                                <Grid item xs={12} md={10} sm={10} className="pl-35">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={4} sm={4}>
                                            <Typography className="profile-field-label">{"Level"}</Typography>
                                            <Typography className="profile-field-value">{"L4"}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4} sm={4}>
                                            <Typography className="profile-field-label">{"Tenure"}</Typography>
                                            <Typography className="profile-field-value">{"2 Years"}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4} sm={4}>
                                            <Typography className="profile-field-label">{"Performance  Rating (2023)"}</Typography>
                                            <Typography className="profile-field-value">{"A"}</Typography>
                                        </Grid>


                                        <Grid item xs={12} md={4} sm={4}>
                                            <Typography className="profile-field-label">{"Job Family "}</Typography>
                                            <Typography className="profile-field-value">{"Assistant Manager"}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4} sm={4}>
                                            <Typography className="profile-field-label">{"Skill Assessments"}</Typography>
                                            <Typography className="profile-field-value">{"Proficient, Hard Working"}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4} sm={4}>
                                            <Typography className="profile-field-label">{"Performance  Rating (2024)"}</Typography>
                                            <Typography className="profile-field-value">{"A+"}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                
                <Grid item xs={12} sm={12} md={12}>
                    {/*----- Top Button Section -----*/}
                    <Grid class="top-btn-List">
                        <a onClick={handleAllFeedback} class={allFeedbackView === true ? "active-btn" : "inActive-btn"}>{"All"}<span class="top-btn-count">{"4"}</span></a>
                        <a onClick={handleAcceptedFeedback} class={acceptedFeedbackView === true ? "active-btn" : "inActive-btn"}>{"Accepted"}<span class="top-btn-count">{"3"}</span></a>
                        <a onClick={handleRejectedFeedback} class={rejectedFeedbackView === true ? "active-btn" : "inActive-btn"}>{"Rejected"}<span class="top-btn-count">{"1"}</span></a>
                        <a onClick={handleNoActionTaken} class={noActionTakenView === true ? "active-btn" : "inActive-btn"}>{"No Action Taken"}<span class="top-btn-count">{"0"}</span></a>
                    </Grid>

                    {allFeedbackView === true &&
                        <AllFeedback />
                    }

                    {acceptedFeedbackView === true &&
                        <AcceptedFeedback />
                    }

                    {rejectedFeedbackView === true &&
                        <RejectedFeedback />
                    }

                    {noActionTakenView === true &&
                        <NoActionTaken />
                    }
                </Grid>
            </Grid>

            <UserRightDrawer
                anchorUserRightDrawer="right"
                openUserStateRight={stateUser.right}
                onCloseUserRightDrawer={toggleUserDrawer(false)}
            >
            </UserRightDrawer>
        </div>
    )
};

export default ManagerFeedbacksMember;