import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="28"
      fill="none"
      viewBox="0 0 27 28"
    >
      <path
        fill="#fff"
        d="M19.99 15.427l-3.186 3.185L15.49 17.3a.79.79 0 10-1.119 1.12l1.873 1.871a.788.788 0 001.118 0l3.745-3.744a.79.79 0 10-1.119-1.12z"
      ></path>
      <path
        fill="#fff"
        d="M23.561 10.525h-5.034V4.278A3.443 3.443 0 0015.088.84H3.438A3.443 3.443 0 000 4.279v7.79a3.444 3.444 0 002.647 3.345v1.95a.791.791 0 001.35.559l2.417-2.416h2.059v6.246a3.442 3.442 0 003.438 3.439h8.675l2.416 2.416a.79.79 0 001.35-.56V25.1A3.445 3.445 0 0027 21.753v-7.79a3.443 3.443 0 00-3.439-3.438zm-17.475 3.4a.79.79 0 00-.559.231l-1.298 1.298v-.738a.79.79 0 00-.79-.791 1.859 1.859 0 01-1.857-1.857v-7.79c0-1.023.833-1.856 1.856-1.856h11.65c1.024 0 1.857.833 1.857 1.857v6.246h-5.034a3.442 3.442 0 00-3.437 3.4H6.086zm19.332 7.828a1.859 1.859 0 01-1.857 1.857.791.791 0 00-.79.791v.738l-1.298-1.297a.79.79 0 00-.56-.232h-9.002a1.859 1.859 0 01-1.856-1.857v-7.79c0-1.023.833-1.856 1.856-1.856h11.65c1.024 0 1.857.833 1.857 1.857v7.79z"
      ></path>
    </svg>
  );
}

export default Icon;