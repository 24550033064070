import React, { useState } from 'react';
import { Button, Grid, Typography, Drawer } from '@mui/material';
import EditUserProfile from "../../../../../assets/images/successionPlan/editUserProfile.png";
import DrawerCloseIcon from "../../../../../assets/images/successionPlan/drawerCloseIcon.svg";
import Filled from './components/Filled';
import UnFilled from './components/UnFilled';
import NoActionTaken from './components/NoActionTaken';


const RightDrawer = ({ anchorRightDrawer, openStateRight, onCloseRightDrawer }) => {

    const [filledView, setFilledView] = useState(true);
    const [unFilledView, setUnFilledView] = useState(false);
    const [noActionTakenView, setNoActionTakenView] = useState(false);

    const handleFilled = () => {
        setFilledView(true);
        setUnFilledView(false);
        setNoActionTakenView(false);
    };

    const handleUnFilled = () => {
        setFilledView(false);
        setUnFilledView(true);
        setNoActionTakenView(false);
    };

    const handleNoActionTaken = () => {
        setFilledView(false);
        setUnFilledView(false);
        setNoActionTakenView(true);
    };

    return (
        <Drawer
            anchor={anchorRightDrawer}
            open={openStateRight}
        // onClose={onCloseRightDrawer}
        >
            <Grid container spacing={2} className="from-stakeholders-drawer">
                <Grid container item xs={12} md={10} sm={10}>
                    <img
                        src={EditUserProfile}
                        alt="EditUserProfile"
                        height={50}
                        width={50}
                    />
                    <Grid className="user-grid">
                        <Typography className="right-user-name">{"Ashish Reji"}</Typography>
                        <Typography className="user-id">{"ID: 26k7"}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={2} sm={2} className="ta-right">
                    <img
                        src={DrawerCloseIcon}
                        alt="DrawerCloseIcon"
                        height={17}
                        width={17}
                        onClick={onCloseRightDrawer}
                    />
                </Grid>


                <Grid item xs={12} sm={12} md={12}>

                    <Typography className="title-stakeholders">{"Forms from Stakeholders"}</Typography>

                    {/*----- Top Button Section -----*/}
                    <Grid class="top-btn-List">
                        <a onClick={handleFilled} class={filledView === true ? "active-btn" : "inActive-btn"}>{"Filled"}</a>
                        <a onClick={handleUnFilled} class={unFilledView === true ? "active-btn" : "inActive-btn"}>{"Unfilled"}</a>
                        <a onClick={handleNoActionTaken} class={noActionTakenView === true ? "active-btn" : "inActive-btn"}>{"No Action Taken"}</a>
                    </Grid>

                    <Grid className="border-horizontal" />

                    {filledView === true &&
                        <Filled />
                    }

                    {unFilledView === true &&
                        <UnFilled />
                    }

                    {noActionTakenView === true &&
                        <NoActionTaken />
                    }
                </Grid>
            </Grid>
        </Drawer>
    );
};

export default RightDrawer;