import { useEffect, useState } from "react";
import { Grid, Typography, TextField, MenuItem } from "@mui/material";
import MyTeam from './components/MyTeam';
import MyOrg from './components/MyOrg';
import TopIcon from "../../../assets/images/MyTeamCompensation/topIcon.svg";

const MyTeamCompensationLetter = ({viewType}) => {
    const [myTeamView, setMyTeamView] = useState(true);
    const [myOrgView, setMyOrgView] = useState(false);

    const handleMyTeam = () => {
        setMyTeamView(true);
        setMyOrgView(false);
    };

    const handleMyOrg = () => {
        setMyTeamView(false);
        setMyOrgView(true);
    };

    return (
        <div className="my-team-compensation">
            {
                 viewType !== "letter" ? 
                 <Grid container spacing={2}>
                {/*----- Top Button Section -----*/}
                <Grid item xs={12} sm={12} md={12} className="top-grid">
                    <Grid class="top-btn-List">
                        <a onClick={handleMyTeam} class={myTeamView === true ? "active-btn" : "inActive-btn"}>{"My Team"}<span className="top-btn-count">4</span></a>
                        <a onClick={handleMyOrg} class={myOrgView === true ? "active-btn" : "inActive-btn"}>{"My Org"}</a>
                    </Grid>

                    <Grid class="top-btn-List">
                        <TextField
                            id="filled-basic"
                            size="small"
                            variant="outlined"
                            name="search"
                            placeholder="Search"
                            //value={"7"}
                            //onChange={handleChangeComp}
                            sx={{
                                width: "100%",
                                height: "fit-content",
                                backgroundColor: "#FFFFFF",
                                borderRadius: "10px",
                                "& fieldset": {
                                    border: "1px solid #E6E6E6",
                                    borderRadius: "10px",
                                },
                            }}
                        />
                        <button className="send-download-btn">{"Send"}</button>
                        <button className="send-download-btn">{"Download"}</button>
                        <img
                            src={TopIcon}
                            alt="Icon"
                            height={17}
                            width={17}
                        />
                    </Grid>
                </Grid>

                {myTeamView === true &&
                    <MyTeam />
                }

                {myOrgView === true &&
                    <MyOrg />
                }
            </Grid>
            :
            <Grid className="compensation-letter">
                <div className="tabs-outer">
                    <div className="tab">Compensation Profile</div>
                    <div className="tab active-tab">Compensation Letter <span>i</span></div>
                </div>

                <div className="year-dropdown">
                <TextField
                        select
                        // label="Select Year"
                        variant="outlined"
                        size="small"
                        value={2023-2024}
                        sx={{ width: "180px", padding: "1rem 1rem", borderRadius: "10px" }}
                    >
                        <MenuItem value={2023-2024}>2023-2024</MenuItem>
                    </TextField>
                </div>

                <Grid className="compensation-letter-card">
                    <Grid>
                        <Typography className="user-name">{"Dear Harry Mustain,"}</Typography>
                        <Typography className="letter-description">{"Thank you for your contributions to the continued success. We believe that your performance and success in your role finally leads to success of xxx. As part of the xxxxx Success Review (MBSR), for FY 2023-24, you are rated <<What Rating>> on OKRs is and  <<HOW Rating>> on Beliefs."}</Typography>
                        <Typography className="letter-description">{" Your annual fixed salary has been revised to INR ____________ resulting in a ___________% increase* on your fixed salary for FY’24. Your annual variable pay  for FY’24  is INR______________, which is dependent on company, functional and individual performance."}</Typography>
                    </Grid>

                    <Grid className="w-100">
                        {/*----- Header -----*/}
                        <Grid className="table-header">
                            <Grid>
                                <Typography className="header-txt">{"Component"}</Typography>
                            </Grid>
                            <Grid className="ta-center">
                                <Typography className="header-txt">{"Jul’23 - Jun’24"}</Typography>
                            </Grid>
                            <Grid className="ta-center">
                                <Typography className="header-txt">{"Jul’24 - Jun’25"}</Typography>
                            </Grid>
                            <Grid className="ta-center">
                                <Typography className="header-txt">{"Jul’25 - Jun’26"}</Typography>
                            </Grid>
                            <Grid className="ta-center">
                                <Typography className="header-txt">{"Jul' 26 - Jun' 27"}</Typography>
                            </Grid>
                        </Grid>

                        {/*----- Table List Item 1 -----*/}
                        <Grid className="w-100">
                            <Grid container className="table-item">
                                <Grid>
                                    <Typography className="table-list-value">{"Fixed Salary"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"5,00,000"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"5,50,000"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"-"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"-"}</Typography>
                                </Grid>
                            </Grid>
                            <Grid className="item-border"></Grid>
                        </Grid>

                        {/*----- Table List Item 2 -----*/}
                        <Grid className="w-100">
                            <Grid container className="table-item">
                                <Grid>
                                    <Typography className="table-list-value">{"Variable Pay"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"2,00,000"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"2,50,000"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"-"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"-"}</Typography>
                                </Grid>
                            </Grid>
                            <Grid className="item-border"></Grid>
                        </Grid>

                        {/*----- Table List Item 3 -----*/}
                        <Grid className="w-100">
                            <Grid container className="table-item">
                                <Grid>
                                    <Typography className="table-list-value">{"Joining Bonus"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"1,00,000"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"-"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"-"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"-"}</Typography>
                                </Grid>
                            </Grid>
                            <Grid className="item-border"></Grid>
                        </Grid>

                        {/*----- Table List Item 4 -----*/}
                        <Grid className="w-100">
                            <Grid container className="table-item">
                                <Grid>
                                    <Typography className="table-list-value">{"Retention Bonus"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"-"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"3,00,000"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"-"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"-"}</Typography>
                                </Grid>
                            </Grid>
                            <Grid className="item-border"></Grid>
                        </Grid>

                        {/*----- Table List Item 5 -----*/}
                        <Grid className="w-100">
                            <Grid container className="table-item">
                                <Grid>
                                    <Typography className="table-list-value">{"Incentives"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"50,000"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"75,000"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"-"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"-"}</Typography>
                                </Grid>
                            </Grid>
                            <Grid className="item-border"></Grid>
                        </Grid>

                        {/*----- Table List Item 6 -----*/}
                        <Grid className="w-100">
                            <Grid container className="table-item">
                                <Grid>
                                    <Typography className="table-list-value">{"Value of ESOPs Vested (4 yrs projection)"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"1,00,000"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"2,00,000"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"3,00,000"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"4,00,000"}</Typography>
                                </Grid>
                            </Grid>
                            <Grid className="item-border"></Grid>
                        </Grid>

                        {/*----- Table List Item 7 -----*/}
                        <Grid className="w-100">
                            <Grid container className="table-item">
                                <Grid>
                                    <Typography className="table-list-value">{"MB Wallet"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"12,000"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"12,000"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"12,000"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-value">{"12,000"}</Typography>
                                </Grid>
                            </Grid>
                            <Grid className="item-border item-total-border"></Grid>
                        </Grid>

                        {/*----- Table List Item Total -----*/}
                        <Grid className="w-100">
                            <Grid container className="table-item">
                                <Grid>
                                    <Typography className="table-list-total">{"Total Compensation Target (TCT)"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-total">{"9,62,000"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-total">{"13,87,000"}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-total">{""}</Typography>
                                </Grid>
                                <Grid className="ta-center">
                                    <Typography className="table-list-total">{""}</Typography>
                                </Grid>
                            </Grid>
                            <Grid className="item-border item-total-border"></Grid>
                        </Grid>

                        <Grid>
                            <Typography className="letter-description">{"The break of the fixed salary for FY 25, will be available in xxxx. Details of Insurances applicable to you (Group Medical Insurance Group Personal Accident Insurance) are uploaded in the xxxx. This is over and above the Personal Compensation Summary as shown above. All other terms and conditions of your employment remain unchanged. If you have any questions or require further clarification, please do not hesitate to reach out to your reporting manager or the People and Culture team."}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid className="download-letter-btn-grid">
                    <button className="download-letter-btn">{"Download Letter"}</button>
                </Grid>
            </Grid>
            }
            

            
        </div>
    )
};

export default MyTeamCompensationLetter;