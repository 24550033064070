import Avatar from "react-avatar";
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from "react-icons/ai"
import { isEmptyNullUndefined } from "../../../../utils/utils";
import { IoIosArrowForward } from "react-icons/io";
import { Backdrop, CircularProgress, IconButton } from "@mui/material";
import { RiErrorWarningFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import TableSkel from "../../../../common/tableSkeleton";
import APIList from "../../../../../api";
import checkIcon from "../../../../../assets/images/popup-check.png";
// import cross from "../../../../../assets/images/cross.png";

const MultiLevelTableForMyOrg = ({sortEmpList, handleViewFeedback, handleFeedback, activeTab, indentLevel = 0}) => {

    const employeeDetails = useSelector((state) => state?.empData?.empData);

    // const [showSubTeam, setShowSubTeam] = useState(false);
    const [manIds, setManIds] = useState([]);
    const [subTeamsData, setsubTeamsData] = useState([]);
    const [empListLoader, setEmpListLoader] = useState([]);

    // console.log("asasasasasasasa",manIds,subTeamsData)

    const handleOpenSubTeam = (id) => {
        // setShowSubTeam(true);
        if(manIds?.includes(id)){
            setManIds(manIds?.filter((x) => x !== id));
            setsubTeamsData(subTeamsData?.filter((x) => x?.id !== id));
        } else {
            setManIds([...manIds, id]);
            getSubTeam(id);
        }
    }

    const getSubTeam = (id) => {
        setEmpListLoader(true);
        APIList.getNewMyTeamGrouped({
            payload : {
                employeeEligibilityDTO : {},
                keyword : "",
                managerId : id,
                companyId : employeeDetails?.company?.id,
                page: "My Team"
            },
            page: 0,
            size: 1000
        })
        .then((res) => {
            if(res?.data?.data){
                let obj = {
                    id: id,
                    data: res?.data?.data
                };
                setsubTeamsData([...subTeamsData, obj]);
                // setEmpList(res?.data?.data);
                // setTotalPages(res?.data?.totalPages);
                setEmpListLoader(false);
                // setFirstLoad(false);
            }            
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setEmpListLoader(false);
        })
    }

    const getFeedbackStatus = (elligible, managerFeedback, reviewStarted) => {
        if(elligible) {
            if(managerFeedback && reviewStarted) {
                return "Submitted in close loop";
            }
            if(managerFeedback || reviewStarted) {
                return "Submitted";                
            }
            if(!managerFeedback && !reviewStarted) {
                return "Not submitted";
            }
        } else {
            return "Ineligible";
        }
    }

    const getFeedbackStatusForColor = (elligible, managerFeedback, reviewStarted) => {
        if(getFeedbackStatus(elligible, managerFeedback, reviewStarted) === "Submitted in close loop" || getFeedbackStatus(elligible, managerFeedback, reviewStarted) === "Submitted") {
           return true;
        } else {
            return false;
        }
    }
 
    return(
        sortEmpList?.map((emp,index) => {
            return(            
                <>             
                        <tr> 
                        <td>
                            {
                                emp?.isManager ?
                                manIds?.includes(emp?.employeeId) ?                                            
                                <AiOutlineMinusSquare onClick={() => handleOpenSubTeam(emp?.employeeId)} style={{cursor:"pointer", fontSize:"1.25rem", marginLeft: `${indentLevel * 10}px`}} />
                                :
                                <AiOutlinePlusSquare onClick={() => handleOpenSubTeam(emp?.employeeId)} style={{cursor:"pointer", fontSize:"1.25rem", marginLeft: `${indentLevel * 10}px`}} />
                                :
                                ""
                            }                                        
                        </td>
                        <td>
                            <div className="empDetaila" style={{marginLeft: `${indentLevel * 10}px`, borderLeft: indentLevel > 0 ? "1px solid gray" : ""}}>
                                {
                                   indentLevel > 0 && 
                                   <div style={{display:"flex", alignItems:"center"}}>-</div>
                                }
                                <div className="empPic" style={{display:"flex", alignItems:"center"}}>
                                {emp?.profilePhotoPath ? 
                                    <img src={emp?.profilePhotoPath} className="userImg" alt="User Image" />
                                    :
                                    <Avatar 
                                        name={emp.employeeName} 
                                        size="45" 
                                        className="userImg"
                                        color={"#00425A"}    
                                    />
                                }
                                </div>
                                <div className="userInfo">
                                    <p className="name">{emp?.employeeName}</p>
                                    {/* <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : "Designation"}</p> */}
                                    <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : ""}</p>
                                </div>
                            </div>
                        </td> 
                        <td>
                        {/* <p className= {emp?.appraisalStatus === "Submitted" ? "btns green" : (emp?.appraisalStatus === "Not submitted" || emp?.appraisalStatus === "Draft") ? "btns grey" : "btns yellow" }>
                            {emp?.appraisalStatus === "Draft" ? "Not submitted" : emp?.appraisalStatus}
                        </p> */}
                        {/* /////////////self appresal///////////// */}
                        {/* <p className= {emp?.appraisalStatus === "ACTIVE" ? "btns green" : (emp?.appraisalStatus === "Not submitted" || emp?.appraisalStatus === "Draft") ? "btns grey" : "btns yellow" }>
                            {emp?.appraisalStatus === "ACTIVE" ? "Submitted" : 'not Submitted'}
                        </p> */}
    
    
                         {/* <p className= {emp?.isEligible ? emp?.appraisalStatus === "ACTIVE" ? "btns green" : "btns grey" : "btns red"}> */}
                         <p className= {emp?.appraisalStatus === "ACTIVE" ? "btns green" : "btns grey"}>
                            {emp?.isEligible ? emp?.appraisalStatus === "ACTIVE" ? "Submitted" : 'Not Submitted' : "Ineligible"}
                        </p>
                        </td> 
                        <td>
                        {/* <p className= "btns grey">
                            Not submitted
                        </p> */}
                        {/* ///////////////peer-feedback//////////////////// */}
                        {/* <p className= {emp?.peerFeedbackstatus[0] === "0" ? "btns yellow" : "btns grey" }>
                            {emp?.peerFeedbackstatus}
                        </p> */}
    
    
    
                        {/* <p className={emp?.isEligible ? "btns grey" : "btns red"}> */}
                        <p className={"btns grey"}>
                            {
                                (activeTab === "My Team") ?
                                emp?.isEligible ? 
                                <div className="view-feedback-btn" onClick={() => (
                                    employeeDetails?.permissions?.includes("my-team-view-peer-drawer") ?
                                    handleViewFeedback(emp) :
                                    (
                                        toast.error(
                                            <div style={{display:"flex", flexDirection:"row"}}>
                                                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                &nbsp;&nbsp;{'You do not have permission to view'}
                                            </div>
                                        )
                                    )
                                )}>
                                    {
                                        
                                        !isEmptyNullUndefined(emp?.peerFeedbackstatus) ? 
                                        <div className="status">
                                            <div>Received{`(${emp?.peerFeedbackstatus?.split("-")[0]})`}</div>
                                            <div>Pending{`(${emp?.peerFeedbackstatus?.split("-")[1]})`}</div>
                                        </div>
                                        :
                                        <div className="status">
                                            No Feedbacks
                                        </div>
                                        
                                    }
                                    
                                    <div>
                                    <IoIosArrowForward style={{fontSize:"1rem", color:"var(--primary)"}} /> 
                                    </div>
                                </div>
                                :
                                    "Ineligible"
                                :
                                <div className="status">
                                    <div>Received{`(${emp?.peerFeedbackstatus?.split("-")[0]})`}</div>
                                    <div>Pending{`(${emp?.peerFeedbackstatus?.split("-")[1]})`}</div>
                                </div>
                            }
                        </p>
                        </td> 
                        <td>
                        {/* <p className= {emp?.feedbackstatus === "Submitted" ? "btns green" : emp?.feedbackstatus === "Not submitted" ? "btns grey" : "btns yellow"}>
                            {emp?.feedbackstatus}
                        </p> */}
                        {/* ///////////////manager-feedback//////////////////// */}
    
    
    
                        {/* <p className= {emp?.isEligible ? emp?.managerFeedbackstatus === "ACTIVE" ? "btns green" : "btns grey" : "btns red"}> */}
                        <p className= {getFeedbackStatusForColor(emp?.isEligible, emp?.managerFeedbackstatus === "ACTIVE", emp?.isManagerReviewStarted)? "btns green" : "btns grey"}>
                            {getFeedbackStatus(emp?.isEligible, emp?.managerFeedbackstatus === "ACTIVE", emp?.isManagerReviewStarted)}
                        </p>
                        </td> 
                        <td>                                    
                        <p className= "btns grey">
                        {
                                emp?.rating?.split("/")?.map((rating,index) => {
                                    return(
                                        <div style={{textWrap: 'nowrap'}}>{rating}</div>
                                    )
                                })
                            }
                        </p>    
                        </td> 
                        <td>                                    
                        <p className= "btns grey">
                        <div className="inner-calib">
                                <div>
                              {
                                emp?.calibratedRating?.split("/")?.map((calibratedRating,index) => {
                                    return(
                                        <div style={{textWrap: 'nowrap'}}>{calibratedRating}</div>
                                    )
                                })
                            }
                            </div>
                            {
                                emp?.isFinalCalibiratedRating &&
                                <img className="check-team" src={checkIcon} alt="check" />
                            }
                            
                            </div>
                        </p>    
                        </td> 
                        <td>
                        <p className= {"btns grey promo"}>
                            {emp?.eligibleForPromotion ? emp?.eligibleForPromotion : "No"}
                            {
                                emp?.isPromotionApproved &&
                                <img className="check-team" src={checkIcon} alt="check" />
                                // :
                                // emp?.eligibleForPromotion === "Yes" &&
                                // <img className="check-team" src={cross} alt="check" />
                            }
                        </p>
                        </td> 
                        <td>
                            <div className="action">
                                {
                                    emp?.isEligible && (activeTab === "My Team") ? 
                                    <IconButton variant="outlined" onClick={() => (
                                        employeeDetails?.permissions?.includes("my-team-enter-manager-feedback") ?
                                        handleFeedback(emp) : (
                                            toast.error(
                                                <div style={{display:"flex", flexDirection:"row"}}>
                                                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                                    &nbsp;&nbsp;{'You do not have permission to enter this page'}
                                                </div>
                                            )
                                        )
    
    
                                    )}>
                                {/* Provide Feedback */}
                                <IoIosArrowForward /> 
                                </IconButton>
                                :
                                ""
                                }
                                
                            </div>
                        </td> 
                    </tr>
    
                    {
                        manIds?.includes(emp?.employeeId) && (subTeamsData?.filter(x => x?.id === emp?.employeeId)?.length === 0) &&
                        empListLoader ?
                                // <Backdrop
                                // sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor:"rgba(0, 0, 0, 0.25)" }}
                                // open={empListLoader}
                                // >
                                // <CircularProgress color="inherit" />
                                // </Backdrop>
                                <tr>
                                    <td></td>
                                    <td>
                                     <CircularProgress color="primary" />
                                    </td>
                                </tr>
                                :
                                <MultiLevelTableForMyOrg sortEmpList={subTeamsData?.filter(x => x?.id === emp?.employeeId)[0]?.data} indentLevel={indentLevel + 1} />
                    }
                    
                    </>  
            )
        }
    )
    )
}

export default MultiLevelTableForMyOrg;