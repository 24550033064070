import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Chart.js को रजिस्टर करें
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TCIncreaseByLevelAndGenderChart = () => {
  const history = useHistory();

  const valueOnTopPlugin = {
    id: 'valueOnTopPlugin',
    afterDatasetsDraw: (chart) => {
      const { ctx, data } = chart;
      chart.data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i);
        meta.data.forEach((bar, index) => {
          const value = dataset.data[index];
          ctx.fillStyle = 'black'; // Text color
          ctx.font = '12px Arial';
          ctx.textAlign = 'center';
          ctx.fillText( `${value}%`, bar.x, bar.y - 9); // Position above the bar
        });
      });
    },
  };

  // Chart Data
  const data = {
    labels: ['L1', 'L2', 'L3', 'L4', 'L5'],
    datasets: [
      {
        label: 'Male',
        data: [3, 6, 11, 12, 9],
        backgroundColor: '#2d99fe',
        borderColor: '#2d99fe',
        borderWidth: 1,
        borderRadius: 10,
        borderSkipped: 'bottom',
      },

      {
        label: 'Female',
        data: [5, 9, 7, 15, 7],
        backgroundColor: '#cd77db',
        borderColor: '#cd77db',
        borderWidth: 1,
        borderRadius: 10,
        borderSkipped: 'bottom',
      },
    ],
  };

  // Chart Options
  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          display: true, // Show the tick values
          callback: (value) => `${value}%`, // Add percentage sign
        },
      }
    },
    plugins: {
      title: {
        display: false,
        position: 'bottom',
        text: 'Year on Year',
      },
      legend: {
        position: 'bottom',
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: '#000',
        font: {
          size: 12,
          weight: 'bold',
        },
        formatter: (value) => `$${value}`,
      },
    },
  };

  return (
    <Grid>
      <Bar data={data} options={options} plugins={[valueOnTopPlugin]} />
    </Grid>
  )
};

export default TCIncreaseByLevelAndGenderChart; 
