import React, { useState } from 'react';
import { Grid, Typography, Button, Checkbox } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { FaSort } from "react-icons/fa6";
import { AiFillCaretRight } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import bellIcon from "../../../../../src/assets/images/header/empeditIcon.svg";

const NominatePromotions = ({ }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const nominateEmps = [
        {
            id: 1,
            empCode: 10614,
            empName: 'Ashish Reji',
            designation: 'Assistant Manager',
            perfRating2024: 'A',
            perfRating2023: 'A',
            recommendForPromotion: 'Yes',
            editViewButton: 'Edit Promotion Form',
            empIcon: '',
        },
        {
            id: 2,
            empCode: 10680,
            empName: 'Akshay C ',
            designation: 'Senior Associate',
            perfRating2024: 'A',
            perfRating2023: 'A+',
            recommendForPromotion: 'Yes',
            editViewButton: 'Edit Promotion Form',
            empIcon: bellIcon,
        },
        {
            id: 3,
            empCode: 10681,
            empName: 'Govind H',
            designation: 'Assistant Manager',
            perfRating2024: 'C',
            perfRating2023: 'C',
            recommendForPromotion: 'No',
            editViewButton: 'View Promotion Form',
            empIcon: '',
        },
        {
            id: 4,
            empCode: 10692,
            empName: 'Umashankar Aravallil',
            designation: 'Senior Associate',
            perfRating2024: 'A',
            perfRating2023: 'A+',
            recommendForPromotion: 'Yes',
            editViewButton: 'Edit Promotion Form',
            empIcon: '',
        },
        {
            id: 5,
            empCode: 10691,
            empName: 'Akshay C',
            designation: 'Assistant Manager',
            perfRating2024: 'A+',
            perfRating2023: 'B',
            recommendForPromotion: 'Yes',
            editViewButton: 'View Promotion Form',
            empIcon: bellIcon,
        },
        {
            id: 6,
            empCode: 10614,
            empName: 'Ashish Reji',
            designation: 'Assistant Manager',
            perfRating2024: 'A',
            perfRating2023: 'A',
            recommendForPromotion: 'Yes',
            editViewButton: 'View Promotion Form',
            empIcon: '',
        },
        {
            id: 7,
            empCode: 10680,
            empName: 'Akshay C ',
            designation: 'Senior Associate',
            perfRating2024: 'A',
            perfRating2023: 'A+',
            recommendForPromotion: 'Yes',
            editViewButton: 'Edit Promotion Form',
            empIcon: '',
        },
        {
            id: 8,
            empCode: 10681,
            empName: 'Govind H',
            designation: 'Assistant Manager',
            perfRating2024: 'C',
            perfRating2023: 'C',
            recommendForPromotion: 'No',
            editViewButton: 'View Promotion Form',
            empIcon: '',
        },
        {
            id: 9,
            empCode: 10692,
            empName: 'Umashankar Aravallil',
            designation: 'Senior Associate',
            perfRating2024: 'A',
            perfRating2023: 'A+',
            recommendForPromotion: 'Yes',
            editViewButton: 'View Promotion Form',
            empIcon: bellIcon,
        },
        {
            id: 10,
            empCode: 10691,
            empName: 'Akshay C',
            designation: 'Assistant Manager',
            perfRating2024: 'A+',
            perfRating2023: 'B',
            recommendForPromotion: 'Yes',
            editViewButton: 'Edit Promotion Form',
            empIcon: '',
        },
    ];

    const handleEditViewButton = (item) => {
        if (item.editViewButton === "Edit Promotion Form") {
            history.push("/promotion-form");
        } else if (item.editViewButton === "View Promotion Form") {
            history.push("/view-promotion-form");
        }
    }

    const handleCheckboxChange = (event, item) => {
        const isChecked = event.target.checked;
        item.recommendForPromotion = isChecked ? "Yes" : "No";
    };

    return (
        <Grid container className="table-card">
            <Grid item xs={12} sm={12} md={12}>
                <div className="nominate-promo-table">
                    <table className="emp-list-table">
                        <tr className="table-head-emp-list">
                            <th className="w-130 pl-20">Emp Code &nbsp;<FaSort className="c-pointer" /></th>
                            <th className="w-130">Emp Name &nbsp;<FaSort className="c-pointer" /></th>
                            <th>Current Designation</th>
                            <th className="w-130">Recommend for promotion</th>
                            <th className="w-130">Proposed Designation</th>
                            <th>Perf. Rating 2024</th>
                            <th>Perf. Rating 2023</th>

                            <th className="ta-end pr-20">Action &nbsp;<FaSort className="c-pointer" /></th>
                        </tr>

                        {nominateEmps.map((item, index) => (
                            <tr>
                                <td className="pl-20">
                                    <div>
                                        <Typography className="emp-table-value">{item.empCode}</Typography>
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex'>
                                        <Typography className="emp-name-checkbox">{item.empName} </Typography>
                                        {item.empIcon === bellIcon && <img src={item.empIcon} alt="bell" className="emp-icon" />}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <Typography className="emp-table-value">{item.designation}</Typography>
                                    </div>
                                </td>

                                <td>
                                    <div>
                                        <Typography component="label" className="emp-name-checkbox">
                                            <Checkbox
                                                checked={item.recommendForPromotion === "Yes"}
                                                onChange={(event) => handleCheckboxChange(event, item)}
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: '#00425A',
                                                        // backgroundColor: 'transparent',
                                                        '& .MuiSvgIcon-root': {
                                                            fill: 'transparent',
                                                            stroke: '#00425A',
                                                        }
                                                    },
                                                    '&.MuiCheckbox-root': {
                                                        // backgroundColor: 'transparent',
                                                    },
                                                    opacity: item.empName === "Akshay C" || item.empName === "Umashankar Aravallil" ? 0.5 : 1
                                                }}
                                                disabled={item.empName === "Akshay C" || item.empName === "Umashankar Aravallil"}
                                            />
                                            {item.recommendForPromotion}
                                        </Typography>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <Typography className="emp-table-value">{item.designation}</Typography>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <Typography className="emp-table-value">{item.perfRating2023}</Typography>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <Typography className="emp-table-value">{item.perfRating2024}</Typography>
                                    </div>
                                </td>

                                <td className="ta-end pr-20">
                                    <div>
                                        {item.recommendForPromotion === "Yes" ?
                                            <Button className="edit-view-btn" onClick={() => handleEditViewButton(item)}>
                                                {item.editViewButton === "Edit Promotion Form" &&
                                                    <AiFillCaretRight className="mr-5" />
                                                }
                                                {item.editViewButton === "View Promotion Form" &&
                                                    <AiFillEye className="mr-5" />
                                                }
                                                {item.editViewButton}
                                            </Button> : <div />
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>

                <div className="save-submit-btn-div">
                    <Button className="save-draft">{"Save Draft"}</Button>
                    <Button className="submit-list">{"Submit List"}</Button>
                </div>
            </Grid>
        </Grid>
    );
};

export default React.memo(NominatePromotions);
