import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="28"
      fill="none"
      viewBox="0 0 27 28"
    >
      <path
        fill="#fff"
        d="M26.275 12.413l-.002-.002L15.258 1.396A2.469 2.469 0 0013.5.668a2.47 2.47 0 00-1.758.728L.733 12.406a2.489 2.489 0 00-.006 3.522 2.472 2.472 0 001.726.727h.439v8.106a2.913 2.913 0 002.91 2.91h4.309a.791.791 0 00.79-.791v-6.355c0-.732.596-1.328 1.328-1.328h2.542c.732 0 1.327.596 1.327 1.328v6.355c0 .436.355.79.792.79h4.309a2.913 2.913 0 002.91-2.909v-8.105h.406a2.47 2.47 0 001.758-.729 2.49 2.49 0 00.002-3.514zm-1.12 2.395a.898.898 0 01-.64.265h-1.198a.791.791 0 00-.79.791v8.897c0 .732-.596 1.328-1.328 1.328H17.68v-5.564a2.913 2.913 0 00-2.91-2.91H12.23a2.913 2.913 0 00-2.91 2.91v5.564H5.801a1.329 1.329 0 01-1.327-1.328v-8.897a.791.791 0 00-.791-.79H2.505a.897.897 0 01-.66-.265.905.905 0 010-1.279h.001v-.001L12.862 2.515a.897.897 0 01.64-.265c.24 0 .467.094.638.265l11.012 11.012.005.005c.35.353.35.925-.001 1.276z"
      ></path>
    </svg>
  );
}

export default Icon;