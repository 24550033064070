import React, { useState } from 'react';
import { Grid, Typography, Tooltip } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import KudosIcon1 from "../../../../assets/images/MyCommunity/kudosIcon1.svg";
import KudosIcon2 from "../../../../assets/images/MyCommunity/kudosIcon2.svg";
import KudosIcon3 from "../../../../assets/images/MyCommunity/kudosIcon3.svg";
import KudosIcon4 from "../../../../assets/images/MyCommunity/kudosIcon4.svg";
import KudosIcon5 from "../../../../assets/images/MyCommunity/kudosIcon5.svg";



const Kudos = ({ setKudosForModal }) => {
    const dispatch = useDispatch();

    const kudosList = [
        {
            id: 1,
            icon: KudosIcon1,
            title: 'Positive Pat-on-the back',
            cardBg: '#E9FBF2',
            tooltip: 'Awarded to employees who give extra effort and bring their A-game.',
        },
        {
            id: 2,
            icon: KudosIcon2,
            title: 'High Five',
            cardBg: '#F9F2FF',
            tooltip: 'Awarded to employees who give extra effort and bring their A-game.',
        },
        {
            id: 3,
            icon: KudosIcon3,
            title: 'Raising the bar',
            cardBg: '#F0FAFD',
            tooltip: 'Awarded to employees who give extra effort and bring their A-game.',
        },
        {
            id: 4,
            icon: KudosIcon4,
            title: 'Milestone Achiever',
            cardBg: '#EEEFFF',
            tooltip: 'Awarded to employees who give extra effort and bring their A-game.',
        },
        {
            id: 5,
            icon: KudosIcon5,
            title: 'High Five',
            cardBg: '#FFF8E7',
            tooltip: 'Awarded to employees who give extra effort and bring their A-game.',
        },
        {
            id: 6,
            icon: KudosIcon2,
            title: 'High Five',
            cardBg: '#F9F2FF',
            tooltip: 'Awarded to employees who give extra effort and bring their A-game.',
        },
        {
            id: 7,
            icon: KudosIcon3,
            title: 'Raising the bar',
            cardBg: '#F0FAFD',
            tooltip: 'Awarded to employees who give extra effort and bring their A-game.',
        },
        {
            id: 8,
            icon: KudosIcon4,
            title: 'Milestone Achiever',
            cardBg: '#EEEFFF',
            tooltip: 'Awarded to employees who give extra effort and bring their A-game.',
        },
    ];

    const handleOpenPopup = (title) => {
        if (title === "Positive Pat-on-the back") {
            setKudosForModal(true);
        }
    }

    return (
        <Grid container className="kudos-rewards-card">
            <Grid item xs={12} sm={12} md={12} className="kudos-rewards-container">
                <Grid container direction="row" wrap="nowrap" className="kudos-rewards-scroll">
                    {kudosList.map((item, index) => (
                        item.title === "High Five" ?
                        <Tooltip
                            key={index}
                            title={item.tooltip}
                            arrow
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        width: '148px',
                                        fontSize: '12px',
                                    },
                                },
                            }}
                        >
                            <div className="kudos-rewards-item-div" onClick={() => handleOpenPopup(item?.title)}>
                                <Grid key={index} item className="kudos-rewards-item" style={{ backgroundColor: item.cardBg }} >
                                    <img src={item.icon} alt="Slider" className="kudos-rewards-icon" />
                                    <Typography className="kudos-rewards-title">{item.title}</Typography>
                                </Grid>
                            </div>
                        </Tooltip>
                        :
                        <div className="kudos-rewards-item-div" onClick={() => handleOpenPopup(item?.title)}>
                                <Grid key={index} item className="kudos-rewards-item" style={{ backgroundColor: item.cardBg }} >
                                    <img src={item.icon} alt="Slider" className="kudos-rewards-icon" />
                                    <Typography className="kudos-rewards-title">{item.title}</Typography>
                                </Grid>
                            </div>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default React.memo(Kudos);
