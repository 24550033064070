import React, { useEffect, useState } from "react";
import { IoMdSearch } from "react-icons/io";
import {
    Grid,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const UploadSendVideoMessageModal = ({ openUploadSendVideoMessageModal, handleUploadSendVideoMessageModalClose }) => {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery("(max-width: 480px)");
    
    // const [search, setSearch] = useState("");
    // const handleSearchChange = (e) => {
    //     setSearch(e.target.value);
    // };

    // const handleSearchSubmit = (e) => {
    //     e.preventDefault();
    //     console.log("Searching for:", search);
    // };

    // State to store uploaded files
    const [files, setFiles] = useState([]);
    const [status, setStatus] = useState("INACTIVE"); // Example status

    // Handle file selection
    const handleImageChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const newFiles = [...files, selectedFile]; // Add new file to state
            setFiles(newFiles); // Update state with new files
        }
    };

    // Open document handler (optional)
    const openDoc = (index) => {
        // Function to open the document (e.g., in a new tab or modal)
        const file = files[index];
        // Logic to open the file...
    };

    // Delete document handler
    const handleDeleteDoc = (index) => {
        const updatedFiles = files.filter((_, fileIndex) => fileIndex !== index);
        setFiles(updatedFiles); // Remove the file from state
    };

    return (
        <div>
            <Dialog
                open={openUploadSendVideoMessageModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                PaperProps={{
                    style: {
                        backgroundColor: "#F9F9F9",
                        borderRadius: "20px",
                        // width: "52%",
                        width: isMobile ? "100%" : "52%",
                    },
                }}
            >
                <DialogContent>
                    <Grid container spacing={4} className="kudos-for-modal">
                        <Grid item xs={12} md={12} sm={12}>
                            {/*----- Upload & Send Video Message -----*/}
                            <Typography className="upload-send-txt">{"Upload & Send Video Message"}</Typography>
                            <div className="upload-document-container">
                                <div className="MuiAccordionSummary-content MuiAccordionSummary-contentGutters">
                                    <div className="item-distributor">
                                        <>
                                            <div className="doc-distibute">
                                                {files.map((file, index) => (
                                                    <div key={index} className="documents">
                                                        <div
                                                            onClick={() => openDoc(index)}
                                                            className="documents-name"
                                                        >
                                                            {file.name}
                                                        </div>
                                                        {status !== "ACTIVE" && (
                                                            <div
                                                                onClick={() => handleDeleteDoc(index)}
                                                                className="delete"
                                                            >
                                                                X
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                        {status !== "ACTIVE" && (
                                            <button className="upload-doc-button">
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImageChange}
                                                    style={{ display: "none" }}
                                                    id="imageInput"
                                                />
                                                <label htmlFor="imageInput" className="edit-button">
                                                    Browse <span>or Drag n Drop</span>
                                                </label>
                                                <div className="uploadmessage">
                                                    mp4, wma Max upto 20MB
                                                </div>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>


                            {/*----- Your Message -----*/}
                            <div className="textAreaSection">
                                <label htmlFor="outlined-multiline-static">{"Your Message "}</label>
                                <TextField
                                    name="demonstrated"
                                    id="outlined-multiline-static"
                                    variant="outlined"
                                    placeholder="Please provide Overall feedback"
                                    multiline
                                    rows={3}
                                    value="I would like him to Thing Big and Strategically as a VP (showcase this in areas outside of the standard input focus)"
                                    style={{ width: "100%" }}
                                    InputProps={{
                                        style: {
                                            color: "#7E7E7E",
                                            fontSize: "16px",
                                            fontWeight: "lighter",
                                            border: "1px solid #D9D9D9",
                                            backgroundColor: "#FFFFFF",
                                            borderRadius: "8px",
                                        },
                                    }}
                                />
                            </div>

                            {/*----- Cancel & Done Button -----*/}
                            <div className="botttombtn">
                                <button
                                    className="cancel-btn"
                                    onClick={handleUploadSendVideoMessageModalClose}
                                    color="transparent"
                                    variant="outlined"
                                >
                                    Cancel
                                </button>
                                <button
                                    className="update-btn"
                                    // onClick={() => {}}
                                    type="submit"
                                    variant="outlined"
                                >
                                    Done
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default UploadSendVideoMessageModal;
