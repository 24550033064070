import React, { useEffect, useState } from "react";
import { IoMdSearch } from "react-icons/io";
import {
    Grid,
    Typography,
    Dialog,
    DialogContent,
    TextField,
    useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CircleImg from "../../../../assets/images/MyCommunity/RewardsIcon1.svg";
import UpArrow from "../../../../assets/images/MyCommunity/upArrow.svg";
import RoleCloseIcon from "../../../../assets/images/successionPlan/roleClose.svg";

const ChairmaAwardsModal = ({ openChairmaAwardsModal, handleChairmaAwardsModalClose }) => {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery("(max-width: 480px)");

    const [search, setSearch] = useState("");
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        console.log("Searching for:", search);
    };


    return (
        <div>
            <Dialog
                open={openChairmaAwardsModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                PaperProps={{
                    style: {
                        backgroundColor: "#F9F9F9",
                        borderRadius: "20px",
                        width: isMobile ? "100%" : "52%",
                    },
                }}
            >
                <DialogContent>
                    <Grid container spacing={4} className="kudos-for-modal">
                        <Grid item xs={12} md={12} sm={12}>
                            {/*----- Heading -----*/}
                            <Typography className="model-title">{"Peer Nomination For "}</Typography>

                            {/*----- Top Image Card Section -----*/}
                            <div className="image-section">
                                <div className="main-b">
                                    <div className="org-update-row">
                                        <img src={CircleImg} className="loginBg"></img>
                                        <div className="update-info">
                                            <Typography className="head">{"Chairman Awards"}</Typography>
                                            <Typography className="description">{"Awards Definition"}{" "}<img src={UpArrow} className="up-arrow"></img></Typography>
                                            <Typography className="description">
                                                In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document. In publishing and graphic design, Lorem ipsum is a placeholder text.
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*----- Search Section -----*/}
                            <div className="searchSection">
                                <form onSubmit={handleSearchSubmit} className="search-bar">
                                    <input
                                        type="text"
                                        // placeholder="Search by username or email"
                                        value={search}
                                        onChange={handleSearchChange}
                                        aria-label="Search"
                                        className="search-input"
                                    />
                                    <button type="submit" className="search-btn">
                                        <IoMdSearch size={20} />{" "}
                                    </button>
                                </form>
                                <div className="role-container">
                                    <div className="role-border-div">
                                        <div className="d-flex ai-center">
                                            <Typography className="role-type">
                                                Savannah Nguyen
                                            </Typography>
                                            <img
                                                src={RoleCloseIcon}
                                                alt="RoleCloseIcon"
                                                height={12}
                                                width={12}
                                                className="role-close-Icon"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/*----- Enter reason for nomination* -----*/}
                            <div className="textAreaSection">
                                <label htmlFor="outlined-multiline-static" className="mt-20">{"Enter reason for nomination*"}</label>
                                <TextField
                                    name="demonstrated"
                                    id="outlined-multiline-static"
                                    variant="outlined"
                                    placeholder="Please provide Overall feedback"
                                    multiline
                                    rows={3}
                                    value="I would like him to Thing Big and Strategically as a VP (showcase this in areas outside of the standard input focus)"
                                    style={{ width: "100%" }}
                                    InputProps={{
                                        style: {
                                            color: "#7E7E7E",
                                            fontSize: "16px",
                                            fontFamily: "poppins",
                                            fontWeight: "400",
                                            border: "1px solid #D9D9D9",
                                            backgroundColor: "#FFFFFF",
                                            borderRadius: "8px",
                                        },
                                    }}
                                />
                            </div>

                            {/*----- Cancel & Done Button -----*/}
                            <div className="botttombtn">
                                <button
                                    className="cancel-btn"
                                    onClick={handleChairmaAwardsModalClose}
                                    color="transparent"
                                    variant="outlined"
                                >
                                    Cancel
                                </button>
                                <button
                                    className="update-btn"
                                    // onClick={() => {}}
                                    type="submit"
                                    variant="outlined"
                                >
                                    Done
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ChairmaAwardsModal;
