import React from "react";

function HRIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27" 
      height="28" 
      fill="none"
      viewBox="0 0 20 21"
    >
      <g
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.2"
        clipPath="url(#clip0_4873_3185)"
      >
        <path d="M18.412 14.406C20.2 10.112 18.08 4.858 13.818 3a.34.34 0 00-.28.62 8.228 8.228 0 014.265 10.482 1.58 1.58 0 10.61.304zm-.984 2.13a.902.902 0 110-1.804.902.902 0 010 1.804zm-15.82-4.338c.547-.047.256-.73.307-1.087a8.23 8.23 0 016.853-8.102 1.577 1.577 0 003.093-.442C11.825.63 9.051.42 8.722 2.32a8.927 8.927 0 00-7.452 9.567.34.34 0 00.338.31zm8.671-10.533a.902.902 0 110 1.804.902.902 0 010-1.804zm4.483 16.247a8.218 8.218 0 01-11.046-1.67 1.574 1.574 0 10-.546.403 8.896 8.896 0 0011.974 1.828.34.34 0 00-.382-.561zM1.635 15.198a.902.902 0 111.804 0 .902.902 0 01-1.804 0z"></path>
        <path d="M10 6.12c-2.156-.03-3.358 2.62-1.937 4.214a4.667 4.667 0 00-2.634 3.456.942.942 0 00.923 1.09h7.294a.942.942 0 00.926-1.09 4.68 4.68 0 00-2.637-3.456c1.421-1.591.22-4.246-1.936-4.215zm0 .679c2.476.079 2.476 3.67 0 3.749-2.478-.079-2.477-3.67 0-3.749zm3.9 7.1a.252.252 0 01-.254.3H6.352a.253.253 0 01-.246-.188.255.255 0 01-.006-.112 3.974 3.974 0 012.544-3.065 2.533 2.533 0 002.71 0A3.984 3.984 0 0113.9 13.9z"></path>
      </g>
      <defs>
        <clipPath id="clip0_4873_3185">
          <path
            fill="#fff"
            d="M0 0H20V20H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default HRIcon;
