import { Typography, FormControl, InputLabel, MenuItem, Select, TextField, InputAdornment, Checkbox, ListItemText, Stack, Button, Skeleton, CircularProgress, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { IoIosSearch } from "react-icons/io";
import Minus from "../../../assets/images/compensation/minus.svg";
import Plus from "../../../assets/images/compensation/plus.svg";
import APIList from "../../../api";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import SideFilters from "../../common/sideFilters";
import FilterSelect from "../../common/filterSelect";
import filterIcon from "../../../assets/images/filter.svg";
import { CurrencyFormat, getFormattedDate, accordianSVGstyle, isEmptyNullUndefined } from "../../utils/utils";
import PaginationMedi from "../../common/pagination";
import { FaFlag } from "react-icons/fa6";
import DoughnutChart from "../../common/doughnutChart";
import { SlClose } from "react-icons/sl";
import DialogPopup from "../../common/Dialog";
import axios from "axios";
import { BASE_URL } from "../../../api/apiServices";
import { saveAs } from 'file-saver';
import MediTooltip from "../../common/mediTooltip";
import { BsChevronDown } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const Compensation = () => {

    const history = useHistory();

    const [allYearRatings, setAllYearRatings] = useState([]);
    const [currentCompYears, setCurrentCompYears] = useState([]);
    const [nextCompYears, setNextCompYears] = useState([]);

    const columns = [
        "Date Of Joining",
        "Function",
        "Sub Function",
        "Designation",
        "Level",
        "Location",
        "Cost Center",
        // "Job code",
        "Manager Emp Code",
        "Manager Name",
        "Promotion Status",
        "New Designation",
        // "Performance Rating (Combined Rating) 2021-22",
        // "Performance Rating (Combined Rating) 2022-23",
        // "Performance Rating (Combined Rating) 2023-24",
        ...allYearRatings,
        // "LBP (Yes/No)",
        "Last Increment Date",
        `No of Hikes ${currentCompYears}`,
        `Total Hike % ${currentCompYears}`,
        `Fixed Salary ${currentCompYears}`,
        `Variable Pay Target ${currentCompYears}`,
        `Earned Incentives ${currentCompYears}`,
        `Joining Bonus ${currentCompYears}`,
        `Retention Bonus ${currentCompYears}`,
        `Current Vested ESOPs Units ${currentCompYears}`,
        `Value of Vested ESOPs ${currentCompYears}`,
        `Long Term Contribution Bonus ${currentCompYears}`,
        `Benefits ${currentCompYears}`,
        `Current One-Time Bonus ${currentCompYears}`,
        `Total Compensation ${currentCompYears}`,
        "Current Fixed Compa Ratio",
        "Current TC Compa Ratio",
        "Recommended Fixed Salary increment %",
        "Manager Recommendation %",
        "Final Increment Amount",
        `New Fixed Salary ${nextCompYears}`,
        `New Incentives ${nextCompYears}`,
        `New Joining Bonus ${nextCompYears}`,
        `New Retention ${nextCompYears}`,
        `New Vested ESOP Units ${nextCompYears}`,
        `Value of ESOPs Vested ${nextCompYears}`,
        `Long Term Contribution Bonus ${nextCompYears}`,
        `Benefits ${nextCompYears}`,
        `One Time Bonus ${nextCompYears}`,
        `Recommended Variable Pay Target ${nextCompYears}`,
        "Manager Recommended Variable Pay Target",
        `New Total Compensation ${nextCompYears}`,
        "New Fixed Compa Ratio",
        "New TC Compa Ratio",
        `Variable Pay Eligibility ${currentCompYears}`,
        `Variable Payout Amount ${currentCompYears}`
    ]

    const blockColumns = [
        "Date Of Joining",
        "Function",
        "Sub Function",
        "Designation",
        "Level",
        "Location",
        "Cost Center",
        // "Job code",
        "Manager Emp Code",
        "Manager Name",
        "Promotion Status",
        "New Designation",
        // "Performance Rating (Combined Rating) 2021-22",
        // "Performance Rating (Combined Rating) 2022-23",
        // "Performance Rating (Combined Rating) 2023-24",
        ...allYearRatings,
        // "LBP (Yes/No)",
        "Last Increment Date",
        `No of Hikes ${currentCompYears}`,
        `Total Hike % ${currentCompYears}`,

        `Fixed Salary ${currentCompYears}`,
        "Current Fixed Compa Ratio",
        "Recommended Fixed Salary increment %", 
        "Manager Recommendation %",   
        "Final Increment Amount",
        `New Fixed Salary ${nextCompYears}`,

        `Variable Pay Target ${currentCompYears}`,
        `Recommended Variable Pay Target ${nextCompYears}`,
        "Manager Recommended Variable Pay Target",
        
        `Value of Vested ESOPs ${currentCompYears}`,
        `New Vested ESOP ${nextCompYears}`,
        
        `Total Compensation ${currentCompYears}`,      
        "Current TC Compa Ratio",    
        `New Total Compensation ${nextCompYears}`,
        "New TC Compa Ratio",
        
        `Variable Pay Eligibility ${currentCompYears}`,
        `Variable Payout Amount ${currentCompYears}`,
    ]

    const grayBlocks = [
        `Fixed Salary ${currentCompYears}`,
        "Current Fixed Compa Ratio",
        "Recommended Fixed Salary increment %", 
        "Manager Recommendation %",   
        "Final Increment Amount",
        `New Fixed Salary ${nextCompYears}`,
        `Value of Vested ESOPs ${currentCompYears}`,
        `New Vested ESOP ${nextCompYears}`,
        `Variable Pay Eligibility ${currentCompYears}`,
        `Variable Payout Amount ${currentCompYears}`,
    ]

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const currency = useSelector((state) => state?.empData?.empData?.company?.currency);
    const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);
    const userToken = useSelector((state) => state?.user?.userToken);

    const [budgetGraphType, setBudgetGraphType] = useState("Per Annum");

    const [dropdownData, setDropdownData] = useState([]);
    const [dropdownLoader, setDropdownLoader] = useState(false); 
    const [chartData, setChartData] = useState([]);
    const [chartDataLoader, setChartDataLoader] = useState(false);

    const [totalCompData, setTotalCompData] = useState([]);
    const [dataLoader, setDataLoader] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);

    const [viewTableType, setViewTableType] = useState("Block planning View");
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState("");
    const [filterPayload, setFilterPayload] = useState({});
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [activeTab, setActiveTab] = useState("my Team");

    const [firstTimeLoad, setFirstTimeLoad] = useState(true);

    const [selectedConfigColumns, setSelectedConfigColumns] = useState(blockColumns);
    const [activeRow, setActiveRow] = useState(null);

    const [isElligibleForApprove, setIsElligibleForApprove] = useState(false);
    const [approveLoader, setApproveLoader] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    
    const [reportList, setReportList] = useState([]);
    const [annualCycle, setAnnualCycle] = useState(null);  
    const [downloadLoader, setDownloadLoader] = useState(false);

    const [openStatistics, setOpenStatistics] = useState(false);

    console.log("totalCompData", totalCompData);

    useEffect(() => {
      
        setSelectedConfigColumns(viewTableType === "Block planning View" ? blockColumns : columns);

    }, [allYearRatings])
    

    useEffect(() => {

        if(!isEmptyNullUndefined(employeeDetails?.id)) {

            getChartData();
            getDropdownData();
            getData(0, {});

            APIList.getIsElligibleToApprove({employeeId:employeeDetails?.id, companyId:employeeDetails?.company?.id})
            .then((res) => {
                setIsElligibleForApprove(res?.data);
            })
            .catch((err) => {
                setIsElligibleForApprove(false);
                toast.error(
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                    </div>
                )
            })

            APIList.getAllReports({companyId: employeeDetails?.company?.id, reportType:"Compensation"})
            .then((res) => {
             setReportList(res?.data);
            })
            .catch((err) => {
             toast.error(
                 <div style={{display:"flex", flexDirection:"row"}}>
                 <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                 &nbsp;&nbsp;{err?.title}
                 </div>
             );
            })

            APIList.getAnnualCycle({companyId: employeeDetails?.company?.id})
            .then((res) => {
             setAnnualCycle(res?.data[0]);
            })
            .catch((err) => {
             toast.error(
                 <div style={{display:"flex", flexDirection:"row"}}>
                 <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                 &nbsp;&nbsp;{err?.title}
                 </div>
             );
            }) 
        } 

    }, [employeeDetails?.id])

    useEffect(() => {
        const getSearch = setTimeout(() => {
            if (!firstTimeLoad) {
                if (currentPage !== 0) {
                    setCurrentPage(0);
                } else {
                    getData(0, filterPayload);
                }
            }
        }, 1000);

        return () => clearTimeout(getSearch);

    }, [search])

    useEffect(() => {
       
        const getdd = setTimeout(() => {
            if (!firstTimeLoad) {
                getData(currentPage, filterPayload);
            }
        }, 1000);

        return () => clearTimeout(getdd);
    }, [currentPage])

    useEffect(() => {
        if (!firstTimeLoad) {
            if (currentPage !== 0) {
                setCurrentPage(0);
            } else {
                getData(0, filterPayload);
            }
        }
    }, [itemsPerPage])

    useEffect(() => {
        if(!isEmptyNullUndefined(employeeDetails?.id)) {
            getChartData();
            if (!firstTimeLoad) {
                if (currentPage !== 0) {
                    setCurrentPage(0);
                } else {
                    getData(0, filterPayload);
                }
            }
        }
    }, [activeTab])

    const handleSubmitFilter = () => {
        if (currentPage !== 0) {
            setCurrentPage(0);
        } else {
            getData(0, filterPayload);
        }
    }

    const handleResetFilters = () => {
        setFilterPayload({});
        if (currentPage !== 0) {
            setCurrentPage(0);
        } else {
            getData(0, {});
        }
    }

    const getChartData = () => {
        setChartDataLoader(true);
        APIList.getCompChartData({
            employeeId: employeeDetails?.id,
            compannyId: employeeDetails?.company?.id,
            range: activeTab
        })
            .then((res) => {
                // console.log("getChartData",res)
                setChartData(res?.data);
                setChartDataLoader(false);
            }).catch((err) => {
                toast.error(
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                    </div>
                )
                setChartDataLoader(false);
            })
    }

    const getDropdownData = () => {
        setDropdownLoader(true);
        APIList.getCompDropdownData({
            companyId: employeeDetails?.company?.id,
        })
            .then((res) => {
                setDropdownData(res?.data);
                setDropdownLoader(false);
            }).catch((err) => {
                toast.error(
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                    </div>
                )
                setDropdownLoader(false);
            })
    }

    const getData = (cp, filters) => {
        console.log("aaaaaaasaas",cp, filters)
        setDataLoader(true);
        APIList.getCompensationData({
            payload: {
                employeeEligibilityDTO: filters,
                filterData: Object.keys(filters).length === 0 ? false : true, // Default is false. Set to true if filtering is required.
                keyword: search,
                managerId: employeeDetails?.id,
                companyId: employeeDetails?.company?.id,
                page: activeTab,
            },
            page: cp,
            size: itemsPerPage
        })
            .then((res) => {
                // console.log("res---------->",res)
                setTotalCompData(res?.data?.data?.employeeData);
                res?.data?.data?.compYears?.forEach((x) => {
                    if (x?.currentYear) {
                        setCurrentCompYears(x.currentYear);
                    }
                    if (x?.nextYear) {
                        setNextCompYears(x.nextYear);
                    }
                })
                setTotalPages(res?.data?.totalPages);
                !isEmptyNullUndefined(res?.data?.data?.employeeData) && setAllYearRatings(res?.data?.data?.employeeData[0]?.ratings?.map(item => Object.keys(item)[0]));
                setDataLoader(false);
                setFirstTimeLoad(false);
                setSaveLoader(false);
                setActiveRow(null);
            }).catch((err) => {
                toast.error(
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                    </div>
                )
                setDataLoader(false);
            })
    }

    const handleCollapse = (column) => {
        let temp = structuredClone(selectedConfigColumns);
        temp = temp?.filter((x) => x !== column);
        // } else {
        //     temp.push(column);
        // }
        setSelectedConfigColumns(temp);
    }


    const handleConfigColumnsChange = (event) => {
        // console.log("event.target.value", event.target)
        const value = event.target.value;
        let preventDuplicate = value.filter(
            (v, i, a) => a.findIndex((t) => t === v) === i,
        );
        if (value[value?.length - 1] === 'All') {
            preventDuplicate =
                preventDuplicate?.length - 1 === columns?.length
                    ? []
                    : columns;
        }
        setSelectedConfigColumns(preventDuplicate);
        // setCollapsedList(columns?.filter(x => !value?.includes(x)))
    };

    const [flag, setFlag] = useState(null);
    const [tcCompaFlag, setTcCompaFlag] = useState(null);
    const [recommendedVariablePayTargetFlag, setRecommendedVariablePayTargetFlag] = useState(null);
    const [newFixedSalaryFlag, setNewFixedSalaryFlag] = useState(null)
    const [isRecomendErr, setIsRecomendErr] = useState(false);
    const [withPromoErr, setWithPromoErr] = useState(false);

    const handleEditRow = (row) => {
        if (isEmptyNullUndefined(activeRow)) {
            setActiveRow(row);
            setFlag(null);
            setTcCompaFlag(null);
            setRecommendedVariablePayTargetFlag(null);
            setNewFixedSalaryFlag(null);
            setIsRecomendErr(false);
            setWithPromoErr(false);
        } else {
            toast.error(
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp; Please finish editing for {activeRow?.name}
                </div>
            )
        }
    }

    const [recomendAlert1, setRecomendAlert1] = useState(false);
    const [recomendAlert2, setRecomendAlert2] = useState(false);
    const [recomendAlert3, setRecomendAlert3] = useState(false);
    const [recomendAlert4, setRecomendAlert4] = useState(false);
    const [recomendAlert5, setRecomendAlert5] = useState(false);

    const [VPAlert, setVPAlert] = useState(false);
    const [VPAlert1, setVPAlert1] = useState(false);

    const isAlertsFetching = () => {
        if(recomendAlert1 || recomendAlert2 || recomendAlert3 || recomendAlert4 || recomendAlert5 || VPAlert || VPAlert1){
            return true;
        } else {
            return false;
        }
    }

    const handleChangeComp = (e) => {  // fix sal incre = 0 % and var pay target = 0 then maintain old sal ana old var pay , new tc =maintain old sal + old var pay + new sal heads

        let temp = structuredClone(activeRow);

        const newYearSalHeadSum = () => {
            let sum = 0;
            temp?.nextYearSalaryHead?.forEach((head) => {
                if(head?.isEligibilityForCalculation && head?.isReferedInDerivedSalary){
                    sum = sum + head?.amount;
                }
            })
            return Number(sum);
        }
   
        
console.log("e.target.name", e.target)

        if(e.target.name === "promotionStatus" || e.target.name === "newDesignation") {

            temp[e.target.name] = e.target.value;

        } else {

            temp[e.target.name] = isEmptyNullUndefined(e.target.value) ? 0 : e.target.value;

            const oldVariablePayTarget = temp?.currentYearSalaryHead?.filter((x) => x?.type === "Target")[0]?.amount;

            if (e.target.name === "managerRecommendation") {
    
                    temp["finalIncrementAmount"] = (Number(temp?.baseValue) * (Number(temp?.managerRecommendation) / 100));
                    temp["newFixedSalary"] = (Number(temp?.baseValue) + Number(temp?.finalIncrementAmount)) ;
                    
    
                    if((temp?.currentYearSalaryHead?.filter((x) => x?.type === "Target")[0]?.isEligibilityForCalculation) && !isEmptyNullUndefined(temp?.currentYearSalaryHead?.filter((x) => x?.type === "Target")[0])){
    
                        if (temp["recommendedVariablePayTarget"] === temp["managerRecommendedVariablePayTarget"]) {
    
                            temp["recommendedVariablePayTarget"] = Number(temp?.recommendedTCTarget - (Number(temp?.newFixedSalary) + newYearSalHeadSum() )) > oldVariablePayTarget ? Number(temp?.recommendedTCTarget - (Number(temp?.newFixedSalary) + newYearSalHeadSum() )) : oldVariablePayTarget;
                            temp["managerRecommendedVariablePayTarget"] = temp?.recommendedVariablePayTarget > oldVariablePayTarget ? temp?.recommendedVariablePayTarget : oldVariablePayTarget;
    
                        } else {
    
                            temp["recommendedVariablePayTarget"] = Number(temp?.recommendedTCTarget - (Number(temp?.newFixedSalary) + newYearSalHeadSum()));
                        
                        }
    
                    }

                    temp["newTotalCompensation"] = newYearSalHeadSum() + Number(temp?.managerRecommendedVariablePayTarget) + Number(temp?.newFixedSalary);
                    temp["newFixedCompaRatio"] = isFinite(temp?.newFixedSalary / temp?.fixedMarketRanges?.mid) ? Number(temp?.newFixedSalary / temp?.fixedMarketRanges?.mid) : 0;
                    temp["newTCCompaRatio"] = isFinite(temp?.newTotalCompensation / temp?.tcMarketRanges?.mid) ? Number(temp?.newTotalCompensation / temp?.tcMarketRanges?.mid) : 0;

                // }
            }
    
            if (e.target.name === "managerRecommendedVariablePayTarget") {

    
                temp["newTotalCompensation"] = newYearSalHeadSum() + Number(temp?.managerRecommendedVariablePayTarget) + Number(temp?.newFixedSalary);
                temp["newFixedCompaRatio"] = isFinite(temp?.newFixedSalary / temp?.fixedMarketRanges?.mid) ? Number(temp?.newFixedSalary / temp?.fixedMarketRanges?.mid) : 0;
                temp["newTCCompaRatio"] = isFinite(temp?.newTotalCompensation / temp?.tcMarketRanges?.mid) ? Number(temp?.newTotalCompensation / temp?.tcMarketRanges?.mid) : 0;

                console.log("asasasasasa ====== >>>",temp?.managerRecommendedVariablePayTarget, temp?.newFixedSalary, temp?.nextYearSalaryHead?.reduce((a, c) => a + c?.isEligibilityForCalculation && c?.isReferedInDerivedSalary && c?.amount, 0), newYearSalHeadSum())
    
            }
    
    
            if (e.target.name === "managerRecommendation") {

                setRecomendAlert1(true);
                setRecomendAlert2(true);
                setRecomendAlert3(true);
                setRecomendAlert4(true);
                setRecomendAlert5(true);

                APIList.getCompAlerts({
                    payload: { managerPlanningMerit: Number(temp?.managerRecommendation), recommendedMerit: temp?.recommendedFixedSalaryincrement },
                    companyId: employeeDetails?.company?.id, // flag or err
                })
                    .then((res) => {
                        temp["flag"] = res?.data?.flag ? res?.data?.flag : null;
                        temp["flagReason"] = res?.data?.reason ? res?.data?.reason : null;
                        temp["flagId"] = res?.data?.id ? res?.data?.id : null;
                        setFlag(res?.data?.flag ? res?.data?.flag : null);
                        setIsRecomendErr(false);
                        setRecomendAlert1(false);
                    }).catch((err) => {
                        setIsRecomendErr(true);
                        toast.error(
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                &nbsp;&nbsp;{err?.title}
                            </div>
                        )
                        setRecomendAlert1(false);
                    })
    
                    APIList.getCompAlerts({
                        payload: { newTcCompaRatio: temp?.newTCCompaRatio },
                        companyId: employeeDetails?.company?.id, // flag
                    })
                        .then((res) => {
                            temp["tcCompaFlag"] = res?.data?.flag ? res?.data?.flag : null;
                            temp["tcCompaFlagReason"] = res?.data?.reason ? res?.data?.reason : null;
                            temp["tcCompaFlagId"] = res?.data?.id ? res?.data?.id : null;
                            setTcCompaFlag(res?.data?.flag ? res?.data?.flag : null);
                            setRecomendAlert2(false);
                        }).catch((err) => {
                            toast.error(
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                    &nbsp;&nbsp;{err?.title}
                                </div>
                            )
                            setRecomendAlert2(false);
                        })
        
                    APIList.getCompAlerts({
                        payload: { incrementAmount: temp?.finalIncrementAmount, fixedSalaryCR: temp?.baseValue, promotion: temp?.promotionStatus === "Yes" },
                        companyId: employeeDetails?.company?.id, // err
                    })
                        .then((res) => {
                            setWithPromoErr(res?.data?.flag ? res?.data?.flag : null);
                            setRecomendAlert3(false);
                        }).catch((err) => {
                            setWithPromoErr(true);
                            toast.error(
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                    &nbsp;&nbsp;{err?.title}
                                </div>
                            )
                            setRecomendAlert3(false);
                        })
    
                    APIList.getCompAlerts({
                        payload: { recommendedVariablePayTarget: temp?.recommendedVariablePayTarget},
                        companyId: employeeDetails?.company?.id, // err
                    })
                        .then((res) => {
                            temp["recommendedVariablePayTargetFlag"] = res?.data?.flag ? res?.data?.flag : null;
                            temp["recommendedVpTargetFlagReason"] = res?.data?.reason ? res?.data?.reason : null;
                            temp["recommendedVpTargetFlagId"] = res?.data?.id ? res?.data?.id : null;
                            setRecommendedVariablePayTargetFlag(res?.data?.flag ? res?.data?.flag : null);
                            setRecomendAlert4(false);
                        }).catch((err) => {
                            toast.error(
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                    &nbsp;&nbsp;{err?.title}
                                </div>
                            )
                            setRecomendAlert4(false);
                        })
                    APIList.getCompAlerts({
                        payload: { recommendedVariablePayTarget: temp?.recommendedVariablePayTarget, newFixedSalary: temp?.newFixedSalary },
                        companyId: employeeDetails?.company?.id, // err
                    })
                        .then((res) => {
                            temp["newFixedSalaryFlag"] = res?.data?.flag ? res?.data?.flag : null;
                            temp["newFixedSalaryFlagReason"] = res?.data?.reason ? res?.data?.reason : null;
                            temp["newFixedSalaryFlagId"] = res?.data?.id ? res?.data?.id : null;
                            setNewFixedSalaryFlag(res?.data?.flag ? res?.data?.flag : null);
                            setRecomendAlert5(false);
                        }).catch((err) => {
                            toast.error(
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                    &nbsp;&nbsp;{err?.title}
                                </div>
                            )
                            setRecomendAlert5(false);
                        })
    
            }
    
            if (e.target.name === "managerRecommendedVariablePayTarget") {
                setVPAlert(true);
                setVPAlert1(true);
    
                APIList.getCompAlerts({
                    payload: { newTcCompaRatio: temp?.newTCCompaRatio },
                    companyId: employeeDetails?.company?.id, // flag
                })
                    .then((res) => {
                        temp["tcCompaFlag"] = res?.data?.flag ? res?.data?.flag : null;
                        temp["tcCompaFlagReason"] = res?.data?.reason ? res?.data?.reason : null;
                        temp["tcCompaFlagId"] = res?.data?.id ? res?.data?.id : null;
                        setTcCompaFlag(res?.data?.flag ? res?.data?.flag : null);
                        setVPAlert(false);
                    }).catch((err) => {
                        toast.error(
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                &nbsp;&nbsp;{err?.title}
                            </div>
                        )
                        setVPAlert(false);
                    })

                APIList.getCompAlerts({
                    payload: { residualVariablePay: temp?.managerRecommendedVariablePayTarget, oldVariablePay: temp?.currentYearSalaryHead?.filter(x => x?.type === "Target")[0]?.amount },
                    companyId: employeeDetails?.company?.id, // flag
                })
                    .then((res) => {
                        temp["newVariablePayFlag"] = res?.data?.flag ? res?.data?.flag : null;
                        temp["newVariablePayFlagReason"] = res?.data?.reason ? res?.data?.reason : null;
                        temp["newVariablePayFlagId"] = res?.data?.id ? res?.data?.id : null;
                        setTcCompaFlag(res?.data?.flag ? res?.data?.flag : null);
                        setVPAlert1(false);
                    }).catch((err) => {
                        toast.error(
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                                &nbsp;&nbsp;{err?.title}
                            </div>
                        )
                        setVPAlert1(false);
                    })
            }
        }

        setActiveRow(temp);
    }

    const saveCompensation = (row) => {
        setSaveLoader(true);
        APIList.saveCompensation({
            managerId: employeeDetails?.id,
            employeeId: activeRow?.employeeId,
            companyId: employeeDetails?.company?.id,
            managerRecommendation: activeRow?.managerRecommendation,
            finalIncrementAmount: activeRow?.finalIncrementAmount, // base%
            newFixedSalary: activeRow?.newFixedSalary, // base + final
            recommendedVariablePayTarget: activeRow?.recommendedVariablePayTarget,
            managerRecommendedVariablePayTarget: activeRow?.managerRecommendedVariablePayTarget,
            newTotalCompensation: activeRow?.newTotalCompensation, // new sal head sum + var pay
            newFixedCompaRatio: activeRow?.newFixedCompaRatio, //newfix sal /fix market mid
            newTCCompaRatio: activeRow?.newTCCompaRatio, //new total comp / tc market mid
            promotionStatus: activeRow?.promotionStatus,
            newDesignation: activeRow?.newDesignation,
            range: activeTab,
            flag: activeRow?.flag,
            flagId: activeRow?.flagId,
            tcCompaFlag: activeRow?.tcCompaFlag,
            tcCompaFlagId: activeRow?.tcCompaFlagId,
            newFixedSalaryFlag: activeRow?.newFixedSalaryFlag,
            newFixedSalaryFlagId: activeRow?.newFixedSalaryFlagId,
            recommendedVariablePayTargetFlag: activeRow?.recommendedVariablePayTargetFlag,
            recommendedVpTargetFlagId: activeRow?.recommendedVpTargetFlagId,
            newVariablePayFlag: activeRow?.newVariablePayFlag,
            newVariablePayFlagId: activeRow?.newVariablePayFlagId,
        })
            .then((res) => {
                getChartData();
                getData(currentPage, filterPayload);
                console.log("resss ------>", res)
                if( !isEmptyNullUndefined(res?.data?.vpBudgetWarning)){
                    toast.error(
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                            &nbsp;&nbsp;{res?.data?.vpBudgetWarning}
                        </div>
                    )
                }
                if( !isEmptyNullUndefined(res?.data?.fixedBudgetWarning)){
                    toast.error(
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                            &nbsp;&nbsp;{res?.data?.fixedBudgetWarning}
                        </div>
                    )
                }
            }).catch((err) => {
                toast.error(
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                    </div>
                )
                setSaveLoader(false);
            })
    }

    const handleSelectViewType = (e) => {
        setViewTableType(e.target.value); 
        if(e.target.value === "Block planning View") {
            setSelectedConfigColumns(blockColumns);
        } else {
            setSelectedConfigColumns(columns);
        }        
    }

    const handleApproveAll = () => {
        setApproveLoader(true);
        APIList.approveAllCompPlan({
            companyId: employeeDetails?.company?.id,
            managerId: employeeDetails?.id,
            range: activeTab
        }).then((res) => {

                setOpenDialog(true);
                setApproveLoader(false);
                getData(0, {});
                getChartData();
           
        }).catch((err) => {
            toast.error(
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.message}
                </div>
            )
            setApproveLoader(false);
        })
    }

    const getIsElligibleToRecommendPercent = () => {
        activeRow?.currentYearSalaryHead?.forEach((x) => {
            if(x?.type === "fixedSalary"){
                return x?.isEligibilityForCalculation;
            }
        })
    }

    const handleDownloadReport = async(e) => {
        setDownloadLoader(true);
        // axios.get(`${BASE_URL}/services/benevolvemedibuddy/api/feedback-report/send-report/download/by-range?email=null&cycleId=${annualCycle?.id}&queryId=75b59bb0-e3e6-4d42-bc83-b72415171685&companyId=${employeeDetails?.company?.id}&reportId=${e.target.value?.id}&managerId=${employeeDetails?.id}&range=${activeTab}`,{headers: {
        axios.post(`${BASE_URL}/services/benevolvemedibuddy/api/feedback-report/download/filter`,
        {
            employeeEligibilityDTO: filterPayload,
            filterData: Object.keys(filterPayload).length !== 0 , // Default is false. Set to true if filtering is required.
            reportId: e.target.value?.id,
            email: null,
            queryId: null,
            cycleId: annualCycle?.id,
            range: activeTab,
            companyId: employeeDetails?.company?.id,
            managerId: employeeDetails?.id
    },
        {headers: {
            'Authorization': `Bearer ${userToken?.id_token}`,
            'Content-Type': 'application/json',
          },  'responseType': 'arraybuffer'      
        }).then((response) => 
        {
             const blob = new Blob([response?.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
           
          // Trigger the download
          saveAs(blob, `${e.target.value.reportName}.xlsx`);
          setDownloadLoader(false);
            
        })
        .catch((err) => {
                 toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDownloadLoader(false);
        });    
     
      }

      const handleOpenStatistics = () => {
              setOpenStatistics(!openStatistics)
    }

    const excludeRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
        if (excludeRef.current && !excludeRef.current.contains(event.target)) {
            if (!isEmptyNullUndefined(activeRow)) {
                toast.error(
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp; Please finish editing for {activeRow?.name}
                    </div>
                )
            }
        }
        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const totalCompNames = ['Total Compensation View', 'Block planning View'];
    const perAnnumNames = ['Per Annum', 'Monthly'];
    const perPageItemNames = [10, 20, 50];

    const handleViewCandidate = (name) => {
        if(employeeDetails?.permissions?.includes("for-demo-purpose")){
            history.push({pathname: `/view-candidate-in-compensation`, state: {name: name}})
        } 
    }

    return (
        <div className="compensation">

            {/*----- Budget Dashboard -----*/}
            {/* <div className="top-sction-wrapper">
                <Typography className="budget-dashboard">{"Budget Dashboard"}</Typography>

                <div className="top-dropdown">
                    <FormControl size="small"
                        sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none"
                            }
                        }}>
                        <InputLabel id="demo-simple-select-label">Budget Type</InputLabel>
                        <Select
                            fullWidth
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={budgetGraphType}
                            label="Per Annum"
                            onChange={(e) => setBudgetGraphType(e.target.value)}
                        >
                            {perAnnumNames?.map((report) => {
                                return (
                                    <MenuItem value={report}>{report}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div> */}

            {/*----- Progress Bar -----*/}
            {/* <div className="chart-bg">
                <div className="outer-layout">
                    
                    <div className="grid-column">
                        <Typography className="progress-title">{"Total Budget Summary"}</Typography>

                        <div class="image-container">
                            <img src={Progress1} alt="Progress1" />
                            <div className="overlay-div">
                                <Typography className="price-text">{"1,00,000"}</Typography>
                                <Typography className="total-amt">{"Total Amount"}</Typography>
                            </div>
                        </div>

                        <div className="progress-bottom-div">
                            <div>
                                <div className="d-flex">
                                    <div className="progress-point bg-blue"></div>
                                    <Typography className="progress-count">60,000</Typography>
                                    <Typography className="progress-persend">(60%)</Typography>
                                </div>
                                <Typography className="available-txt">{"Available"}</Typography>
                            </div>
                            <div>
                                <div className="d-flex">
                                    <div className="progress-point bg-light-green"></div>
                                    <Typography className="progress-count">40,000</Typography>
                                    <Typography className="progress-persend">(40%)</Typography>
                                </div>
                                <Typography className="available-txt">{"Utilized"}</Typography>
                            </div>
                        </div>
                    </div>

                    
                    <div className="grid-column">
                        <div className="vertical-border"></div>
                    </div>

                    
                    <div className="grid-column">
                        <Typography className="progress-title">{"Fixed Salary Budget"}</Typography>

                        <div class="image-container">
                            <img src={Progress2} alt="Progress2" />
                            <div className="overlay-div">
                                <Typography className="price-text">{"1,50,000"}</Typography>
                                <Typography className="total-amt">{"Total"}</Typography>
                            </div>
                        </div>

                        <div className="progress-bottom-div">
                            <div>
                                <div className="d-flex">
                                    <div className="progress-point bg-blue"></div>
                                    <Typography className="progress-count">90,000</Typography>
                                    <Typography className="progress-persend">(60%)</Typography>
                                </div>
                                <Typography className="available-txt">{"Available"}</Typography>
                            </div>
                            <div>
                                <div className="d-flex">
                                    <div className="progress-point bg-light-red"></div>
                                    <Typography className="progress-count">60,000</Typography>
                                    <Typography className="progress-persend">(40%)</Typography>
                                </div>
                                <Typography className="available-txt">{"Utilized"}</Typography>
                            </div>
                        </div>
                    </div>

                   
                    <div className="grid-column">
                        <div className="vertical-border"></div>
                    </div>

                    
                    <div className="grid-column">
                        <Typography className="progress-title">{"Variable Target Budget"}</Typography>

                        <div class="image-container">
                            <img src={Progress3} alt="Progress3" />
                            <div className="overlay-div">
                                <Typography className="price-text">{"50,000"}</Typography>
                                <Typography className="total-amt">{"Total"}</Typography>
                            </div>
                        </div>

                        <div className="progress-bottom-div">
                            <div>
                                <div className="d-flex">
                                    <div className="progress-point bg-blue"></div>
                                    <Typography className="progress-count">35,000</Typography>
                                    <Typography className="progress-persend">(70%)</Typography>
                                </div>
                                <Typography className="available-txt">{"Available"}</Typography>
                            </div>
                            <div>
                                <div className="d-flex">
                                    <div className="progress-point bg-lavender"></div>
                                    <Typography className="progress-count">15,000</Typography>
                                    <Typography className="progress-persend">(30%)</Typography>
                                </div>
                                <Typography className="available-txt">{"Utilized"}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


<div className="acc-wrapper">
        <Stack sx={{
            margin:"1rem 0px",
            "& .MuiAccordion-rounded":{
                // border:"1px solid #dad2d2",
                borderRadius:"10px !important",
                boxShadow:"none"
            }                           
        }}>
            <Accordion
            // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
            expanded={openStatistics} onChange={() => handleOpenStatistics()} data-value={"stats"}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
            sx={{
                width:"100%",   
                fontSize:"1.25rem",
                fontWeight:"600",
                "& .MuiAccordionSummary-content":{
                    width:"100%", 
                    display:"flex", 
                    justifyContent:"space-between",
                }}}
            >
                Budget Dashboard

                <BsChevronDown
                    style={{...accordianSVGstyle ,transform: !openStatistics ? "rotate(0deg)" : "rotate(180deg)"}}
                />          
            </AccordionSummary>
            <AccordionDetails
            sx={{
                fontSize:"1rem"
            }}
            >
                <div className="top-sction-wrapper">
                {/* <Typography className="budget-dashboard">{"Budget Dashboard"}</Typography> */}

                <div className="top-dropdown">
                    <FormControl size="small"
                        sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none"
                            }
                        }}>
                        <InputLabel id="demo-simple-select-label">Budget Type</InputLabel>
                        <Select
                            fullWidth
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={budgetGraphType}
                            label="Per Annum"
                            onChange={(e) => setBudgetGraphType(e.target.value)}
                        >
                            {perAnnumNames?.map((report) => {
                                return (
                                    <MenuItem value={report} key={report}>{report}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>
                {
                chartDataLoader ? 
                <div className="d-flex justify-content-center">
                   <CircularProgress size={41} />
                </div>
                :
                <div className="chart-bg">
                    {
                        budgetGraphType === "Per Annum" && 
                            <div className="outer-layout">
                            {/*----- Col 1 -----*/}
                            <div className="grid-column">
                                <Typography className="progress-title">{"Total Budget Summary"}</Typography>
                            <DoughnutChart 
                            label1="Available" 
                            label2="Utilized" 
                            data1={chartData["Fixed Salary"]?.availableBudget + chartData["Variable Pay Eligibility Target"]?.availableBudget} 
                            data2={chartData["Fixed Salary"]?.utilised + chartData["Variable Pay Eligibility Target"]?.utilised} 
                            color1="#2d99fe" 
                            color2="#d87d4b" 
                            percent1={(((chartData["Fixed Salary"]?.availableBudget + chartData["Variable Pay Eligibility Target"]?.availableBudget)/(chartData["Fixed Salary"]?.availableBudget + chartData["Variable Pay Eligibility Target"]?.availableBudget + chartData["Fixed Salary"]?.utilised + chartData["Variable Pay Eligibility Target"]?.utilised)) * 100)?.toFixed(3)} 
                            percent2={(((chartData["Fixed Salary"]?.utilised + chartData["Variable Pay Eligibility Target"]?.utilised)/(chartData["Fixed Salary"]?.availableBudget + chartData["Variable Pay Eligibility Target"]?.availableBudget + chartData["Fixed Salary"]?.utilised + chartData["Variable Pay Eligibility Target"]?.utilised)) * 100)?.toFixed(3)} />
                            </div>
        
                            {/*----- Vertical Border -----*/}
                            {/* <div className="grid-column">
                                <div className="vertical-border"></div>
                            </div> */}
                            
                                                {/*----- Col 2 -----*/}
                            <div className="grid-column middle">
                                <Typography className="progress-title">{"Fixed Salary Budget"}</Typography>
                                <DoughnutChart label1="Available" label2="Utilized" data1={chartData["Fixed Salary"]?.availableBudget} data2={chartData["Fixed Salary"]?.utilised} color1="#2d99fe" color2="#d87d4b" percent1={chartData["Fixed Salary"]?.availableBudgetPercent?.toFixed(3)} percent2={chartData["Fixed Salary"]?.utilisedPercent?.toFixed(3)} />                        
                            </div>
        
                            {/*----- Vertical Border -----*/}
                            {/* <div className="grid-column">
                                <div className="vertical-border"></div>
                            </div> */}
        
                            {/*----- Col 3 -----*/}
                            <div className="grid-column">
                                <Typography className="progress-title">{"Variable Target Budget"}</Typography>
                                <DoughnutChart label1="Available" label2="Utilized" data1={chartData["Variable Pay Eligibility Target"]?.availableBudget} data2={chartData["Variable Pay Eligibility Target"]?.utilised} color1="#2d99fe" color2="#d87d4b" percent1={chartData["Variable Pay Eligibility Target"]?.availableBudgetPercent?.toFixed(3)} percent2={chartData["Variable Pay Eligibility Target"]?.utilisedPercent?.toFixed(3)} />
                            </div>
        
                            </div>
                    }
                    {
                        budgetGraphType === "Monthly" && 
                            <div className="outer-layout">
                            {/*----- Col 1 -----*/}
                            <div className="grid-column">
                                <Typography className="progress-title">{"Total Budget Summary"}</Typography>
                            <DoughnutChart 
                            label1="Available" 
                            label2="Utilized" 
                            data1={((chartData["Fixed Salary"]?.availableBudget + chartData["Variable Pay Eligibility Target"]?.availableBudget)/12).toFixed(3)} 
                            data2={((chartData["Fixed Salary"]?.utilised + chartData["Variable Pay Eligibility Target"]?.utilised)/12).toFixed(3)} 
                            color1="#2d99fe" 
                            color2="#d87d4b" 
                            percent1={(((chartData["Fixed Salary"]?.availableBudget + chartData["Variable Pay Eligibility Target"]?.availableBudget)/(chartData["Fixed Salary"]?.availableBudget + chartData["Variable Pay Eligibility Target"]?.availableBudget + chartData["Fixed Salary"]?.utilised + chartData["Variable Pay Eligibility Target"]?.utilised)) * 100)?.toFixed(3)} 
                            percent2={(((chartData["Fixed Salary"]?.utilised + chartData["Variable Pay Eligibility Target"]?.utilised)/(chartData["Fixed Salary"]?.availableBudget + chartData["Variable Pay Eligibility Target"]?.availableBudget + chartData["Fixed Salary"]?.utilised + chartData["Variable Pay Eligibility Target"]?.utilised)) * 100)?.toFixed(3)} />
                            </div>
        
                            {/*----- Vertical Border -----*/}
                            {/* <div className="grid-column">
                                <div className="vertical-border"></div>
                            </div> */}
                            
                                                {/*----- Col 2 -----*/}
                            <div className="grid-column middle">
                                <Typography className="progress-title">{"Fixed Salary Budget"}</Typography>
                                <DoughnutChart label1="Available" label2="Utilized" data1={(chartData["Fixed Salary"]?.availableBudget/12).toFixed(3)} data2={(chartData["Fixed Salary"]?.utilised/12).toFixed(3)} color1="#2d99fe" color2="#d87d4b" percent1={chartData["Fixed Salary"]?.availableBudgetPercent?.toFixed(3)} percent2={chartData["Fixed Salary"]?.utilisedPercent?.toFixed(3)} />                        
                            </div>
        
                            {/*----- Vertical Border -----*/}
                            {/* <div className="grid-column">
                                <div className="vertical-border"></div>
                            </div> */}
        
                            {/*----- Col 3 -----*/}
                            <div className="grid-column">
                                <Typography className="progress-title">{"Variable Target Budget"}</Typography>
                                <DoughnutChart label1="Available" label2="Utilized" data1={(chartData["Variable Pay Eligibility Target"]?.availableBudget/12).toFixed(3)} data2={(chartData["Variable Pay Eligibility Target"]?.utilised/12).toFixed(3)} color1="#2d99fe" color2="#d87d4b" percent1={chartData["Variable Pay Eligibility Target"]?.availableBudgetPercent?.toFixed(3)} percent2={chartData["Variable Pay Eligibility Target"]?.utilisedPercent?.toFixed(3)} />
                            </div>
        
                            </div>
                    }
                    
                </div>
            }
                
            </AccordionDetails>
            </Accordion>
        </Stack>
</div>



            {/*----- My Team -----*/}
            <div className="tabs-outer">

                <div className="tabs">
                    <button className={`tab ${activeTab === "my Team" ? "active" : ""}`} onClick={() => { !dataLoader && setActiveTab("my Team") }}>My Team</button>
                    <button className={`tab ${activeTab === "my Org" ? "active" : ""}`} onClick={() => { !dataLoader && setActiveTab("my Org") }}>My Org</button>
                    {
                        employeeDetails?.permissions?.includes("compensation-entire-org") && <button className={`tab ${activeTab === "Entire Org" ? "active" : ""}`} onClick={() => { !dataLoader && setActiveTab("Entire Org") }}>Entire Org</button>
                    }                    
                </div>

                {
                        (activeTab === "Entire Org") &&
                        <div className="report-download">
                        <div className="report-btn-div">
                            <div className="bottom">
                                {
                                    downloadLoader ? 
                                    <>
                                    Downloading... <CircularProgress size={29} />
                                    </>
                                    :
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label">Download report</InputLabel>
                                    <Select
                                    size="small"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={null}
                                    label="Download report"
                                    onChange={handleDownloadReport}
                                    >
                                        {
                                            reportList?.map((report) => {
                                                return(
                                                    <MenuItem value={report} key={report}>{report?.reportName}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                }
                                
                            </div>
                        </div>
                    
                </div>
            }
                {
                        employeeDetails?.permissions?.includes("functional-leader") && (activeTab !== "Entire Org") &&
                        <div className="report-download">
                        <div className="report-btn-div">
                            <div className="bottom">
                                {
                                    downloadLoader ? 
                                    <>
                                    Downloading... <CircularProgress size={29} />
                                    </>
                                    :
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label">Download report</InputLabel>
                                    <Select
                                    size="small"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={null}
                                    label="Download report"
                                    onChange={handleDownloadReport}
                                    >
                                        {
                                            reportList?.map((report) => {
                                                return(
                                                    <MenuItem value={report} key={report}>{report?.reportName}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                }
                                
                            </div>
                        </div>
                    
                </div>
            }

                

            </div>

            {/*----- Table -----*/}
            <div className="table-bg">
                <div className="table-top-layout">
                    {/*----- Search Bar -----*/}
                    <div className="table-top-column">
                        <TextField
                            id="input-with-icon-textfield"
                            placeholder="Search..."
                            // size="small"
                            fullWidth
                            className="search-bar"
                            onChange={(e) => setSearch(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IoIosSearch />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: 'none',
                                    },
                                },
                            }}
                        />
                    </div>

                    {/*----- Table Fillter -----*/}
                    <div className="table-top-column">
                        <div className="table-filter-dropdown">
                            <button className="filter-tab" onClick={() => setIsOpenFilter(true)}>
                                <img src={filterIcon} alt="filter" />
                                Filter                                
                            </button>
                        </div>
                    </div>

                    {/*----- Gap -----*/}
                    <div className="table-top-column"></div>

                    {/*----- Total Comp. View Dropdown -----*/}
                    <div className="table-top-column">
                        <FormControl className="w-100 mt-12" size="small"
                            sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #00425A",
                                    borderRadius: "10px",
                                },
                                "& .MuiInputBase-root": {
                                    fontSize: "12px",
                                    color: "#00425A",
                                    fontWeight: 400,
                                }
                            }}>
                            <InputLabel id="demo-simple-select-label" sx={{ fontSize: "12px", color: "#00425A", fontWeight: 400 }}>View Type</InputLabel>
                            <Select
                                fullWidth
                                size="small"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={viewTableType}
                                label="Total Comp. View"
                                onChange={(e) => handleSelectViewType(e)}
                                sx={{ fontSize: "12px", color: "#00425A", fontWeight: 400 }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            "& .MuiMenuItem-root": {
                                                fontSize: "12px",
                                                color: "#00425A",
                                                fontWeight: 400,
                                            }
                                        }
                                    }
                                }}
                            >
                                {totalCompNames?.map((report) => {
                                    return (
                                        <MenuItem value={report} key={report}>{report}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>

                    {/*----- Config. Columns Dropdown -----*/}
                    <div className="table-top-column">
                        <FormControl className="w-100 mt-12" size="small"
                            sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #00425A",
                                    borderRadius: "10px",
                                },
                                "& .MuiInputBase-root": {
                                    fontSize: "12px",
                                    color: "#00425A",
                                    fontWeight: 400,
                                }
                            }}>
                            <InputLabel id="demo-simple-select-label" sx={{ fontSize: "12px", color: "#00425A", fontWeight: 400 }}>Config. Columns</InputLabel>
                            <Select
                                fullWidth
                                size="small"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Config. Columns"
                                multiple
                                value={selectedConfigColumns}
                                onChange={(e) => handleConfigColumnsChange(e)}
                                renderValue={(selected) => selected.join(', ')}
                                sx={{ fontSize: "12px", color: "#00425A", fontWeight: 400 }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            "& .MuiMenuItem-root": {
                                                fontSize: "12px",
                                                color: "#00425A",
                                                fontWeight: 400,
                                            }
                                        }
                                    }
                                }}
                            >
                                {/* <div> */}
                                {
                                    viewTableType === "Block planning View" ?

                                blockColumns?.map((report) => {
                                    return (
                                        <MenuItem key={report} value={report}>
                                            <Checkbox checked={selectedConfigColumns?.includes(report)} />
                                            <ListItemText primary={report} />
                                        </MenuItem>
                                    )
                                })
                                :
                                columns?.map((report) => {
                                    return (
                                        <MenuItem key={report} value={report}>
                                            <Checkbox checked={selectedConfigColumns?.includes(report)} />
                                            <ListItemText primary={report} />
                                        </MenuItem>
                                    )
                                })
                            }

                                {/* <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: "1px solid #D9D9D9" }}> */}
                                <MenuItem key={"All"} value={"All"}>
                                    <Checkbox checked={viewTableType === "Block planning View" ? blockColumns?.length === selectedConfigColumns?.length : columns?.length === selectedConfigColumns?.length} />
                                    <ListItemText primary={"Select All"} />
                                </MenuItem>

                                {/* <button style={{ width: "95px", height: "40px", backgroundColor: "#00425A", borderRadius: "10px", border: "none", color: "#F9F9F9", fontSize: "14px", fontWeight: "500", textAlign: "center", marginTop: "10px", marginRight: "15px" }}>{"Apply"}</button> */}
                                {/* </div> */}
                                {/* </div> */}
                            </Select>
                        </FormControl>
                    </div>

                    {/*----- Expand All CheckBox -----*/}
                    <div className="table-top-column expand-all" >
                        <Checkbox
                            checked={viewTableType === "Block planning View" ? blockColumns?.length === selectedConfigColumns?.length : columns?.length === selectedConfigColumns?.length}
                            // disabled={!selectedConfigColumns?.length === 0}
                            onChange={() => setSelectedConfigColumns(viewTableType === "Block planning View" ? blockColumns : columns)}
                        />
                        Expand All
                    </div>
                </div>

             {
                (dataLoader && !saveLoader) ?
                                   
                <table style={{padding: "1rem", marginTop: "2rem"}}>
                    <tbody>
                        {
                            [...Array(10).keys()].map((y) => {
                                return(
                                        <tr key={y}>
                                            {
                                                blockColumns?.map((x) => {
                                                    return(
                                                        <td style={{padding: "0.5rem"}} key={x}>
                                                            <Skeleton width={100} />
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>

                    :
                    
                    isEmptyNullUndefined(totalCompData) ?
                    <div className="no-data">No data found</div>
                    :

                    <div className="table-container-sample">
                    {
                        viewTableType === "Total Compensation View" ? 
                        <table>
                        <thead>
                            
                        <tr>
                                <th className={`sticky`}>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Emp Code</span>
                                    </div>    
                                </th>
                                <th className={`sticky2`}>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Emp Name</span>
                                    </div>    
                                </th>
                            {
                                columns?.map((column,index) => {
                                    return(
                                            
                                                selectedConfigColumns?.includes(column) &&
                                                <th className={`${(column === "Emp Code") && "sticky"} ${((column === "Emp Name")) && "sticky2"}`}>
                                                    <div className="col-head-outer">
                                                    {((column === "Emp Code") || (column === "Emp Name")) ? "" : <img className="mr-5" src={!selectedConfigColumns?.includes(column) ? Plus : Minus} alt="Minus" onClick={() =>  handleCollapse(column)} />} 
                                                        <span className="table-heading">{!selectedConfigColumns?.includes(column) ? "" : column}</span>
                                                    </div>    
                                                </th>
                                            
                                    )
                                })
                            }

                            <th className="sticky-right">
                                <div className="col-head-outer">
                                    <span className="table-heading">Action</span>
                                </div>    
                            </th>
                            
                        </tr>
                        </thead>
                        {
                                            <tbody>
                                                {
                                                totalCompData.map((row) => (
                                                    <tr key={row?.empCode} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    

                                                                <td className="table-value sticky" component="th" scope="row">{row?.empCode}</td>
                                                    { 
                                                            (row?.employeeId === activeRow?.employeeId) ?

                                                                <td className="table-value-emp-name sticky2" align="left">
                                                                    <span onClick={() => handleViewCandidate(activeRow?.name)} style={{cursor: "pointer"}}>{activeRow?.name}&nbsp;</span>
                                                                    {/* {console.log("alertObject?.flag",flag)} */}
                                                                    {/* {
                                                                        (flag || activeRow?.flag || tcCompaFlag || activeRow?.tcCompaFlag || newFixedSalaryFlag || activeRow?.newFixedSalaryFlag || recommendedVariablePayTargetFlag || activeRow?.recommendedVariablePayTargetFlag) &&
                                                                        <FaFlag style={{ color: tcCompaFlag?.toLowerCase() || flag?.toLowerCase() || activeRow?.tcCompaFlag?.toLowerCase() || activeRow?.flag?.toLowerCase() || newFixedSalaryFlag?.toLowerCase() || activeRow?.newFixedSalaryFlag?.toLowerCase() || recommendedVariablePayTargetFlag?.toLowerCase() || activeRow?.recommendedVariablePayTargetFlag?.toLowerCase()}} />
                                                                    } */}
                                                                    <div className="all-flags">
                                                                        {
                                                                            (activeRow?.flag) &&
                                                                            <MediTooltip msg={activeRow?.flagReason}>
                                                                            <FaFlag style={{ color: activeRow?.flag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                        {
                                                                            (activeRow?.tcCompaFlag) &&
                                                                            <MediTooltip msg={activeRow?.tcCompaFlagReason}>
                                                                            <FaFlag style={{ color: activeRow?.tcCompaFlag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                        {
                                                                            (activeRow?.newFixedSalaryFlag) &&
                                                                            <MediTooltip msg={activeRow?.newFixedSalaryFlagReason}>
                                                                            <FaFlag style={{ color: activeRow?.newFixedSalaryFlag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                        {
                                                                            (activeRow?.recommendedVariablePayTargetFlag) &&
                                                                            <MediTooltip msg={activeRow?.recommendedVpTargetFlagReason}>
                                                                            <FaFlag style={{ color: activeRow?.recommendedVariablePayTargetFlag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                        {
                                                                            (activeRow?.newVariablePayFlag) &&
                                                                            <MediTooltip msg={activeRow?.newVariablePayFlagReason}>
                                                                            <FaFlag style={{ color: activeRow?.newVariablePayFlag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                    </div>
                                                                </td>

                                                            :

                                                                <td className="table-value-emp-name sticky2" align="left">
                                                                    <span onClick={() => handleViewCandidate(row?.name)} style={{cursor: "pointer"}}>{row?.name}&nbsp;</span>
                                                                    {/* {
                                                                        (row?.flag || row?.tcCompaFlag || row?.newFixedSalaryFlag || row?.recommendedVariablePayTargetFlag) &&
                                                                        <FaFlag style={{ color: row?.tcCompaFlag?.toLowerCase() || row?.flag?.toLowerCase() || row?.newFixedSalaryFlag?.toLowerCase() || row?.recommendedVariablePayTargetFlag?.toLowerCase() }} />
                                                                    } */}
                                                                    <div className="all-flags">
                                                                        {
                                                                            (row?.flag) &&
                                                                            <MediTooltip msg={row?.flagReason}>
                                                                            <FaFlag style={{ color: row?.flag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                        {
                                                                            (row?.tcCompaFlag) &&
                                                                            <MediTooltip msg={row?.tcCompaFlagReason}>
                                                                            <FaFlag style={{ color: row?.tcCompaFlag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                        {
                                                                            (row?.newFixedSalaryFlag) &&
                                                                            <MediTooltip msg={row?.newFixedSalaryFlagReason}>
                                                                            <FaFlag style={{ color: row?.newFixedSalaryFlag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                        {
                                                                            (row?.recommendedVariablePayTargetFlag) &&
                                                                            <MediTooltip msg={row?.recommendedVpTargetFlagReason}>
                                                                            <FaFlag style={{ color: row?.recommendedVariablePayTargetFlag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                        {
                                                                            (row?.newVariablePayFlag) &&
                                                                            <MediTooltip msg={row?.newVariablePayFlagReason}>
                                                                            <FaFlag style={{ color: row?.newVariablePayFlag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                    </div>
                                                                </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Date Of Joining") &&
                                                            <td className="table-value " align="left">{row?.dateOfJoining ? getFormattedDate(row?.dateOfJoining, dateFormat) : ""}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Function") &&
                                                            <td className="table-value" align="left">{row?.function}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Sub Function") &&
                                                            <td className="table-value" align="left">{row?.subFunction}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Designation") &&
                                                            <td className="table-value" align="left">{row?.designation}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Level") &&
                                                            <td className="table-value" align="left">{row?.employeeLevel}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Location") &&
                                                            <td className="table-value" align="left">{row?.location}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Cost Center") &&
                                                            <td className="table-value" align="left">{row?.costCenter}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Job code") &&
                                                            <td className="table-value" align="left">{row?.jobCode}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Manager Emp Code") &&
                                                            <td className="table-value" align="left">{row?.managerEmpCode}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Manager Name") &&
                                                            <td className="table-value" align="left">{row?.managerName}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Promotion Status") && (activeTab === "Entire Org") &&
                                                            row?.employeeId === activeRow?.employeeId ?
                                                            <td className="table-value" align="left">

                                                                {/* {row?.promotionStatus} */}

                                                                <FormControl sx={{ width: "6rem" }}>
                                                                    {/* <InputLabel id="demo-simple-select-label" sx={{fontSize:"0.9rem"}}>Select</InputLabel> */}
                                                                    <InputLabel id="demo-simple-select-filled-label" sx={{fontSize:"0.9rem"}}>Select</InputLabel>
                                                                    <Select
                                                                        size="small"
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={activeRow?.promotionStatus}
                                                                        label="Promotion"
                                                                        name="promotionStatus"
                                                                        onChange={handleChangeComp}
                                                                    >
                                                                        <MenuItem value="Yes">Yes</MenuItem>
                                                                        <MenuItem value="No">No</MenuItem>
                                                                    </Select>
                                                                </FormControl>

                                                                &nbsp;
                                                                {
                                                                    (row?.newDesignationFlag) &&
                                                                    <FaFlag style={{ color: row?.newDesignationFlag?.toLowerCase() }} />
                                                                }
                                                            </td>
                                                            :
                                                            selectedConfigColumns?.includes("Promotion Status") && 
                                                            <td className="table-value" align="left">

                                                                {row?.promotionStatus}

                                                                &nbsp;
                                                                {
                                                                    (row?.newDesignationFlag) &&
                                                                    <FaFlag style={{ color: row?.newDesignationFlag?.toLowerCase() }} />
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("New Designation") && (activeTab === "Entire Org") &&
                                                            row?.employeeId === activeRow?.employeeId ?
                                                            
                                                            <td className="table-value" align="left">
                                                                <TextField 
                                                                    size="small"
                                                                    id="filled-basic" 
                                                                    label="New Designation" 
                                                                    // variant="filled" 
                                                                    name="newDesignation"
                                                                    value={activeRow?.newDesignation}
                                                                    onChange={handleChangeComp}
                                                                />
                                                            </td>
                                                            :
                                                            selectedConfigColumns?.includes("New Designation") && 
                                                            <td className="table-value" align="left">{row?.newDesignation}</td>
                                                        }
                                                        {
                                                            row?.ratings?.map((item, index) => {
                                                                return(
                                                                    selectedConfigColumns?.includes(Object.keys(item)[0]) &&
                                                                    <td className="table-value" align="left">
                                                                        {
                                                                            // item[Object.keys(item)[0]]?.map((x) =>
                                                                                item[Object.keys(item)[0]]?.map((y, i) => {
                                                                                    return (
                                                                                        <span>{y?.trendingRating}{((item[Object.keys(item)[0]]?.length - 1) !== i) ? (y?.trendingRating && "-") : ""}</span>
                                                                                    )
                                                                                })
                                                                            // )
                                                                        }
                                                                    </td>
                                                                )
                                                            })
                                                        }
                                                        {/* {
                                                            selectedConfigColumns?.includes("Performance Rating (Combined Rating) 2021-22") &&
                                                            <td className="table-value" align="left">
                                                                {
                                                                    row?.ratings?.map((x) =>
                                                                        x["2021-2022"]?.map((y, i) => {
                                                                            return (
                                                                                <span>{y?.trendingRating}{((x["2021-2022"]?.length - 1) !== i) ? (y?.trendingRating && "-") : ""}</span>
                                                                            )
                                                                        })
                                                                    )
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Performance Rating (Combined Rating) 2022-23") &&
                                                            <td className="table-value" align="left">
                                                                {
                                                                    row?.ratings?.map((x) =>
                                                                        x["2022-2023"]?.map((y, i) => {
                                                                            return (
                                                                                <span>{y?.trendingRating}{((x["2022-2023"]?.length - 1) !== i) ? (y?.trendingRating && "-") : ""}</span>
                                                                            )
                                                                        })
                                                                    )
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Performance Rating (Combined Rating) 2023-24") &&
                                                            <td className="table-value" align="left">
                                                                {
                                                                    row?.ratings?.map((x) =>
                                                                        x["2024-2025"]?.map((y, i) => {
                                                                            return (
                                                                                <span>{y?.trendingRating}{((x["2024-2025"]?.length - 1) !== i) ? (y?.trendingRating && "-") : ""}</span>
                                                                            )
                                                                        })
                                                                    )
                                                                }
                                                            </td>
                                                        } */}
                                                        {
                                                            selectedConfigColumns?.includes("LBP (Yes/No)") &&
                                                            <td className="table-value" align="left">{!isEmptyNullUndefined(row?.lBP) ? row?.lBP ? "Yes" : "No" : ""}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Last Increment Date") &&
                                                            <td className="table-value" align="left">{row?.lastIncrementDate ? getFormattedDate(row?.lastIncrementDate, dateFormat) : ""}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`No of Hikes ${currentCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">{row?.noOfHikes}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Total Hike % ${currentCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">{CurrencyFormat(row?.totalHike, currency)}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Fixed Salary ${currentCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">{CurrencyFormat(row?.baseValue, currency)}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Variable Pay Target ${currentCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">{CurrencyFormat(row?.currentYearSalaryHead?.filter((x) => x?.type === "Target")[0]?.amount, currency)}</td>
                                                        }
                                                        {/* {
                                                selectedConfigColumns?.includes("Variable Pay Eligibility(2023-24) as on 30th Jun'24") &&
                                                <TableCell className="table-value" align="left">{row?.variablePayEligibility202324ason30thJunto24}</TableCell>
                                            } */}
                                                        {
                                                            selectedConfigColumns?.includes(`Earned Incentives ${currentCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">
                                                                {
                                                                    row?.currentYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "Earned Incentives" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Joining Bonus ${currentCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">
                                                                {
                                                                    row?.currentYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "Joining Bonus" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Retention Bonus ${currentCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">
                                                                {
                                                                    row?.currentYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "Retention Bonus" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Current Vested ESOPs Units ${currentCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">
                                                                {
                                                                    row?.currentYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "Vested ESOPs Units" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Value of Vested ESOPs ${currentCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">
                                                                {
                                                                    row?.currentYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "Value of Vested ESOPs" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Long Term Contribution Bonus ${currentCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">
                                                                {
                                                                    row?.currentYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "Long Term Contribution Bonus" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Benefits ${currentCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">
                                                                {
                                                                    row?.currentYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "Benefits" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Current One-Time Bonus ${currentCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">
                                                                {
                                                                    row?.currentYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "One-Time Bonus" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Total Compensation ${currentCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">
                                                                {
                                                                    row?.currentYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "Total Compensation" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Current Fixed Compa Ratio") &&
                                                            <td className="table-value mid-align" align="left">{row?.currentFixedCompaRatio?.toFixed(3)}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Current TC Compa Ratio") &&
                                                            <td className="table-value mid-align" align="left">{row?.currentTCCompaRatio?.toFixed(3)}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Recommended Fixed Salary increment %") &&
                                                            <td className="table-value mid-align" align="left" style={{color: !isEmptyNullUndefined(row?.flag) ? row?.flag : ""}}>{CurrencyFormat(row?.recommendedFixedSalaryincrement, currency)}</td>
                                                        }
                                                        {
                                                            row?.employeeId === activeRow?.employeeId ?
                                                                selectedConfigColumns?.includes("Manager Recommendation %") &&
                                                                <td className="table-value mid-align" align="left">
                                                                    <TextField
                                                                        size='small'
                                                                        name="managerRecommendation"
                                                                        placeholder={(isEmptyNullUndefined(activeRow?.managerRecommendation) || activeRow?.managerRecommendation === 0) ? "0" : ""}
                                                                        // label="%"
                                                                        type="number"
                                                                        value={(activeRow?.managerRecommendation > 0) && activeRow?.managerRecommendation}
                                                                        onChange={handleChangeComp}
                                                                        inputProps={{ min: 0, max: 100 }}
                                                                        variant="outlined"
                                                                        disabled={saveLoader || getIsElligibleToRecommendPercent()}
                                                                        error={!isEmptyNullUndefined(activeRow?.flag) || isRecomendErr || withPromoErr}
                                                                    />
                                                                    {/* {console.log("alertObject?.isRecomendErr",isRecomendErr)} */}
                                                                </td>
                                                                :
                                                                selectedConfigColumns?.includes("Manager Recommendation %") &&
                                                                <td className="table-value mid-align" align="left" style={{color: !isEmptyNullUndefined(row?.flag) ? row?.flag : ""}}>{row?.managerRecommendation}</td>
                                                        }
                                                        {
                                                            //     row?.employeeId === activeRow?.employeeId ? 
                                                            //     selectedConfigColumns?.includes("Final Increment Amount") &&
                                                            //     <TableCell className="table-value" align="left">    
                                                            //         <TextField
                                                            //         size='small'
                                                            //         name="finalIncrementAmount"
                                                            //         // label="%"
                                                            //         type="number"
                                                            //         value={activeRow?.finalIncrementAmount || 0}
                                                            //         onChange={handleChangeComp}
                                                            //         // inputProps={{ min: 0, max: 100 }}
                                                            //         variant="outlined"
                                                            //     />
                                                            //   </TableCell>
                                                            //     :
                                                            selectedConfigColumns?.includes("Final Increment Amount") &&
                                                            <td className="table-value mid-align" align="left">{(row?.employeeId === activeRow?.employeeId) ? CurrencyFormat(activeRow?.finalIncrementAmount, currency) : CurrencyFormat(row?.finalIncrementAmount, currency)}</td>

                                                        }
                                                        {
                                                            //     row?.employeeId === activeRow?.employeeId ? 
                                                            //     selectedConfigColumns?.includes("New Fixed Salary Jul'24 to Jun'25") &&
                                                            //     <TableCell className="table-value" align="left">    
                                                            //         <TextField
                                                            //         size='small'
                                                            //         name="newFixedSalaryJul24toJun25"
                                                            //         // label="%"
                                                            //         type="number"
                                                            //         value={activeRow?.newFixedSalaryJul24toJun25 || 0}
                                                            //         onChange={handleChangeComp}
                                                            //         // inputProps={{ min: 0, max: 100 }}
                                                            //         variant="outlined"
                                                            //     />
                                                            //   </TableCell>
                                                            //     :
                                                            selectedConfigColumns?.includes(`New Fixed Salary ${nextCompYears}`) &&
                                                            <td className="table-value mid-align" align="left" style={{color: !isEmptyNullUndefined(row?.newFixedSalaryFlag) ? row?.newFixedSalaryFlag : ""}}>{(row?.employeeId === activeRow?.employeeId) ? CurrencyFormat(activeRow?.newFixedSalary, currency) : CurrencyFormat(row?.newFixedSalary, currency)}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`New Incentives ${nextCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">
                                                                {
                                                                    row?.nextYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "Earned Incentives" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`New Joining Bonus ${nextCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">
                                                                {
                                                                    row?.nextYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "Joining Bonus" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`New Retention ${nextCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">
                                                                {
                                                                    row?.nextYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "Retention Bonus" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`New Vested ESOP Units ${nextCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">
                                                                {
                                                                    row?.nextYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "Vested ESOPs Units" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Value of ESOPs Vested ${nextCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">
                                                                {
                                                                    row?.nextYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "Value of Vested ESOPs" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Long Term Contribution Bonus ${nextCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">
                                                                {
                                                                    row?.nextYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "Long Term Contribution Bonus" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Benefits ${nextCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">
                                                                {
                                                                    row?.nextYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "Benefits" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`One Time Bonus ${nextCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">
                                                                {/* {CurrencyFormat(row?.oneTimeBonusJul24toJun25, currency)} */}
                                                                {
                                                                    row?.nextYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "One-Time Bonus" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Recommended Variable Pay Target ${nextCompYears}`) &&
                                                            <td className="table-value mid-align" align="left" style={{color: !isEmptyNullUndefined(row?.newFixedSalaryFlag) ? row?.newFixedSalaryFlag : !isEmptyNullUndefined(row?.recommendedVariablePayTargetFlag) ? row?.recommendedVariablePayTargetFlag : ""}}>
                                                                {
                                                                    row?.employeeId === activeRow?.employeeId ?
                                                                        activeRow?.recommendedVariablePayTarget > 0 ? CurrencyFormat(activeRow?.recommendedVariablePayTarget, currency) : 0
                                                                        :
                                                                        row?.recommendedVariablePayTarget > 0 ? CurrencyFormat(row?.recommendedVariablePayTarget, currency) : 0
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            row?.employeeId === activeRow?.employeeId ?
                                                                selectedConfigColumns?.includes("Manager Recommended Variable Pay Target") &&
                                                                <td className="table-value mid-align" align="left">
                                                                    <TextField
                                                                        size='small'
                                                                        name="managerRecommendedVariablePayTarget"
                                                                        placeholder={(isEmptyNullUndefined(activeRow?.managerRecommendedVariablePayTarget) || activeRow?.managerRecommendedVariablePayTarget > 0) ? "" : "0"}
                                                                        // label="%"
                                                                        type="number"
                                                                        value={(activeRow?.managerRecommendedVariablePayTarget > 0) && activeRow?.managerRecommendedVariablePayTarget}
                                                                        onChange={handleChangeComp}
                                                                        // inputProps={{ min: 0, max: 100 }}
                                                                        variant="outlined"
                                                                        disabled={saveLoader || isEmptyNullUndefined(row?.currentYearSalaryHead?.filter((x) => x?.type === "Target")[0]) || !row?.currentYearSalaryHead?.filter((x) => x?.type === "Target")[0]?.isEligibilityForCalculation}
                                                                    />
                                                                </td>
                                                                :
                                                                selectedConfigColumns?.includes("Manager Recommended Variable Pay Target") &&
                                                                <td className="table-value mid-align" align="left" style={{color: !isEmptyNullUndefined(row?.newVariablePayFlag) ? row?.newVariablePayFlag : ""}}>
                                                                    {row?.managerRecommendedVariablePayTarget > 0 ? CurrencyFormat(row?.managerRecommendedVariablePayTarget, currency) : 0}
                                                                </td>
                                                        }
                                                        {
                                                            //     row?.employeeId === activeRow?.employeeId ? 
                                                            //     selectedConfigColumns?.includes(`New Total Compensation ${nextCompYears}`) &&
                                                            //     <TableCell className="table-value" align="left">    
                                                            //         <TextField
                                                            //         size='small'
                                                            //         name="newTotalCompensationJul24toJun25"
                                                            //         // label="%"
                                                            //         type="number"
                                                            //         value={activeRow?.newTotalCompensationJul24toJun25 || 0}
                                                            //         onChange={handleChangeComp}
                                                            //         // inputProps={{ min: 0, max: 100 }}
                                                            //         variant="outlined"
                                                            //     />
                                                            //   </TableCell>
                                                            //     :
                                                            selectedConfigColumns?.includes(`New Total Compensation ${nextCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">{(row?.employeeId === activeRow?.employeeId) ? (activeRow?.newTotalCompensation > 0 ? CurrencyFormat(activeRow?.newTotalCompensation, currency) : 0) : (row?.newTotalCompensation > 0 ? CurrencyFormat(row?.newTotalCompensation, currency) : 0)}</td>
                                                        }
                                                        {
                                                            //     row?.employeeId === activeRow?.employeeId ? 
                                                            //     selectedConfigColumns?.includes("New Fixed Compa Ratio") &&
                                                            //     <TableCell className="table-value" align="left">    
                                                            //         <TextField
                                                            //         size='small'
                                                            //         name="newFixedCompaRatio"
                                                            //         // label="%"
                                                            //         type="number"
                                                            //         value={activeRow?.newFixedCompaRatio || 0}
                                                            //         onChange={handleChangeComp}
                                                            //         // inputProps={{ min: 0, max: 100 }}
                                                            //         variant="outlined"
                                                            //     />
                                                            //   </TableCell>
                                                            //     :
                                                            selectedConfigColumns?.includes("New Fixed Compa Ratio") &&
                                                            <td className="table-value mid-align" align="left">{(row?.employeeId === activeRow?.employeeId) ? Number(activeRow?.newFixedCompaRatio)?.toFixed(3) : Number(row?.newFixedCompaRatio)?.toFixed(3)}</td>
                                                        }
                                                        {
                                                            //     row?.employeeId === activeRow?.employeeId ? 
                                                            //     selectedConfigColumns?.includes("New TC Compa Ratio") &&
                                                            //     <TableCell className="table-value" align="left">    
                                                            //         <TextField
                                                            //         size='small'
                                                            //         name="newTCCompaRatio"
                                                            //         // label="%"
                                                            //         type="number"
                                                            //         value={activeRow?.newTCCompaRatio || 0}
                                                            //         onChange={handleChangeComp}
                                                            //         // inputProps={{ min: 0, max: 100 }}
                                                            //         variant="outlined"
                                                            //     />
                                                            //   </TableCell>
                                                            //     :
                                                            selectedConfigColumns?.includes("New TC Compa Ratio") &&
                                                            <td className="table-value mid-align" align="left" style={{color: !isEmptyNullUndefined(row?.tcCompaFlag) ? row?.tcCompaFlag : ""}}>{(row?.employeeId === activeRow?.employeeId) ? Number(activeRow?.newTCCompaRatio)?.toFixed(3) : Number(row?.newTCCompaRatio)?.toFixed(3)}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Variable Pay Eligibility ${currentCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">{CurrencyFormat(row?.variablePayEligibility, currency)}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Variable Payout Amount ${currentCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">{CurrencyFormat(row?.variablePayoutAmount, currency)}</td>
                                                        }
                                                        {
                                                            (row?.employeeId === activeRow?.employeeId) ?
                                                                <td className="table-value-btns sticky-right" align="left">
                                                                    {
                                                                        saveLoader ?
                                                                            <CircularProgress size={19} />
                                                                            :
                                                                            <>
                                                                            <button className={`save-btn ${(isRecomendErr || withPromoErr || isAlertsFetching()) ? "gray-bg" : ""}`} disabled={saveLoader || isRecomendErr || withPromoErr || isAlertsFetching()} onClick={() => saveCompensation(activeRow)}>Save</button>
                                                                            <button className="edit-btn mt-2" onClick={() => setActiveRow(null)}>Cancel</button>
                                                                            </>
                                                                    }
                                                                </td>
                                                            :
                                                                <td className="table-value-btns sticky-right" align="left">
                                                                    {
                                                                        !row?.approved && <button className="edit-btn" disabled={saveLoader} onClick={() => handleEditRow(row)}>Edit</button>
                                                                    }                                                                    
                                                                </td>
                                                        }
                                                    </tr>
                                                
                                                ))}
                                            </tbody>
                                    }
                        <tbody>
                            
                        </tbody>
                        </table>
                        :
                        <table>
                        <thead>
                            
                        <tr>
                                <th className={`sticky`}>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Emp Code</span>
                                    </div>    
                                </th>
                                <th className={`sticky2`}>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Emp Name</span>
                                    </div>    
                                </th>
                            {
                                blockColumns?.map((column,index) => {
                                    return(
                                            
                                                selectedConfigColumns?.includes(column) &&
                                                <th className={`${(column === "Emp Code") && "sticky" } ${((column === "Emp Name")) && "sticky2"} ${grayBlocks?.includes(column) && "gray-block"}`}>
                                                    <div className="col-head-outer">
                                                    {((column === "Emp Code") || (column === "Emp Name")) ? "" : <img className="mr-5" src={!selectedConfigColumns?.includes(column) ? Plus : Minus} alt="Minus" onClick={() => handleCollapse(column)} />} 
                                                        <span className="table-heading">{!selectedConfigColumns?.includes(column) ? "" : column}</span>
                                                    </div>    
                                                </th>
                                            
                                    )
                                })
                            }

                            <th className="sticky-right">
                                <div className="col-head-outer">
                                    <span className="table-heading">Action</span>
                                </div>    
                            </th>
                            
                        </tr>
                        </thead>
                        {
                                        
                                            <tbody>
                                                {totalCompData.map((row) => (
                                                    <tr key={row?.empCode} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    

                                                                <td className="table-value sticky" component="th" scope="row">{row?.empCode}</td>
                                                    { 
                                                            (row?.employeeId === activeRow?.employeeId) ?

                                                                <td className="table-value-emp-name sticky2" align="left">
                                                                    <span onClick={() => handleViewCandidate(activeRow?.name)} style={{cursor: "pointer"}}>{activeRow?.name}&nbsp;</span>
                                                                    {/* {console.log("alertObject?.flag",flag)} */}
                                                                    {/* {
                                                                        (flag || activeRow?.flag || tcCompaFlag || activeRow?.tcCompaFlag || newFixedSalaryFlag || activeRow?.newFixedSalaryFlag || recommendedVariablePayTargetFlag || activeRow?.recommendedVariablePayTargetFlag) &&
                                                                        <FaFlag style={{ color: tcCompaFlag?.toLowerCase() || flag?.toLowerCase() || activeRow?.tcCompaFlag?.toLowerCase() || activeRow?.flag?.toLowerCase() || newFixedSalaryFlag?.toLowerCase() || activeRow?.newFixedSalaryFlag?.toLowerCase() || recommendedVariablePayTargetFlag?.toLowerCase() || activeRow?.recommendedVariablePayTargetFlag?.toLowerCase()}} />
                                                                    } */}
                                                                     <div className="all-flags">
                                                                        {
                                                                            (activeRow?.flag) &&
                                                                            <MediTooltip msg={activeRow?.flagReason}>
                                                                            <FaFlag style={{ color: activeRow?.flag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                        {
                                                                            (activeRow?.tcCompaFlag) &&
                                                                            <MediTooltip msg={activeRow?.tcCompaFlagReason}>
                                                                            <FaFlag style={{ color: activeRow?.tcCompaFlag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                        {
                                                                            (activeRow?.newFixedSalaryFlag) &&
                                                                            <MediTooltip msg={activeRow?.newFixedSalaryFlagReason}>
                                                                            <FaFlag style={{ color: activeRow?.newFixedSalaryFlag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                        {
                                                                            (activeRow?.recommendedVariablePayTargetFlag) &&
                                                                            <MediTooltip msg={activeRow?.recommendedVpTargetFlagReason}>
                                                                            <FaFlag style={{ color: activeRow?.recommendedVariablePayTargetFlag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                        {
                                                                            (activeRow?.newVariablePayFlag) &&
                                                                            <MediTooltip msg={activeRow?.newVariablePayFlagReason}>
                                                                            <FaFlag style={{ color: activeRow?.newVariablePayFlag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                    </div>
                                                                </td>

                                                            :

                                                                <td className="table-value-emp-name sticky2" align="left">
                                                                    <span onClick={() => handleViewCandidate(row?.name)} style={{cursor: "pointer"}}>{row?.name}&nbsp;</span>
                                                                    {/* {
                                                                        (row?.flag || row?.tcCompaFlag || row?.newFixedSalaryFlag || row?.recommendedVariablePayTargetFlag) &&
                                                                        <FaFlag style={{ color: row?.tcCompaFlag?.toLowerCase() || row?.flag?.toLowerCase() || row?.newFixedSalaryFlag?.toLowerCase() || row?.recommendedVariablePayTargetFlag?.toLowerCase() }} />
                                                                    } */}
                                                                     <div className="all-flags">
                                                                        {
                                                                            (row?.flag) &&
                                                                            <MediTooltip msg={row?.flagReason}>
                                                                            <FaFlag style={{ color: row?.flag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                        {
                                                                            (row?.tcCompaFlag) &&
                                                                            <MediTooltip msg={row?.tcCompaFlagReason}>
                                                                            <FaFlag style={{ color: row?.tcCompaFlag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                        {
                                                                            (row?.newFixedSalaryFlag) &&
                                                                            <MediTooltip msg={row?.newFixedSalaryFlagReason}>
                                                                            <FaFlag style={{ color: row?.newFixedSalaryFlag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                        {
                                                                            (row?.recommendedVariablePayTargetFlag) &&
                                                                            <MediTooltip msg={row?.recommendedVpTargetFlagReason}>
                                                                            <FaFlag style={{ color: row?.recommendedVariablePayTargetFlag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                        {
                                                                            (row?.newVariablePayFlag) &&
                                                                            <MediTooltip msg={row?.newVariablePayFlagReason}>
                                                                            <FaFlag style={{ color: row?.newVariablePayFlag?.toLowerCase() }} />
                                                                            </MediTooltip>
                                                                        }
                                                                    </div>
                                                                </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Date Of Joining") &&
                                                            <td className="table-value " align="left">{row?.dateOfJoining ? getFormattedDate(row?.dateOfJoining, dateFormat) : ""}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Function") &&
                                                            <td className="table-value" align="left">{row?.function}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Sub Function") &&
                                                            <td className="table-value" align="left">{row?.subFunction}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Designation") &&
                                                            <td className="table-value" align="left">{row?.designation}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Level") &&
                                                            <td className="table-value" align="left">{row?.employeeLevel}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Location") &&
                                                            <td className="table-value" align="left">{row?.location}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Cost Center") &&
                                                            <td className="table-value" align="left">{row?.costCenter}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Job code") &&
                                                            <td className="table-value" align="left">{row?.jobCode}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Manager Emp Code") &&
                                                            <td className="table-value" align="left">{row?.managerEmpCode}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Manager Name") &&
                                                            <td className="table-value" align="left">{row?.managerName}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Promotion Status") &&  (activeTab === "Entire Org") &&
                                                            row?.employeeId === activeRow?.employeeId ?                                                           
                                                            <td className="table-value" align="left">

                                                                {/* {row?.promotionStatus} */}

                                                                <FormControl sx={{ width: "6rem" }}>
                                                                    {/* <InputLabel id="demo-simple-select-label" sx={{fontSize:"0.9rem"}}>Select</InputLabel> */}
                                                                    <InputLabel id="demo-simple-select-filled-label" sx={{fontSize:"0.9rem"}}>Select</InputLabel>
                                                                    <Select
                                                                        size="small"
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={activeRow?.promotionStatus}
                                                                        label="Promotion"
                                                                        name="promotionStatus"
                                                                        onChange={handleChangeComp}
                                                                    >
                                                                        <MenuItem value="Yes">Yes</MenuItem>
                                                                        <MenuItem value="No">No</MenuItem>
                                                                    </Select>
                                                                </FormControl>

                                                                &nbsp;
                                                                {
                                                                    (row?.newDesignationFlag) &&
                                                                    <FaFlag style={{ color: row?.newDesignationFlag?.toLowerCase() }} />
                                                                }
                                                            </td>
                                                            :
                                                            selectedConfigColumns?.includes("Promotion Status") &&
                                                            <td className="table-value" align="left">
                                                                {row?.promotionStatus}
                                                                &nbsp;
                                                                {
                                                                    (row?.newDesignationFlag) &&
                                                                    <FaFlag style={{ color: row?.newDesignationFlag?.toLowerCase() }} />
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("New Designation") && (activeTab === "Entire Org") &&
                                                            row?.employeeId === activeRow?.employeeId ?
                                                            
                                                            <td className="table-value" align="left">
                                                                <TextField 
                                                                    id="filled-basic" 
                                                                    label="New Designation" 
                                                                    // variant="filled" 
                                                                    name="newDesignation"
                                                                    value={activeRow?.newDesignation}
                                                                    onChange={handleChangeComp}
                                                                />
                                                            </td>
                                                            :
                                                            selectedConfigColumns?.includes("New Designation") && 
                                                            <td className="table-value" align="left">{row?.newDesignation}</td>
                                                        }

                                                        {
                                                            row?.ratings?.map((item, index) => {
                                                                return(
                                                                    selectedConfigColumns?.includes(Object.keys(item)[0]) &&
                                                                    <td className="table-value" align="left">
                                                                        {
                                                                            // item[Object.keys(item)[0]]?.map((x) =>
                                                                                item[Object.keys(item)[0]]?.map((y, i) => {
                                                                                    return (
                                                                                        <span>{y?.trendingRating}{((item[Object.keys(item)[0]]?.length - 1) !== i) ? (y?.trendingRating && "-") : ""}</span>
                                                                                    )
                                                                                })
                                                                            // )
                                                                        }
                                                                    </td>
                                                                )
                                                            })
                                                        }

                                                        {/* {
                                                            selectedConfigColumns?.includes("Performance Rating (Combined Rating) 2021-22") &&
                                                            <td className="table-value" align="left">
                                                                {
                                                                    row?.ratings?.map((x) =>
                                                                        x["2021-2022"]?.map((y, i) => {
                                                                            return (
                                                                                <span>{y?.trendingRating}{((x["2021-2022"]?.length - 1) !== i) ? (y?.trendingRating && "-") : ""}</span>
                                                                            )
                                                                        })
                                                                    )
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Performance Rating (Combined Rating) 2022-23") &&
                                                            <td className="table-value" align="left">
                                                                {
                                                                    row?.ratings?.map((x) =>
                                                                        x["2022-2023"]?.map((y, i) => {
                                                                            return (
                                                                                <span>{y?.trendingRating}{((x["2022-2023"]?.length - 1) !== i) ? (y?.trendingRating && "-") : ""}</span>
                                                                            )
                                                                        })
                                                                    )
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Performance Rating (Combined Rating) 2023-24") &&
                                                            <td className="table-value" align="left">
                                                                {
                                                                    row?.ratings?.map((x) =>
                                                                        x["2024-2025"]?.map((y, i) => {
                                                                            return (
                                                                                <span>{y?.trendingRating}{((x["2024-2025"]?.length - 1) !== i) ? (y?.trendingRating && "-") : ""}</span>
                                                                            )
                                                                        })
                                                                    )
                                                                }
                                                            </td>
                                                        } */}
                                                        {
                                                            selectedConfigColumns?.includes("LBP (Yes/No)") &&
                                                            <td className="table-value" align="left">{!isEmptyNullUndefined(row?.lBP) ? row?.lBP ? "Yes" : "No" : ""}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Last Increment Date") &&
                                                            <td className="table-value mid-align" align="left">{row?.lastIncrementDate ? getFormattedDate(row?.lastIncrementDate, dateFormat) : ""}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`No of Hikes ${currentCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">{row?.noOfHikes}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Total Hike % ${currentCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">{CurrencyFormat(row?.totalHike, currency)}</td>
                                                        }

                                                        {/* ------------------------ */}

                                                        {
                                                            selectedConfigColumns?.includes(`Fixed Salary ${currentCompYears}`) &&
                                                            <td className="table-value gray-block mid-align" align="left">{CurrencyFormat(row?.baseValue, currency)}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Current Fixed Compa Ratio") &&
                                                            <td className="table-value gray-block mid-align" align="left">{row?.currentFixedCompaRatio?.toFixed(3)}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Recommended Fixed Salary increment %") &&
                                                            <td className="table-value gray-block mid-align" align="left" style={{color: !isEmptyNullUndefined(row?.flag) ? row?.flag : ""}}>{CurrencyFormat(row?.recommendedFixedSalaryincrement, currency)}</td>
                                                        }
                                                        {
                                                            row?.employeeId === activeRow?.employeeId ?
                                                                selectedConfigColumns?.includes("Manager Recommendation %") &&
                                                                <td className="table-value gray-block mid-align" align="left">
                                                                    <TextField
                                                                        size='small'
                                                                        name="managerRecommendation"
                                                                        placeholder={(isEmptyNullUndefined(activeRow?.managerRecommendation) || activeRow?.managerRecommendation === 0) ? "0" : ""}
                                                                        // label="%"
                                                                        type="number"
                                                                        value={(activeRow?.managerRecommendation > 0) && activeRow?.managerRecommendation}
                                                                        onChange={handleChangeComp}
                                                                        inputProps={{ min: 0, max: 100 }}
                                                                        variant="outlined"
                                                                        disabled={saveLoader}
                                                                        error={!isEmptyNullUndefined(activeRow?.flag) || isRecomendErr || withPromoErr}
                                                                    />
                                                                </td>
                                                                :
                                                                selectedConfigColumns?.includes("Manager Recommendation %") &&
                                                                <td className="table-value gray-block mid-align" align="left" style={{color: !isEmptyNullUndefined(row?.flag) ? row?.flag : ""}}>{row?.managerRecommendation}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Final Increment Amount") &&
                                                            <td className="table-value gray-block mid-align" align="left">{(row?.employeeId === activeRow?.employeeId) ? CurrencyFormat(activeRow?.finalIncrementAmount, currency) : CurrencyFormat(row?.finalIncrementAmount, currency)}</td>

                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`New Fixed Salary ${nextCompYears}`) &&
                                                            <td className="table-value gray-block mid-align" align="left" style={{color: !isEmptyNullUndefined(row?.newFixedSalaryFlag) ? row?.newFixedSalaryFlag : ""}}>{(row?.employeeId === activeRow?.employeeId) ? CurrencyFormat(activeRow?.newFixedSalary, currency) : CurrencyFormat(row?.newFixedSalary, currency)}</td>
                                                        }
                                                        
                                                        {/* ------------------------ */}

                                                        {/* {
                                                            selectedConfigColumns?.includes(`Variable Pay Eligibility ${nextCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">{CurrencyFormat(row?.variablePayEligibility, currency)}</td>
                                                        } */}
                                                        {
                                                            selectedConfigColumns?.includes(`Variable Pay Target ${currentCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">{CurrencyFormat(row?.currentYearSalaryHead?.filter((x) => x?.type === "Target")[0]?.amount, currency)}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Recommended Variable Pay Target ${nextCompYears}`) &&
                                                            <td className="table-value mid-align" align="left" style={{color: !isEmptyNullUndefined(row?.newFixedSalaryFlag) ? row?.newFixedSalaryFlag : !isEmptyNullUndefined(row?.recommendedVariablePayTargetFlag) ? row?.recommendedVariablePayTargetFlag : ""}}>
                                                                {
                                                                    row?.employeeId === activeRow?.employeeId ?
                                                                        activeRow?.recommendedVariablePayTarget > 0 ? CurrencyFormat(activeRow?.recommendedVariablePayTarget, currency) : 0
                                                                        :
                                                                        row?.recommendedVariablePayTarget > 0 ? CurrencyFormat(row?.recommendedVariablePayTarget, currency) : 0
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            row?.employeeId === activeRow?.employeeId ?
                                                                selectedConfigColumns?.includes("Manager Recommended Variable Pay Target") &&
                                                                <td className="table-value mid-align" align="left">
                                                                    <TextField
                                                                        size='small'
                                                                        name="managerRecommendedVariablePayTarget"
                                                                        placeholder={(isEmptyNullUndefined(activeRow?.managerRecommendedVariablePayTarget) || activeRow?.managerRecommendedVariablePayTarget > 0) ? "" : "0"}
                                                                        // label="%"
                                                                        type="number"
                                                                        value={(activeRow?.managerRecommendedVariablePayTarget > 0) && activeRow?.managerRecommendedVariablePayTarget}
                                                                        onChange={handleChangeComp}
                                                                        // inputProps={{ min: 0, max: 100 }}
                                                                        variant="outlined"
                                                                        disabled={saveLoader || isEmptyNullUndefined(row?.currentYearSalaryHead?.filter((x) => x?.type === "Target")[0]) || !row?.currentYearSalaryHead?.filter((x) => x?.type === "Target")[0]?.isEligibilityForCalculation}
                                                                    />
                                                                </td>
                                                                :
                                                                selectedConfigColumns?.includes("Manager Recommended Variable Pay Target") &&
                                                                <td className="table-value mid-align" align="left" style={{color: !isEmptyNullUndefined(row?.newVariablePayFlag) ? row?.newVariablePayFlag : ""}}>
                                                                    {row?.managerRecommendedVariablePayTarget > 0 ? CurrencyFormat(row?.managerRecommendedVariablePayTarget, currency) : 0}
                                                                </td>
                                                        }
                                                        
                                                        {/* ------------------------ */}

                                                        
                                                        {
                                                            selectedConfigColumns?.includes(`Value of Vested ESOPs ${currentCompYears}`) &&
                                                            <td className="table-value gray-block mid-align" align="left">
                                                                {
                                                                    row?.currentYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "Value of Vested ESOPs" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`New Vested ESOP ${nextCompYears}`) &&
                                                            <td className="table-value gray-block mid-align" align="left">
                                                                {
                                                                    row?.nextYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "Value of Vested ESOPs" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        
                                                        {/* ------------------------ */}

                                                        {
                                                            selectedConfigColumns?.includes(`Total Compensation ${currentCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">
                                                                {
                                                                    row?.currentYearSalaryHead?.map((sh) => {
                                                                        return (
                                                                            sh?.salaryHead === "Total Compensation" &&
                                                                            <span>{CurrencyFormat(sh?.amount, currency)}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Current TC Compa Ratio") &&
                                                            <td className="table-value mid-align" align="left">{row?.currentTCCompaRatio?.toFixed(3)}</td>
                                                        }{
                                                            selectedConfigColumns?.includes(`New Total Compensation ${nextCompYears}`) &&
                                                            <td className="table-value mid-align" align="left">{(row?.employeeId === activeRow?.employeeId) ? (activeRow?.newTotalCompensation > 0 ? CurrencyFormat(activeRow?.newTotalCompensation, currency) : 0) : (row?.newTotalCompensation > 0 ? CurrencyFormat(row?.newTotalCompensation, currency) : 0)}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("New TC Compa Ratio") &&
                                                            <td className="table-value mid-align" align="left" style={{color: !isEmptyNullUndefined(row?.tcCompaFlag) ? row?.tcCompaFlag : ""}}>{(row?.employeeId === activeRow?.employeeId) ? Number(activeRow?.newTCCompaRatio)?.toFixed(3) : Number(row?.newTCCompaRatio)?.toFixed(3)}</td>
                                                        }
                                                        
                                                        {/* ------------------------ */}

                                                        {
                                                            selectedConfigColumns?.includes(`Variable Pay Eligibility ${currentCompYears}`) &&
                                                            <td className="table-value gray-block mid-align" align="left">{CurrencyFormat(row?.variablePayEligibility, currency)}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes(`Variable Payout Amount ${currentCompYears}`) &&
                                                            <td className="table-value gray-block mid-align" align="left">{CurrencyFormat(row?.variablePayoutAmount, currency)}</td>
                                                        }
                                                        {
                                                            (row?.employeeId === activeRow?.employeeId) ?
                                                                <td className="table-value-btns sticky-right" align="left">
                                                                    {
                                                                        saveLoader ?
                                                                            <CircularProgress size={19} />
                                                                            :
                                                                            <>
                                                                            <button className={`save-btn ${(isRecomendErr || withPromoErr || isAlertsFetching()) ? "gray-bg" : ""}`} disabled={saveLoader || isRecomendErr || withPromoErr || isAlertsFetching()} onClick={() => saveCompensation(activeRow)}>Save</button>
                                                                            <button className="edit-btn mt-2" onClick={() => setActiveRow(null)}>Cancel</button>
                                                                            </>
                                                                    }
                                                                </td>
                                                            :
                                                                <td className="table-value-btns sticky-right" align="left">
                                                                    {
                                                                        !row?.approved && <button className="edit-btn" disabled={saveLoader} onClick={() => handleEditRow(row)}>Edit</button>
                                                                    }                                                                    
                                                                </td>
                                                        }


                                                    </tr>
                                                
                                                ))}
                                            </tbody>
                                    }
                        <tbody>
                            
                        </tbody>
                        </table>
                    }
                    
                </div>
             }

                
            </div>

            {/*----- Pagination -----*/}
            <div className="table-pagination-wrapper">
                <div className="d-flex align-items-center">
                    <Typography className="per-page-item">{"Per page items"}</Typography>
                    <FormControl className="mt-12" size="small"
                        sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "1px solid #00425A",
                                borderRadius: "10px",
                            },
                            "& .MuiInputBase-root": {
                                fontSize: "12px",
                                color: "#00425A",
                                fontWeight: 400,
                            }
                        }}>
                        <InputLabel id="demo-simple-select-label" sx={{ fontSize: "12px", color: "#00425A", fontWeight: 400 }}></InputLabel>
                        <Select
                            fullWidth
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(e.target.value)}
                            sx={{ fontSize: "12px", color: "#00425A", fontWeight: 400 }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        "& .MuiMenuItem-root": {
                                            fontSize: "12px",
                                            color: "#00425A",
                                            fontWeight: 400,
                                        }
                                    }
                                }
                            }}
                        >
                            {perPageItemNames?.map((report) => {
                                return (
                                    <MenuItem value={report}>{report}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>
                {
                    !isEmptyNullUndefined(totalCompData) && (totalPages > 1) && 
                    <PaginationMedi currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
                }
            </div>

            {
                isElligibleForApprove && (activeTab === "Entire Org") &&
                <div className="approve-btn-outer">
                    <button className={`${(approveLoader || dataLoader) ? "apr-btn-disabled" : "apr-btn"}`} onClick={handleApproveAll} disabled={approveLoader || dataLoader}>
                        {
                            approveLoader ? 
                            <CircularProgress size={19} style={{color: "#ffffff"}} />
                            :
                            "Approve"
                        }
                    </button>
                </div>
            }

            {
                isOpenFilter &&
                <SideFilters isOpen={isOpenFilter} setIsOpen={setIsOpenFilter} >
                    <div className="filter-wrapper">

                        <div className="filters-heading">Filters</div>

                        {
                            dropdownLoader ? 
                            <CircularProgress size={29} />
                            :
                            <Stack>
                                {
                                    !isEmptyNullUndefined(dropdownData) && Object.keys(dropdownData).map((key) => {
                                        return (
                                            <FilterSelect title={key} filterPayload={filterPayload} setFilterPayload={setFilterPayload} dropdownData1={dropdownData} />
                                        )
                                    })
                                }
                            </Stack>
                        }

                        <Stack sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: "1rem",
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end"
                        }}>

                            <Button
                                variant="outlined"
                                disabled={Object.keys(filterPayload).length === 0}
                                sx={{
                                    color: "var(--primary)",
                                    borderColor: "var(--primary)",
                                    marginRight: "1rem",
                                    width: "9rem"
                                }}
                                onClick={() => { handleResetFilters() }}
                            >
                                Reset
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    // color:"var(--secondary)",
                                    backgroundColor: "var(--primary)",
                                    borderColor: "var(--primary)",
                                    "&:hover": {
                                        backgroundColor: "#035c7c",
                                        borderColor: "#035c7c",
                                    },
                                    width: "9rem"
                                }}
                                disabled={dataLoader || dropdownLoader}
                                onClick={() => handleSubmitFilter()}
                            >
                                Submit
                            </Button>
                        </Stack>
                    </div>
                </SideFilters>
            }

{
            openDialog && 
            <DialogPopup openDialog={openDialog} setOpenDialog={setOpenDialog} redirect={true} path="/compensation">
                
                <div className="check-approved-popup-main">
                <SlClose onClick={() => setOpenDialog(false)} />

               
               
                    <div className="table-heading" style={{margin: "3.2rem auto", textAlign:"center", fontSize:"1.25rem", fontWeight:"500"}}> Compensation planning is approved. </div>
                    <div className="btn-outer">
                        <button className="approve-done-btn" onClick={() => setOpenDialog(false)} disabled={approveLoader}>
                            {
                                approveLoader ? 
                                <CircularProgress size={27} />
                                :
                                "Done"
                            }
                            
                        </button>
                    </div>
                    
                </div>
            </DialogPopup>
        }
           
        </div>
    )
};

export default Compensation; 
