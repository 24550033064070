import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { CurrencyFormat } from "../../../utils/utils";

// Chart.js को रजिस्टर करें
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BudgetSummaryChart = () => {

  const history = useHistory();

  const employeeDetails = useSelector((state) => state?.empData?.empData);

  // Custom plugin to display data values on bars
const valueOnTopPlugin = {
  id: 'valueOnTopPlugin',
  afterDatasetsDraw: (chart) => {
    const { ctx, data } = chart;
    chart.data.datasets.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i);
      meta.data.forEach((bar, index) => {
        const value = dataset.data[index];
        ctx.fillStyle = 'black'; // Text color
        ctx.font = '12px Arial';
        ctx.textAlign = 'center';
        ctx.fillText( CurrencyFormat(value, employeeDetails?.company?.currency), bar.x, bar.y - 9); // Position above the bar
      });
    });
  },
};

  // Chart Data
  const data = {
    labels: ['2022', '2023', '2024'],
    datasets: [
      {
        label: 'Base Salary',
        data: [3000, 2000, 4000, 5000, 6000],
        backgroundColor: '#2d99fe',
        borderColor: '#2d99fe',
        borderWidth: 1,
        borderRadius: 10,
        borderSkipped: 'bottom',
      },
      {
        label: 'Total Compensation',
        data: [2000, 4000, 5000, 5000, 6000],
        backgroundColor: '#cd77db',
        borderColor: '#cd77db',
        borderWidth: 1,
        borderRadius: 10,
        borderSkipped: 'bottom',
      },
    ],
  };

  // Chart Options
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        position: 'bottom',
        text: 'Year on Year',
      },
      legend: {
        position: 'bottom',
      },
      scales: {
        y: {
          ticks: {
            display: false, // Hide the tick values
          },
          title: {
            display: true, // Show the axis label
            text: 'Salary Cost', // Set your desired label
            font: {
              size: 14,
            },
          },
          grid: {
            drawTicks: false,
            drawBorder: true,
          },
        },
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: '#000',
        font: {
          size: 12,
          weight: 'bold',
        },
        formatter: (value) => `$${value}`,
      },
    },
  };

  return (
    <Grid>
      <Bar data={data} options={options} plugins={[valueOnTopPlugin]} />
    </Grid>
  )
};

export default BudgetSummaryChart; 
