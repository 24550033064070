import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import Kudos from './components/Kudos';
import Rewards from './components/Rewards';
import MyEngagement from './components/MyEngagement';
import MyTeamEngagement from './components/MyTeamEngagement';
import MyOrgEngagement from './components/MyOrgEngagement';
import ManageRewards from './components/ManageRewards';
import CalendarEvents from './components/CalendarEvents';
import MyAccomplishments from './components/MyAccomplishments';
import SurveyPuzzle from './components/SurveyPuzzle';
import KudosForModal from './Modal/KudosForModal';
import SendAGreetingCardModal from './Modal/SendAGreetingCardModal';
import UploadSendVideoMessageModal from './Modal/UploadSendVideoMessageModal';
import KudosForDeattachGreetingCardModal from './Modal/KudosForDeattachGreetingCardModal';
import CertificateModal from './Modal/CertificateModal';
import DateIcon from "../../../assets/images/MyCommunity/dateIcon.svg";
import { MdArrowBackIos } from "react-icons/md";
import RightSideBlock from "./components/rightSideBlock";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const MyCommunity = () => {

  const history = useHistory();
  
  const [kudosView, setKudosView] = useState(true);
  const [RewardsView, setRewardsView] = useState(false);
  const [myEngagementView, setMyEngagementView] = useState(true);
  const [myTeamEngagementView, setMyTeamEngagementView] = useState(false);
  const [myOrgEngagementView, setMyOrgEngagementView] = useState(false);

  const [openKudosForModal, setKudosForModal] = useState(false);
  const [openSendAGreetingCardModal, setSendAGreetingCardModal] = useState(false);
  const [openUploadSendVideoMessageModal, setUploadSendVideoMessageModal] = useState(false);
  const [openKudosForDeattachGreetingCardModal, setKudosForDeattachGreetingCardModal] = useState(false);
  const [openCertificateModal, setCertificateModal] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleKudos = () => {
    setKudosView(true);
    setRewardsView(false);
  };

  const handleRewards = () => {
    setKudosView(false);
    setRewardsView(true);
  };

  //----- Engagement Tab Start Handle -----//
  const handleMyEngagement = () => {
    setMyEngagementView(true);
    setMyTeamEngagementView(false);
    setMyOrgEngagementView(false);
  };

  const handleMyTeamEngagement = () => {
    setMyEngagementView(false);
    setMyTeamEngagementView(true);
    setMyOrgEngagementView(false);
  };

  const handleMyOrgEngagement = () => {
    setMyEngagementView(false);
    setMyTeamEngagementView(false);
    setMyOrgEngagementView(true);
  };

  const handleOpenKudosFor = () => {
    setKudosForModal(true);
  }

  const handleSendAGreetingCard = () => {
    setSendAGreetingCardModal(true);
  }

  const handleUploadSendVideoMessage = () => {
    setUploadSendVideoMessageModal(true);
  }

  const handlesetKudosForDeattachGreetingCard = () => {
    setKudosForDeattachGreetingCardModal(true);
  }

  const handleKudosForModalClose = () => {
    setKudosForModal(false);
  }

  const handleSendAGreetingCardModalClose = () => {
    setSendAGreetingCardModal(false);
  }

  const handleUploadSendVideoMessageModalClose = () => {
    setUploadSendVideoMessageModal(false);
  }

  const handleKudosForDeattachGreetingCardModalClose = () => {
    setKudosForDeattachGreetingCardModal(false);
  }

  const handleCertificateModalClose = () => {
    setCertificateModal(false);
  }

  const handleShowCalendarEvents = () => {
    history.push({ pathname: "/my-community-calendar", state: { RewardsView: RewardsView } });
  }

  return (
    <div className="my-community">
      <Grid container spacing={2}>     

              {/*----- Kudos & Rewards Button Section -----*/}
              <Grid item xs={12} sm={12} md={12}>
                <Grid class="tab-btn-List">
                  <a onClick={handleKudos} class={kudosView === true ? "active-btn" : "inActive-btn"}>{"Kudos"}</a>
                  <a onClick={handleRewards} class={RewardsView === true ? "active-btn" : "inActive-btn"}>{"Rewards"}</a>

                  {/* <button onClick={() => handleOpenKudosFor()}>{"KudosFor"}</button>
            <button onClick={() => handleSendAGreetingCard()}>{"SendAGreeting"}</button>
            <button onClick={() => handleUploadSendVideoMessage()}>{"UploadSendVideo"}</button>
            <button onClick={() => handlesetKudosForDeattachGreetingCard()}>{"DeattachGreeting"}</button> */}

                  <button
                    className="date-btn"
                    onClick={() => handleShowCalendarEvents()}
                  >
                    <img src={DateIcon} alt="Date" className="date-icon" />
                  </button>
                </Grid>
              </Grid>

              {/*----- Kudos & Rewards Value Section -----*/}
              <Grid item xs={12} sm={12} md={12} className="pt-0">
                {kudosView === true &&
                  <Kudos setKudosForModal={setKudosForModal} />
                }

                {RewardsView === true &&
                  <Rewards />
                }
              </Grid>

              {/*----- MyEngagement & MyTeamEngagement & MyOrgEngagement Button Section -----*/}

              <Grid item xs={12} sm={12} md={12}>
                <Grid class="tab-btn-List">
                  <a onClick={handleMyEngagement} class={myEngagementView === true ? "active-btn" : "inActive-btn"}>{"My Engagement"}</a>
                  <a onClick={handleMyTeamEngagement} class={myTeamEngagementView === true ? "active-btn" : "inActive-btn"}>{"My Team Engagement"}</a>
                  <a onClick={handleMyOrgEngagement} class={myOrgEngagementView === true ? "active-btn" : "inActive-btn"}>{"My Org Engagement"}</a>
                </Grid>
              </Grid>

              {/*----- MyEngagement & MyTeamEngagement & MyOrgEngagement Value Section -----*/}
              <Grid item xs={12} sm={12} md={7} className="pt-0">
                {myEngagementView === true &&
                  <MyEngagement setUploadSendVideoMessageModal={setUploadSendVideoMessageModal} setSendAGreetingCardModal={setSendAGreetingCardModal} />
                }

                {myTeamEngagementView === true &&
                  <MyTeamEngagement />
                }

                {myOrgEngagementView === true &&
                  <MyOrgEngagement />
                }
              </Grid>
         

        {/*----- Manage Rewards & Calendar Events & My Accomplishments & Survey Puzzle -----*/}
        {
          windowWidth > 900 && 
          <RightSideBlock RewardsView={RewardsView} />
        }

      </Grid>

      {/*----- Kudos For Modal -----*/}
      {openKudosForModal && (
        <KudosForModal
          openKudosForModal={openKudosForModal}
          handleKudosForModalClose={handleKudosForModalClose}
        />
      )}

      {/*----- Send A Greeting Card Modal -----*/}
      {openSendAGreetingCardModal && (
        <SendAGreetingCardModal
          openSendAGreetingCardModal={openSendAGreetingCardModal}
          handleSendAGreetingCardModalClose={handleSendAGreetingCardModalClose}
        />
      )}

      {/*----- Upload Send Video Message Modal -----*/}
      {openUploadSendVideoMessageModal && (
        <UploadSendVideoMessageModal
          openUploadSendVideoMessageModal={openUploadSendVideoMessageModal}
          handleUploadSendVideoMessageModalClose={handleUploadSendVideoMessageModalClose}
        />
      )}

      {/*----- Kudos For Deattach Greeting Card Modal -----*/}
      {openKudosForDeattachGreetingCardModal && (
        <KudosForDeattachGreetingCardModal
          openKudosForDeattachGreetingCardModal={openKudosForDeattachGreetingCardModal}
          handleKudosForDeattachGreetingCardModalClose={handleKudosForDeattachGreetingCardModalClose}
        />
      )}

      {/*----- Kudos For Modal -----*/}
      {openCertificateModal && (
        <CertificateModal
          openCertificateModal={openCertificateModal}
          handleCertificateModalClose={handleCertificateModalClose}
        />
      )}
    </div>
  );
};

export default MyCommunity;
