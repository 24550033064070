import React, { useState } from 'react';
import { Grid, Typography, TextField, Button, Checkbox, FormControlLabel } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import EditUserProfile from "../../../../assets/images/successionPlan/editUserProfile.png";
import UserRightArrow from "../../../../assets/images/promotions/userRightArrow.svg";
import AssessmentIcon from "../../../../assets/images/promotions/assessmentIcon.png";
import ListBottomImg from "../../../../assets/images/promotions/listBottomImg.png";


const EditCandidate = ({ onCancel, name }) => {
    const dispatch = useDispatch();

    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    return (
        <Grid item xs={12} sm={12} md={12}>
            {/*----- User Form -----*/}
            <Grid container className="user-form-card">
                <Grid item xs={12} sm={12} md={12} className="edit-user-card">
                    {/*----- User Name & Profile & Emp No -----*/}
                    <Grid className="ta-center">
                        <img
                            src={EditUserProfile}
                            alt="EditUserProfile"
                            height={50}
                            width={50}
                        />
                        <Typography className="profile-name">{name}<span><img src={UserRightArrow} alt="rightArrow" height={16} width={10} /></span></Typography>
                        <Typography className="profile-emp-lable">{"Emp No. "}<span className="profile-emp-no">{"10614"}</span></Typography>
                    </Grid>

                    {/*----- Vertical Border -----*/}
                    <Grid className='vertical-border'></Grid>

                    <Grid>
                        <Grid>
                            <Typography className="profile-field-label">{"Level"}</Typography>
                            <Typography className="profile-field-value">{"L4"}</Typography>
                        </Grid>

                        <Grid className="mt-15">
                            <Typography className="profile-field-label">{"Job Family"}</Typography>
                            <Typography className="profile-field-value">{"Assistant Manager"}</Typography>
                        </Grid>
                    </Grid>

                    <Grid>
                        <Grid>
                            <Typography className="profile-field-label">{"Role"}</Typography>
                            <Typography className="profile-field-value">{"IC"}</Typography>
                        </Grid>

                        <Grid className="mt-15">
                            <Typography className="profile-field-label">{"Retention Risk"}</Typography>
                            <Typography className="profile-field-value">{"No"}</Typography>
                        </Grid>
                    </Grid>

                    <Grid>
                        <Grid>
                            <Typography className="profile-field-label">{"Performance Rating(2023-24)"}</Typography>
                            <Typography className="profile-field-value">{"A"}</Typography>
                        </Grid>

                        <Grid className="mt-15">
                            <Typography className="profile-field-label">{"Rating Multiplier"}</Typography>
                            <Typography className="profile-field-value">{"0.7"}</Typography>
                        </Grid>
                    </Grid>

                    <Grid>
                        <Grid>
                            <Typography className="profile-field-label">{"Suggested TC Placement"}</Typography>
                            <Typography className="profile-field-value">{"TC Min"}</Typography>
                        </Grid>

                        <Grid className="mt-15">
                            <Typography className="profile-field-label">{"Proration Indicatot"}</Typography>
                            <Typography className="profile-field-value">{"Yes"}</Typography>
                        </Grid>
                    </Grid>

                    {/* <Grid>
                        <Grid>
                            <Button className="profile-btn">
                                <img src={AssessmentIcon} alt="reload" />
                                <div>
                                    <Typography className="profile-btn-txt">{"View Performance Assessment"}</Typography>
                                </div>
                            </Button>
                        </Grid>

                        <Grid className="mt-15">
                            <Button className="profile-btn">
                                <img src={AssessmentIcon} alt="reload" />
                                <div>
                                    <Typography className="profile-btn-txt">{"View Employee Profile"}</Typography>
                                </div>
                            </Button>
                        </Grid>
                    </Grid> */}
                </Grid>

                <Grid item xs={12} sm={12} md={12} className="form-card">
                    <Grid container>
                        {/*----- No Salary -----*/}
                        <Grid item xs={12} sm={6} md={6}>
                            <Typography className="form-title">{"No Salary"}</Typography>
                            <Typography className="form-description">{"Lorem ipsum is simply text of printing and type setting"}</Typography>
                            <FormControlLabel
                                control={<Checkbox checked={checked} onChange={handleChange} />}
                                label={<Typography className="form-checkbox-title">{"No salary increase"}</Typography>}
                            />
                        </Grid>

                        {/*----- Pro-ration check -----*/}
                        <Grid item xs={12} sm={6} md={6}>
                            <Typography className="form-title">{"Pro-ration check"}</Typography>
                            <Typography className="form-description">{"Lorem ipsum is simply text of printing and type setting"}</Typography>
                            <FormControlLabel
                                control={<Checkbox checked={checked} onChange={handleChange} />}
                                label={<Typography className="form-checkbox-title">{"This employee comes inder pro-ration"}</Typography>}
                            />
                        </Grid>

                        {/*----- Horizontal Border -----*/}
                        <Grid item xs={12} sm={12} md={12} className="horizontal-border"></Grid>

                        {/*----- Base Salary -----*/}
                        <Grid item xs={12} sm={12} md={12} className="list-container">
                            <Grid>
                                <Typography className="list-left-title">{"Base Salary"}</Typography>
                            </Grid>
                            <Grid>
                                <Grid className="right-list-container">
                                    <Grid>
                                        <Typography className="list-right-title">{"Current"}</Typography>
                                        <Typography className="list-right-value">{"5,00,000"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Recommended Inc %"}</Typography>
                                        <Typography className="list-right-value">{"5%"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Manager Inc %"}</Typography>
                                        <TextField
                                            id="filled-basic"
                                            size="small"
                                            variant="outlined"
                                            name="managerInc"
                                            value={"7"}
                                            //onChange={handleChangeComp}
                                            sx={{
                                                width: "100%",
                                                backgroundColor: "#F4F9FB",
                                                borderRadius: "8px",
                                                "& fieldset": {
                                                    border: "1px solid #000000",
                                                    borderRadius: "8px",
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Incremented Salary"}</Typography>
                                        <Typography className="list-right-value">{"35,000"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"New Base Salary"}</Typography>
                                        <Typography className="list-right-value">{"5,35,000"}</Typography>
                                    </Grid>
                                </Grid>

                                {/*----- Horizontal Border -----*/}
                                <Grid item xs={12} sm={12} md={12} className="horizontal-border"></Grid>
                            </Grid>
                        </Grid>

                        {/*----- Stocks -----*/}
                        <Grid item xs={12} sm={12} md={12} className="list-container">
                            <Grid>
                                <Typography className="list-left-title">{"Stocks"}</Typography>
                            </Grid>
                            <Grid>
                                <Grid className="right-list-container">
                                    <Grid>
                                        <Typography className="list-right-title">{"Current"}</Typography>
                                        <Typography className="list-right-value">{"10,000"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Recommended stocks"}</Typography>
                                        <Typography className="list-right-value">{"5000"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Manager's proposal"}</Typography>
                                        <TextField
                                            id="filled-basic"
                                            size="small"
                                            variant="outlined"
                                            name="managerProposal"
                                            value={"7000"}
                                            //onChange={handleChangeComp}
                                            sx={{
                                                width: "100%",
                                                backgroundColor: "#F4F9FB",
                                                borderRadius: "8px",
                                                "& fieldset": {
                                                    border: "1px solid #000000",
                                                    borderRadius: "8px",
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Total"}</Typography>
                                        <Typography className="list-right-value">{"7,000"}</Typography>
                                    </Grid>
                                    <Grid></Grid>
                                </Grid>

                                {/*----- Horizontal Border -----*/}
                                <Grid item xs={12} sm={12} md={12} className="horizontal-border"></Grid>
                            </Grid>
                        </Grid>

                        {/*----- Variable -----*/}
                        <Grid item xs={12} sm={12} md={12} className="list-container">
                            <Grid>
                                <Typography className="list-left-title">{"Variable"}</Typography>
                            </Grid>
                            <Grid>
                                <Grid className="right-list-container">
                                    <Grid>
                                        <Typography className="list-right-title">{"Current"}</Typography>
                                        <Typography className="list-right-value">{"15,000"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Recommended"}</Typography>
                                        <Typography className="list-right-value">{"12,000"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Manager's"}</Typography>
                                        <TextField
                                            id="filled-basic"
                                            size="small"
                                            variant="outlined"
                                            name="managers"
                                            value={"18,000"}
                                            //onChange={handleChangeComp}
                                            sx={{
                                                width: "100%",
                                                backgroundColor: "#F4F9FB",
                                                borderRadius: "8px",
                                                "& fieldset": {
                                                    border: "1px solid #000000",
                                                    borderRadius: "8px",
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Total"}</Typography>
                                        <Typography className="list-right-value">{"18,000"}</Typography>
                                    </Grid>
                                    <Grid></Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/*----- Total Compensation -----*/}
                        <Grid item xs={12} sm={12} md={12} className="list-container mt-40">
                            <Grid>
                                <Typography className="list-left-title">{"Total Compensation"}</Typography>
                            </Grid>
                            <Grid>
                                <Grid className="right-list-container">
                                    <Grid>
                                        <Typography className="list-right-title">{"Current"}</Typography>
                                        <Typography className="list-right-value">{"5,20,000"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"Changed"}</Typography>
                                        <Typography className="list-right-value">{"52,000"}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className="list-right-title">{"New"}</Typography>
                                        <Typography className="list-right-value" sx={{ padding: "5px 10px", backgroundColor: "#F4F9FB", borderRadius: "8px" }}>{"5,72,000"}</Typography>
                                    </Grid>
                                    <Grid></Grid>
                                    <Grid></Grid>
                                </Grid>

                                {/*----- Horizontal Border -----*/}
                                <Grid item xs={12} sm={12} md={12} className="horizontal-border"></Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} className="list-container">
                            <Grid></Grid>
                            <Grid container>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Typography className="list-left-title">{"Base Compa Ratio Placement"}</Typography>
                                    <img src={ListBottomImg} alt="reload" className="list-bottom-img" />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Typography className="list-left-title">{"TC Compa ratio Analytics"}</Typography>
                                    <img src={ListBottomImg} alt="reload" className="list-bottom-img" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className="cancel-save-grid">
                <Button className="cancel-btn" onClick={onCancel}>{"Cancel"}</Button>
                <Button className="save-btn" onClick={onCancel}>{"Save"}</Button>
            </Grid>
        </Grid>
    );
};

export default React.memo(EditCandidate);
