import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27" 
      height="28" 
      fill="none"
      viewBox="0 0 27 22"
    >
      <path
        fill="#fff"
        d="M17.227 9.287l-2.364 2.364a3.168 3.168 0 00-4.528 2.855A3.168 3.168 0 0013.5 17.67a3.168 3.168 0 002.855-4.527l2.364-2.364a1.055 1.055 0 10-1.492-1.492zM13.5 15.561a1.056 1.056 0 111.055-1.055c0 .582-.473 1.055-1.055 1.055z"
      ></path>
      <path
        fill="#fff"
        d="M17.614 1.432A13.592 13.592 0 0011.82.894C5.8 1.61 1.15 6.357.19 12.183c-.436 2.65-.118 5.327.921 7.74.514 1.194 1.667 1.965 2.939 1.965h18.912c1.242 0 2.357-.695 2.84-1.77A13.607 13.607 0 0027 14.505C27 8.52 23.224 3.23 17.614 1.432zm5.348 18.347H4.05c-.428 0-.821-.27-1.002-.69a11.533 11.533 0 01-.89-3.53h1.005a1.055 1.055 0 100-2.108H2.156a11.607 11.607 0 012.507-6.293l.633.633a1.055 1.055 0 101.492-1.492l-.663-.663a11.092 11.092 0 016.323-2.686v1.005a1.055 1.055 0 102.11 0V2.95a11.146 11.146 0 016.321 2.686l-.662.662a1.055 1.055 0 101.492 1.492l.633-.633a11.747 11.747 0 012.501 6.292h-1a1.055 1.055 0 100 2.11h1a11.47 11.47 0 01-.965 3.693c-.141.315-.51.526-.916.526z"
      ></path>
    </svg>
  );
}

export default Icon;