import { useState } from "react";
import { Grid, Select, MenuItem, Typography, Box, Stack, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AiOutlineDown } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import { accordianSVGstyle } from "../../../../../utils/utils";

const Assessment = ({ }) => {
    const [selectedValue, setSelectedValue] = useState(''); // Set default value here
   
    const handleOpen = (value) => {
      if(selectedValue === value) {
        setSelectedValue('');
      } else {
        setSelectedValue(value);
      }
    }
   
    return (
        <Grid>
            {/*----- User 1 -----*/}
            <Grid item xs={12} sm={12} md={12}>
            {/* <Select
            size="small"
            name="promotionStatus"
            fullWidth
            value={selectedValue}
            onChange={handleChange} // Handle selection changes
            className="text-field-area"
            IconComponent={AiOutlineDown}
            sx={{
                backgroundColor: '#F9F9F9', 
                color:'#00425A',
                lineHeight:'30px',
                marginBottom:'15px',
                fontSize:'20px',
                height:'72px',       // Set background color to red
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',              // Remove the border
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',              // Ensure border stays removed on hover
                },
                '& .MuiSelect-icon': {
                    color: '#00425A',                // Set arrow color to red
                  },
              }}
            >
            <MenuItem value="option1">Option 1</MenuItem>
            <MenuItem value="option2">OKR’s Performance</MenuItem>
            <MenuItem value="option3">Option 3</MenuItem>
            <MenuItem value="option4">Option 4</MenuItem>
            </Select>
            <Select
            size="small"
            name="promotionStatus"
            fullWidth
            value={selectedValue}
            onChange={handleChange} // Handle selection changes
            className="text-field-area"
            IconComponent={AiOutlineDown}
            sx={{
                backgroundColor: '#F9F9F9', 
                color:'#00425A',
                marginBottom:'15px',
                lineHeight:'30px',
                fontSize:'20px',
                height:'72px',       // Set background color to red
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',              // Remove the border
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',              // Ensure border stays removed on hover
                },
                '& .MuiSelect-icon': {
                    color: '#00425A',                // Set arrow color to red
                  },
              }}
            >
            <MenuItem value="option1">Option 1</MenuItem>
            <MenuItem value="option2">OKR’s Performance</MenuItem>
            <MenuItem value="option3">Option 3</MenuItem>
            <MenuItem value="option4">Option 4</MenuItem>
            </Select>
            <Select
            size="small"
            name="promotionStatus"
            fullWidth
            value={selectedValue}
            onChange={handleChange} // Handle selection changes
            className="text-field-area"
            IconComponent={AiOutlineDown}
            sx={{
                backgroundColor: '#F9F9F9', 
                color:'#00425A',
                marginBottom:'15px',
                lineHeight:'30px',
                fontSize:'20px',
                height:'72px',       // Set background color to red
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',              // Remove the border
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',              // Ensure border stays removed on hover
                },
                '& .MuiSelect-icon': {
                    color: '#00425A',                // Set arrow color to red
                  },
              }}
            >
            <MenuItem value="option1">Option 1</MenuItem>
            <MenuItem value="option2">OKR’s Performance</MenuItem>
            <MenuItem value="option3">Option 3</MenuItem>
            <MenuItem value="option4">Option 4</MenuItem>
            </Select> */}

<div className="acc-wrapper">
        <Stack sx={{
            margin:"1rem 0px",
            "& .MuiAccordion-rounded":{
                // border:"1px solid #dad2d2",
                borderRadius:"10px !important",
                boxShadow:"none",             
            backgroundColor:"var(--graybg)"
            }
        }}>
            <Accordion
            // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
            expanded={selectedValue === "OKR"} onChange={() => handleOpen("OKR")} data-value={"stats"}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
            sx={{
                width:"100%",   
                fontSize:"20px",
                lineHeight:"30px",
                fontWeight:"400",
                color:"rgba(0, 66, 90, 1)",

                "& .MuiAccordionSummary-content":{
                    width:"100%", 
                    display:"flex", 
                    justifyContent:"space-between",
                }}}
            >
                OKR’s Performance

                <BsChevronDown
                    style={{...accordianSVGstyle ,transform: selectedValue !== "OKR" ? "rotate(0deg)" : "rotate(180deg)"}}
                />          
            </AccordionSummary>
            <AccordionDetails
            sx={{
                fontSize:"1rem"
            }}
            >
               
            </AccordionDetails>
            </Accordion>
        </Stack>
</div>
<div className="acc-wrapper">
        <Stack sx={{
            margin:"1rem 0px",
            "& .MuiAccordion-rounded":{
                // border:"1px solid #dad2d2",
                borderRadius:"10px !important",
                boxShadow:"none",             
            backgroundColor:"var(--graybg)"
            }                           
        }}>
            <Accordion
            // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
            expanded={selectedValue === "Medibuddy Beliefs"} onChange={() => handleOpen("Medibuddy Beliefs")} data-value={"stats"}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
            sx={{
                width:"100%",   
                fontSize:"20px",
                lineHeight:"30px",
                fontWeight:"400",
                color:"rgba(0, 66, 90, 1)",
                "& .MuiAccordionSummary-content":{
                    width:"100%", 
                    display:"flex", 
                    justifyContent:"space-between",
                }}}
            >
                Medibuddy Beliefs

                <BsChevronDown
                    style={{...accordianSVGstyle ,transform: selectedValue !== "Medibuddy Beliefs" ? "rotate(0deg)" : "rotate(180deg)"}}
                />          
            </AccordionSummary>
            <AccordionDetails
            sx={{
                fontSize:"1rem"
            }}
            >
               
            </AccordionDetails>
            </Accordion>
        </Stack>
</div>
<div className="acc-wrapper">
        <Stack sx={{
            margin:"1rem 0px",
            "& .MuiAccordion-rounded":{
                // border:"1px solid #dad2d2",
                borderRadius:"10px !important",
                boxShadow:"none",             
            backgroundColor:"var(--graybg)"
            }                           
        }}>
            <Accordion
            // sx={{ border:"1px solid gray", borderRadius:"3.2rem"}}
            expanded={selectedValue === "Develop and Envelope"} onChange={() => handleOpen("Develop and Envelope")} data-value={"stats"}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" 
            sx={{
                width:"100%",   
                fontSize:"20px",
                lineHeight:"30px",
                fontWeight:"400",
                color:"rgba(0, 66, 90, 1)",
                "& .MuiAccordionSummary-content":{
                    width:"100%", 
                    display:"flex", 
                    justifyContent:"space-between",
                }}}
            >
                Develop and Envelope

                <BsChevronDown
                    style={{...accordianSVGstyle ,transform: selectedValue !== "Develop and Envelope" ? "rotate(0deg)" : "rotate(180deg)"}}
                />          
            </AccordionSummary>
            <AccordionDetails
            sx={{
                fontSize:"1rem"
            }}
            >
               
            </AccordionDetails>
            </Accordion>
        </Stack>
</div>


            </Grid>

           
        </Grid>
    )
}

export default Assessment;