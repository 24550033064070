import React, { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import ViewCandidate from './components/ViewCandidate';
import EditCandidate from './components/EditCandidate';
import { useLocation } from "react-router-dom/cjs/react-router-dom";


const ViewCandidateInComp = () => {

  const location = useLocation();

  const [isEditMode, setIsEditMode] = useState(false);

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleCancelClick = () => {
    setIsEditMode(false);
  };

  return (
    <div className="candidate">
      <Grid container spacing={2}>

        <Grid item xs={12} sm={12} md={12} className="title-grid">
          <Typography className="page-title">{"Candidate"}</Typography>

          {!isEditMode && (
            <Button className="edit-btn" onClick={handleEditClick}>
              {"Edit"}
            </Button>
          )}
        </Grid>
        {/*----- View & Edit Candidate -----*/}
        <Grid item xs={12} sm={12} md={12}>
          {isEditMode ? <EditCandidate onCancel={handleCancelClick} name={location?.state?.name} /> : <ViewCandidate name={location?.state?.name} />}
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewCandidateInComp;
