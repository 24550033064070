import React, { useState } from 'react';
import { Grid, Typography, Tooltip } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import RewardsIcon1 from "../../../../assets/images/MyCommunity/RewardsIcon1.svg";
import RewardsIcon2 from "../../../../assets/images/MyCommunity/RewardsIcon2.svg";
import RewardsIcon3 from "../../../../assets/images/MyCommunity/RewardsIcon3.svg";
import RewardsIcon4 from "../../../../assets/images/MyCommunity/RewardsIcon4.svg";
import RewardsIcon5 from "../../../../assets/images/MyCommunity/RewardsIcon5.svg";
import PeerNomonationModal from '../Modal/PeerNomonationModal';
import ChairmaAwardsModal from '../Modal/ChairmaAwardsModal';
import TeamExcellenceModal from '../Modal/TeamExcellenceModal';


const Rewards = ({ }) => {
    const dispatch = useDispatch();

    // const [rewardsModal, setRewardsModal] = useState(false);

    const [chairmaAwardsModal, setChairmaAwardsModal] = useState(false);
    const [teamExcellenceModal, setTeamExcellenceModal] = useState(false);

    const kudosList = [
        {
            id: 1,
            icon: RewardsIcon1,
            title: 'Chairman Awards',
            cardBg: '#FFE9E9',
            tooltip: 'Awarded to employees who give extra effort and bring their A-game.',
        },
        {
            id: 2,
            icon: RewardsIcon2,
            title: 'Customer Excellence',
            cardBg: '#EEFAFF',
            tooltip: 'Awarded to employees who give extra effort and bring their A-game.',
        },
        {
            id: 3,
            icon: RewardsIcon3,
            title: 'People Champion',
            cardBg: '#E9FBF2',
            tooltip: 'Awarded to employees who give extra effort and bring their A-game.',
        },
        {
            id: 4,
            icon: RewardsIcon4,
            title: 'Team Catalyst',
            cardBg: '#EEEFFF',
            tooltip: 'Awarded to employees who give extra effort and bring their A-game.',
        },
        {
            id: 5,
            icon: RewardsIcon5,
            title: 'Team Excellence',
            cardBg: '#FFE9E9',
            tooltip: 'Awarded to employees who give extra effort and bring their A-game.',
        },
        {
            id: 6,
            icon: RewardsIcon2,
            title: 'Customer Excellence',
            cardBg: '#EEFAFF',
            tooltip: 'Awarded to employees who give extra effort and bring their A-game.',
        },
        {
            id: 7,
            icon: RewardsIcon3,
            title: 'People Champion',
            cardBg: '#E9FBF2',
            tooltip: 'Awarded to employees who give extra effort and bring their A-game.',
        },
        {
            id: 8,
            icon: RewardsIcon4,
            title: 'Team Catalyst',
            cardBg: '#EEEFFF',
            tooltip: 'Awarded to employees who give extra effort and bring their A-game.',
        },
    ];

    // const handleRewardsModal = () => {
    //     if (!rewardsModal) {
    //         setRewardsModal(true);
    //     } else {
    //         setRewardsModal(false);
    //     }
    // }

    const handleChairmaAwardsPopup = () => {
        if (!chairmaAwardsModal) {
            setChairmaAwardsModal(true);
        } else {
            setChairmaAwardsModal(false);
        }
    }

    const handleTeamExcellencePopup = () => {
        if (!teamExcellenceModal) {
            setTeamExcellenceModal(true);
        } else {
            setTeamExcellenceModal(false);
        }
    }

    return (
        <Grid container className="kudos-rewards-card">
            <Grid item xs={12} sm={12} md={12} className="kudos-rewards-container">
                <Grid container direction="row" wrap="nowrap" className="kudos-rewards-scroll">
                    {kudosList.map((item, index) => (
                        // <Tooltip
                        //     key={index}
                        //     title={item.tooltip}
                        //     arrow
                        //     componentsProps={{
                        //         tooltip: {
                        //             sx: {
                        //                 width: '148px',
                        //                 fontSize: '12px',
                        //             },
                        //         },
                        //     }}
                        // >
                            <div key={index} className="kudos-rewards-item-div"
                                onClick={() => {
                                    if (item.id === 1) {
                                        handleChairmaAwardsPopup();
                                    } else if (item.id === 5) {
                                        handleTeamExcellencePopup();
                                    } 
                                    // else {
                                    //     handleRewardsModal();
                                    // }
                                }}
                            >
                                <Grid key={index} item className="kudos-rewards-item" style={{ backgroundColor: item.cardBg }}>
                                    <img src={item.icon} alt="Slider" className="kudos-rewards-icon" />
                                    <Typography className="kudos-rewards-title">{item.title}</Typography>
                                </Grid>
                            </div>
                        // </Tooltip>
                    ))}
                </Grid>
            </Grid>

            {/* {rewardsModal &&
                <PeerNomonationModal openPeerNomonationModal={rewardsModal} handlePeerNomonationModalClose={handleRewardsModal} />
            } */}

            {chairmaAwardsModal &&
                <ChairmaAwardsModal openChairmaAwardsModal={chairmaAwardsModal} handleChairmaAwardsModalClose={handleChairmaAwardsPopup} />
            }

            {teamExcellenceModal &&
                <TeamExcellenceModal openTeamExcellenceModal={teamExcellenceModal} handleTeamExcellenceModalClose={handleTeamExcellencePopup} />
            }
        </Grid>
    );
};

export default React.memo(Rewards);
