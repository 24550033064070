import { isEmptyNullUndefined } from "../../../utils/utils";
import CertificateModal from "../Modal/CertificateModal";
import SendAGreetingCardModal from "../Modal/SendAGreetingCardModal";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import CalendarEvents from "./CalendarEvents";
import ManageRewards from "./ManageRewards";
import MyAccomplishments from "./MyAccomplishments";
import SurveyPuzzle from "./SurveyPuzzle";
import { Grid } from "@mui/material";

const RightSideBlock = ({RewardsView}) => {

    const location = useLocation();

    const [openSendAGreetingCardModal, setOpenSendAGreetingCardModal] = useState(false);
    const [openCertificateModal, setOpenCertificateModal] = useState(false);
    const [RewardsView1, setRewardsView1] = useState(false);

    useEffect(() => {
        if(isEmptyNullUndefined(RewardsView)) {
            setRewardsView1(location?.state?.RewardsView);
        } else {
            setRewardsView1(RewardsView);
        }
    }, [RewardsView]);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    const handleSendAGreetingCardModalClose = () => {
        setOpenSendAGreetingCardModal(false);
    };

    const handleCertificateModalClose = () => {
        setOpenCertificateModal(false);
    };

    return (
        windowWidth > 900 ? (
            <Grid item xs={12} sm={12} md={5}>
  
              {
                RewardsView1 === true &&
                <ManageRewards />
              }
  
              <CalendarEvents setOpenSendAGreetingCardModal={setOpenSendAGreetingCardModal} />
              <MyAccomplishments setOpenCertificateModal={setOpenCertificateModal} />
              <SurveyPuzzle />

            {openSendAGreetingCardModal && (
                <SendAGreetingCardModal
                    openSendAGreetingCardModal={openSendAGreetingCardModal}
                    handleSendAGreetingCardModalClose={handleSendAGreetingCardModalClose}
                />
            )}
            {openCertificateModal && (
                <CertificateModal
                    openCertificateModal={openCertificateModal}
                    handleCertificateModalClose={handleCertificateModalClose}
                />
            )}

            </Grid>
        ) : (
            <div className="my-community">
            <Grid item xs={12} sm={12} md={5}>
  
              {
                RewardsView1 === true &&
                <ManageRewards />
              }
  
              <CalendarEvents setOpenSendAGreetingCardModal={setOpenSendAGreetingCardModal} />
              <MyAccomplishments setOpenCertificateModal={setOpenCertificateModal} />
              <SurveyPuzzle />

            {openSendAGreetingCardModal && (
                <SendAGreetingCardModal
                    openSendAGreetingCardModal={openSendAGreetingCardModal}
                    handleSendAGreetingCardModalClose={handleSendAGreetingCardModalClose}
                />
            )}
            {openCertificateModal && (
                <CertificateModal
                    openCertificateModal={openCertificateModal}
                    handleCertificateModalClose={handleCertificateModalClose}
                />
            )}

            </Grid>
        </div>
        )
    )
}

export default RightSideBlock;