import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Chart.js को रजिस्टर करें
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TCIncreaseByFunctionChart = () => {
  const history = useHistory();

    // Custom plugin to display data values on bars
const valueOnTopPlugin = {
  id: 'valueOnTopPlugin',
  afterDatasetsDraw: (chart) => {
    const { ctx, data } = chart;
    chart.data.datasets.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i);
      meta.data.forEach((bar, index) => {
        const value = dataset.data[index];
        ctx.fillStyle = 'black'; // Text color
        ctx.font = '12px Arial';
        ctx.textAlign = 'center';
        ctx.fillText( `${value}%`, bar.x + 16, bar.y + 2); // Position above the bar
      });
    });
  },
};

  // Chart Data
  const data = {
    type: 'bar',
    labels: ['Engineering', 'HR', 'Finance', 'Outside Sales', 'Inside Sales'],
    datasets: [
      {
        // label: 'Base Salary',
        data: [10, 30, 20, 60, 50, 100],
        backgroundColor: [
          "#62d4ab",
          "#b87de5",
          "#75a4ff",
          "#ee9969",
          "#eed969",
        ],
        borderColor: [
          "#62d4ab",
          "#b87de5",
          "#75a4ff",
          "#ee9969",
          "#eed969",
        ],
        borderWidth: 1,
        borderRadius: {
          topRight: 5,
          bottomRight: 5
        },
        borderSkipped: false,
        barThickness: 23, // Set the bar width (reduce for thinner bars)
      },
    ],
  };

  // Chart Options
  const options = {
    indexAxis: 'y', // Horizontal bar ke liye yeh important hai
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          display: true, // Show the tick values
          callback: (value) => `${value}%`, // Add percentage sign
        },
      }
    },
    responsive: true,
    plugins: {
      title: {
        display: false,
        position: 'bottom',
        text: 'Year on Year',
      },
      legend: {
        position: 'bottom',
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: '#000',
        font: {
          size: 12,
          weight: 'bold',
        },
        formatter: (value) => `$${value}`,
      },
    },
  };

  return (
    <Grid>
      <Bar data={data} options={options} plugins={[valueOnTopPlugin]} />
    </Grid>
  )
};

export default TCIncreaseByFunctionChart; 
