import { BsChevronDown } from "react-icons/bs";
import GoalsForm from "../../goalsForm";

const AccDetails = ({formId}) => {
    console.log('formId accDetails', formId)
    return(
        <div> 
                                    <div className="horizontal-border" />
                                    <GoalsForm formId={formId} employeeId={null} pageMoodViewOrEdit={'view'} />


                                    {/* <div className="details-div">
                                        <p className="details-title">Goal Description</p>
                                        <p className="details-description">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.</p>

                                        <div className="sub-container">
                                            ----- Add Stakeholders & Goal cycle -----
                                            <div className="sub-item-col">
                                                <p className="details-title">Add Stakeholders</p>

                                                <div className="d-flex">
                                                    <div className="border-div"><p className="sub-container-description">Micheal William</p></div>
                                                    <div className="border-div"><p className="sub-container-description">Wallace Mason</p></div>
                                                </div>
                                            </div>

                                            <div className="sub-item-col">
                                                <p className="details-title">Goal cycle</p>
                                                <p className="details-description">Q1 Cycle 2024, Q2 Cycle 2024</p>
                                            </div>
                                        </div>

                                        ----- Start Date & Goal Weightage in % -----
                                        <div className="sub-container">
                                            <div className="sub-item-col">
                                                <p className="details-title">Start Date</p>
                                                <p className="details-description">30 July 2024</p>
                                            </div>

                                            <div className="sub-item-col">
                                                <p className="details-title">Goal Weightage in %</p>
                                                <p className="details-description">40%</p>
                                            </div>
                                        </div>

                                        ----- Priority & Goal Tag -----
                                        <div className="sub-container">
                                            <div className="sub-item-col">
                                                <p className="details-title">Priority</p>
                                                <p className="details-description red-description">High</p>
                                            </div>

                                            <div className="sub-item-col">
                                                <p className="details-title">Goal Tag</p>
                                                <p className="details-description">Profitability</p>
                                            </div>
                                        </div>

                                        <p className="details-title mt-20">Path to Green</p>
                                        <p className="details-description">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document.</p>


                                        <p className="details-title mt-20">Updates on this Goal</p>
                                        <p className="details-description">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to... View More</p>


                                        ----- Milestone 1 -----
                                        <div className="sub-status-div">
                                            <p className="sub-status-title">Milestone 1</p>
                                            <p className="completed-status">Completed</p>
                                            <div className="status-horizontal-border" />
                                            <BsChevronDown style={{ ...accordianSVGstyle, transform: !openExpandedCard ? "rotate(0deg)" : "rotate(180deg)" }} />
                                        </div>

                                        <div className="status-col-div">
                                            <div className="status-col">
                                                <div className="status-vertical-border" />
                                            </div>
                                            <div className="status-col">
                                                ----- Milestone Details & Milestone Completion -----
                                                <div className="sub-container">
                                                    <div className="sub-item-col">
                                                        <p className="details-title">Milestone Details</p>
                                                        <p className="details-description">E-Auction as per policy</p>
                                                    </div>

                                                    <div className="sub-item-col">
                                                        <p className="details-title">Milestone Completion</p>
                                                        <p className="details-description">50%</p>
                                                    </div>
                                                </div>

                                                ----- Start Date & End Date -----
                                                <div className="sub-container">
                                                    <div className="sub-item-col">
                                                        <p className="details-title">Start Date</p>
                                                        <p className="details-description">05 August 2024</p>
                                                    </div>

                                                    <div className="sub-item-col">
                                                        <p className="details-title">End Date</p>
                                                        <p className="details-description">15 August 2024</p>
                                                    </div>
                                                </div>

                                                ----- Milestone Deadline -----
                                                <div className="sub-container">
                                                    <div className="sub-item-col">
                                                        <p className="details-title">Milestone Deadline</p>
                                                        <p className="details-description">08 August 2024</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        ----- Milestone 2 -----
                                        <div className="sub-status-div">
                                            <p className="sub-status-title">Milestone 2</p>
                                            <p className="delayed-status">Delayed</p>
                                            <div className="status-horizontal-border" />
                                            <BsChevronDown style={{ ...accordianSVGstyle, transform: !openExpandedCard ? "rotate(0deg)" : "rotate(180deg)" }} />
                                        </div>


                                        ----- Milestone 3 -----
                                        <div className="sub-status-div">
                                            <p className="sub-status-title">Milestone 3</p>
                                            <p className="inProgress-status">In Progress</p>
                                            <div className="status-horizontal-border" />
                                            <BsChevronDown style={{ ...accordianSVGstyle, transform: !openExpandedCard ? "rotate(0deg)" : "rotate(180deg)" }} />
                                        </div>
                                    </div> */}
                                </div>
    )
}

export default AccDetails;