import React from "react";

function CompIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27" 
      height="28" 
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8 2.154a6.346 6.346 0 106.065 4.473.577.577 0 011.103-.34 7.5 7.5 0 11-5.615-5.125.577.577 0 01-.24 1.128A6.374 6.374 0 008 2.154zm3.934-.362a.577.577 0 01.598-.554l2.095.081a.577.577 0 01.554.554l.081 2.095a.577.577 0 11-1.153.044l-.03-.775-1.786 1.786a.577.577 0 01-.816-.815l1.786-1.787-.775-.03a.577.577 0 01-.554-.599zM8 3.308a.577.577 0 01.577.577v.978h.02a2.143 2.143 0 012.144 2.143.577.577 0 01-1.154 0 .99.99 0 00-.99-.99h-.02v1.917h.061a2.102 2.102 0 110 4.204h-.061v.978a.577.577 0 01-1.154 0v-.978h-.061a2.103 2.103 0 01-2.102-2.102.577.577 0 011.154 0c0 .524.424.948.948.948h.061V9.087h-.051a2.112 2.112 0 010-4.223h.051v-.98A.577.577 0 018 3.309zm-.577 2.709h-.051a.959.959 0 000 1.916h.051V6.017zm1.154 3.07v1.896h.061a.948.948 0 000-1.896h-.061z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CompIcon;
