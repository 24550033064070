import React, { useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import NominatePromotions from './components/NominatePromotions';
import RequestPromotionFeedback from './components/RequestPromotionFeedback';
import FeedbackRequests from './components/FeedbackRequests';
import ViewSharedDocuments from './components/ViewSharedDocuments';
import ApprovalRequests from './components/ApprovalRequests';
import { PiSlidersHorizontalLight } from "react-icons/pi";
import { HiOutlineChevronDown } from "react-icons/hi2";
import ValidationIcon from "../../../../src/assets/images/HRPromotions/validationIcon.svg";


const Promotions = () => {
  const [nominatePromotionsView, setNominatePromotionsView] = useState(true);
  const [requestPromotionFeedbackView, setRequestPromotionFeedbackView] = useState(false);
  const [viewSharedDocuments, setViewSharedDocuments] = useState(false);
  const [feedbackRequestsView, setFeedbackRequestsView] = useState(false);
  const [approvalRequestsView, setApprovalRequestsView] = useState(false);

  const handleNominatePromotions = () => {
    setNominatePromotionsView(true);
    setRequestPromotionFeedbackView(false);
    setViewSharedDocuments(false);
    setFeedbackRequestsView(false);
    // setApprovalRequestsView(false);
  };

  const handleRequestPromotionFeedback = () => {
    setNominatePromotionsView(false);
    setRequestPromotionFeedbackView(true);
    setViewSharedDocuments(false);
    setFeedbackRequestsView(false);
    // setApprovalRequestsView(false);
  };

  const handleViewSharedDocuments = () => {
    setNominatePromotionsView(false);
    setRequestPromotionFeedbackView(false);
    setViewSharedDocuments(true);
    setFeedbackRequestsView(false);
    // setApprovalRequestsView(false);
  };

  const handleFeedbackRequests = () => {
    setNominatePromotionsView(false);
    setRequestPromotionFeedbackView(false);
    setFeedbackRequestsView(true);
    setViewSharedDocuments(false);
    setApprovalRequestsView(false);
  };

  // const handleApprovalRequests = () => {
  //   setNominatePromotionsView(false);
  //   setRequestPromotionFeedbackView(false);
  //   setFeedbackRequestsView(false);
  //   setApprovalRequestsView(true);
  // };

  return (
    <div className="promotions">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} className="title-grid">
          <Typography className="page-title">{"Promotions (2024-2025)"}</Typography>

          <Button className="my-team-btn">
            <PiSlidersHorizontalLight className="slider-icon" />
            {"My Team"}
            <HiOutlineChevronDown className="down-arrow" />
          </Button>
        </Grid>

        {/*----- Feedback Comparison -----*/}
        <Grid item xs={12} sm={12} md={12}>
          {/*----- Top Button Section -----*/}
          <Grid class="top-btn-List">
            <a onClick={handleNominatePromotions} class={nominatePromotionsView === true ? "active-btn" : "inActive-btn"}>{"Nominate Promotions"}</a>
            <a onClick={handleRequestPromotionFeedback} class={requestPromotionFeedbackView === true ? "active-btn" : "inActive-btn"}>{"Request Promotion Feedback"}</a>
            <a onClick={handleViewSharedDocuments} class={viewSharedDocuments === true ? "active-btn" : "inActive-btn"}>{"View Shared Documents"}</a>
           <a onClick={handleFeedbackRequests} class={feedbackRequestsView === true ? "active-btn" : "inActive-btn"}>{"Feedback Requests View"}</a> 
            {/* <a onClick={handleApprovalRequests} class={approvalRequestsView === true ? "active-btn" : "inActive-btn"}>{"Approval Requests"}</a>  */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} className="validated-grid">
        <img className="validation-icon" src={ValidationIcon} alt="ValidationIcon" />
        <Typography className="validated-txt">{"Validated"}</Typography>
      </Grid>

          {nominatePromotionsView === true &&
            <NominatePromotions />
          }

          {requestPromotionFeedbackView === true &&
            <RequestPromotionFeedback />
          }
           {viewSharedDocuments === true &&
            <ViewSharedDocuments />
          }

           {feedbackRequestsView === true &&
            <FeedbackRequests />
          }

          {/* {approvalRequestsView === true &&
            <ApprovalRequests />
          }  */}
        </Grid>
      </Grid>
    </div>
  );
};

export default Promotions;
